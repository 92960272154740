import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";
import { theme } from "../../theme/theme";

interface ProgressBarProps {
  duration: number;
}

const ProgressBar = ({ duration }: ProgressBarProps) => {
  const [progress, setProgress] = useState(100);
  const interval = duration / 100;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => prevProgress - 1);
    }, interval);

    return () => clearInterval(intervalId);
  }, [duration, interval]);

  return (
    <LinearProgress
      sx={{
        background: theme.palette.grey[800],
        borderRadius: "0 0 4px 4px",
        transitionDuration: "100ms",
        ".MuiLinearProgress-bar": {
          zIndex: 99,
          transitionDuration: "100ms",
          backgroundColor: theme.palette.primary[300],
        },
      }}
      variant="determinate"
      value={progress > 0 ? progress : 0}
    />
  );
};

export default ProgressBar;
