import { getBorderShadow } from "../../utils/getBorderShadow";
import Chip from "@mui/material/Chip";
import { ChipProps as MuiChipProps, styled } from "@mui/material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) =>
    prop !== "$borderColor" && prop !== "$backgroundColor",
})<
  MuiChipProps & {
    $borderColor: string | undefined;
    $backgroundColor: string | undefined;
  }
>`
  height: auto;
  background-color: ${(p) =>
    p.$backgroundColor ? p.$backgroundColor : p.theme.palette.primary[50]};
  box-shadow: ${(p) =>
    getBorderShadow(p.$borderColor || p.theme.palette.primary[100])};
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  padding: 4px 8px;

  & .MuiChip-label {
    padding: 0;
  }

  ${(p) =>
    p.onDelete &&
    `
    padding-right: 4px;

    & .MuiChip-label {
      padding-right: 4px;
    }
  `};

  &.MuiChip-clickable:hover {
    background-color: ${(p) => p.theme.palette.primary[50]};
    box-shadow: ${(p) => getBorderShadow(p.theme.palette.primary[200])};
  }

  & .MuiChip-deleteIcon {
    color: ${(p) => p.theme.palette.grey[700]};
    font-size: 1rem;
    margin: 0;
  }
`;

type PickedChipProps = Pick<
  MuiChipProps,
  | "deleteIcon"
  | "onDelete"
  | "sx"
  | "label"
  | "clickable"
  | "onClick"
  | "onMouseDown"
>;

interface ChipProps extends PickedChipProps {
  borderColor?: string;
  backgroundColor?: string;
}

export const Tag: React.FC<ChipProps> = ({
  borderColor,
  backgroundColor,
  ...props
}) => {
  return (
    <StyledChip
      deleteIcon={<CloseOutlined />}
      $borderColor={borderColor}
      $backgroundColor={backgroundColor}
      {...props}
    />
  );
};
