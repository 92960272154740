import { Button, Tooltip } from "../../atoms";
import {
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableBody as MuiTableBody,
  Box,
  SxProps,
} from "../../reexports";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useTranslation } from "next-i18next";
import { QuestionTableColumnProps, QuestionTableRowProps } from "./types";

function TableCell({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: SxProps;
}) {
  return (
    <MuiTableCell
      sx={{
        p: "12px 16px",
        border: "0px",
        lineHeight: "20px",
        minWidth: "120px",
        maxWidth: "360px",
        borderBottom: "1px solid",
        borderColor: "grey.200",
        wordWrap: "break-word",
        overflowWrap: "break-word",
        hyphens: "auto",
        ...sx,
      }}
    >
      {children}
    </MuiTableCell>
  );
}

export function TableHead({
  columns,
  isNotEmptyData,
}: {
  columns: QuestionTableColumnProps[];
  isNotEmptyData: boolean;
}) {
  return (
    <MuiTableHead
      sx={{
        borderBottom: "1px solid",
        backgroundColor: "grey.50",
        borderColor: "grey.200",
      }}
    >
      <MuiTableRow>
        {columns.map((column) => (
          <TableCell key={column.id}>{column.name}</TableCell>
        ))}
        {isNotEmptyData && (
          <TableCell
            key="actions"
            sx={{ position: "sticky", right: 0, backgroundColor: "grey.50" }}
          >
            {null}
          </TableCell>
        )}
      </MuiTableRow>
    </MuiTableHead>
  );
}

const TOOLTIP_PROPS = {
  sx: {
    "& .MuiTooltip-tooltip": {
      p: "4px 8px",
      lineHeight: "16px",
    },
  },
  slotProps: {
    popper: {
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, -14],
          },
        },
      ],
    },
  },
};

function EmptyDataRow({ columns }: { columns: QuestionTableColumnProps[] }) {
  return (
    <MuiTableRow>
      {columns.map((column) => (
        <TableCell key={column.id}>-</TableCell>
      ))}
    </MuiTableRow>
  );
}

function DataRow({
  columns,
  row,
  onEditClick,
  onDeleteClick,
  canEdit,
}: {
  columns: QuestionTableColumnProps[];
  row: QuestionTableRowProps;
  onEditClick: (row: QuestionTableRowProps) => void;
  onDeleteClick: (row: QuestionTableRowProps) => void;
  canEdit: boolean;
}) {
  const { t } = useTranslation("common");

  return (
    <MuiTableRow>
      {columns.map((column) => {
        const CHARACTERS_LIMIT = 120;
        let cellValue = row.values.find(
          (value) => value.column_id === column.id
        )?.value;
        const columnType = column.type.toUpperCase();

        if (columnType === "ENTITY") {
          cellValue = column?.options?.find(
            (option) => option.id === cellValue
          )?.value;
        }

        if (columnType === "TEXT") {
          cellValue = cellValue?.slice?.(0, CHARACTERS_LIMIT) ?? "-";
        }

        return (
          <TableCell key={column.id}>
            {cellValue ?? "-"}
            {cellValue?.length === CHARACTERS_LIMIT ? "..." : ""}
          </TableCell>
        );
      })}
      <TableCell
        key="actions"
        sx={{ position: "sticky", right: 0, backgroundColor: "common.white" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            ml: "auto",
            width: "min-content",
          }}
        >
          <Tooltip title={t("update")} {...TOOLTIP_PROPS}>
            <Button
              variant="text"
              onClick={() => onEditClick(row)}
              disabled={!canEdit}
              icon={<EditNoteIcon sx={{ color: "grey.700" }} />}
              data-testid={`table-row-edit-button-${row.row_id}`}
            />
          </Tooltip>
          <Tooltip title={t("delete")} {...TOOLTIP_PROPS}>
            <Button
              variant="text"
              icon={<DeleteOutlinedIcon sx={{ color: "grey.700" }} />}
              disabled={!canEdit}
              data-testid={`table-row-delete-button-${row.row_id}`}
              onClick={() => onDeleteClick(row)}
            />
          </Tooltip>
        </Box>
      </TableCell>
    </MuiTableRow>
  );
}

export function TableBody({
  rows = [],
  columns,
  onEditClick,
  onDeleteClick,
  canEdit,
}: {
  columns: QuestionTableColumnProps[];
  onEditClick: (row: QuestionTableRowProps) => void;
  onDeleteClick: (row: QuestionTableRowProps) => void;
  canEdit: boolean;
  rows?: QuestionTableRowProps[];
}) {
  if (!rows?.length) {
    return (
      <MuiTableBody>
        <EmptyDataRow columns={columns} />
      </MuiTableBody>
    );
  }

  return (
    <MuiTableBody>
      {rows.map((row) => (
        <DataRow
          key={row.row_id}
          columns={columns}
          row={row}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
          canEdit={canEdit}
        />
      ))}
    </MuiTableBody>
  );
}
