import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import Autocomplete, {
  AutocompleteRenderGetTagProps,
} from "@mui/material/Autocomplete";
import { Box, StyledTextField, Tag, Typography } from "../../atoms";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import { useTheme } from "@mui/material";
import debounce from "lodash.debounce";
import { useTranslation } from "next-i18next";

export interface DataListProps<T> {
  icon: React.ReactNode;
  placeholder: string;
  getOptions: (q: string) => Promise<T[]>;
  getOptionLabel: (options: T) => string;
  isSelected: (option: T, value: T) => boolean;
  renderTags: "NONE" | "TAGS";
  onChangeCallback: (v: T[]) => void;
  clearOnSelect?: boolean;
  initialValue?: T[];
  renderOption: (props: object, option: T, state: object) => React.ReactNode;
  getOptionDisabled: (item: T) => boolean;
  value?: T[];
  getTagLabel?: (options: T) => string;
  popupIcon?: React.ReactNode | null;
  disabled?: boolean;
  label?: string;
  enableSearchOnClick?: boolean;
  listBoxHeigh?: string;
  listBoxDataTestId?: string;
  autoFocus?: boolean;
}

interface ListboxProps extends React.HTMLAttributes<HTMLUListElement> {
  "data-testid"?: string;
}

export const DataList = <T,>({
  label,
  icon,
  placeholder,
  getOptions,
  getOptionLabel,
  isSelected,
  renderTags,
  onChangeCallback,
  clearOnSelect,
  initialValue,
  value,
  renderOption,
  getOptionDisabled,
  popupIcon,
  getTagLabel,
  disabled,
  enableSearchOnClick,
  listBoxHeigh,
  listBoxDataTestId,
  autoFocus,
}: DataListProps<T>) => {
  const theme = useTheme();
  const { t } = useTranslation("common");
  const [options, setOptions] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const RENDER_TAGS = {
    NONE: () => null,
    TAGS: (
      items: typeof options,
      getTagProps: AutocompleteRenderGetTagProps
    ) => {
      return items.map((item, index) => (
        <Tag
          sx={{
            lineHeight: "1rem",
            margin: "0 !important",
          }}
          label={getTagLabel?.(item) || getOptionLabel(item)}
          {...getTagProps({ index })}
          borderColor={theme.palette.grey[300]}
          backgroundColor={theme.palette.grey[100]}
        />
      ));
    },
  };

  const updateOptions = useCallback(async () => {
    async function fetchOptions() {
      setLoading(true);
      const options = await getOptions(searchPhrase);
      setOptions(options);
      setLoading(false);
    }
    const shouldFetchOptions =
      (searchPhrase !== "" && searchPhrase.length >= 2) || enableSearchOnClick;
    shouldFetchOptions ? fetchOptions() : setOptions([]);
  }, [getOptions, searchPhrase, enableSearchOnClick]);

  useEffect(() => {
    updateOptions();
  }, [searchPhrase, updateOptions]);

  const handleOnOpen = () => {
    if (enableSearchOnClick && !options.length) updateOptions();
  };

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchPhrase(event.target.value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedInputChangeHandler = useCallback(
    debounce(inputChangeHandler, 500),
    []
  );

  const clearInputState = () => {
    setOptions([]);
    setSearchPhrase("");
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const optionChangeHandler = (value: T[], reason: string) => {
    clearOnSelect && clearInputState();
    onChangeCallback(value);
  };

  return (
    <InputLabel>
      <Typography
        variant="bodyMedium14"
        sx={{
          color: "grey.700",
          display: "flex",
          paddingBottom: "4px",
        }}
      >
        {label}
      </Typography>
      <Box sx={{ position: "relative", marginTop: !label ? "20px" : "unset" }}>
        <Autocomplete
          onOpen={handleOnOpen}
          disabled={disabled}
          multiple
          options={options}
          getOptionLabel={getOptionLabel}
          defaultValue={initialValue || []}
          disableClearable={true}
          loading={loading}
          renderOption={renderOption}
          noOptionsText={
            searchPhrase
              ? searchPhrase.length >= 2
                ? t("noOptions")
                : t("minSearchPhrase")
              : t("typeToSearch")
          }
          isOptionEqualToValue={isSelected}
          clearOnBlur={false}
          getOptionDisabled={getOptionDisabled}
          {...(renderTags ? { renderTags: RENDER_TAGS[renderTags] } : {})}
          {...(value ? { value } : {})}
          popupIcon={popupIcon}
          onChange={(_, value, reason) => optionChangeHandler(value, reason)}
          ListboxProps={
            {
              style: { maxHeight: listBoxHeigh },
              "data-testid": listBoxDataTestId || "autocomplete-options",
            } as ListboxProps
          }
          renderInput={(params) => (
            <StyledTextField
              {...params}
              inputRef={inputRef}
              value={searchPhrase}
              onChange={debouncedInputChangeHandler}
              placeholder={placeholder}
              autoFocus={autoFocus}
              sx={{
                ".MuiInputBase-input": {
                  minWidth: "120px !important",
                },
                ...(icon
                  ? {
                      ".MuiAutocomplete-input": {
                        p: "0 !important",
                        height: "24px",
                      },
                      ".MuiInputBase-root": {
                        padding: "7px 40px",
                        gap: 1,
                      },
                    }
                  : {}),
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <Box
          sx={{
            position: "absolute",
            top: "9px",
            left: "12px",
            "&, & > svg": {
              height: "20px",
              width: "20px",
            },
          }}
        >
          {icon}
        </Box>
      </Box>
    </InputLabel>
  );
};
