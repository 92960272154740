import MuiFormControlLabel from "@mui/material/FormControlLabel";
import MuiCheckbox from "@mui/material/Checkbox";
import {
  FormControlLabelProps as MuiFormControlLabelProps,
  CheckboxProps as MuiCheckboxProps,
  alpha,
  styled,
  SxProps,
  Theme,
} from "@mui/material";
import { theme } from "../../theme/theme";

export interface CheckboxProps extends MuiCheckboxProps {
  label?: string | React.ReactNode;
  labelStyles?: SxProps<Theme>;
  padding?: string;
  cursorPointerOnLabel?: boolean;
}

interface FormControlLabelProps extends MuiFormControlLabelProps {
  color?: CheckboxProps["color"];
  size?: CheckboxProps["size"];
  cursorPointerOnLabel?: boolean;
}

export const focusStyles = {
  filter: `drop-shadow(0px 0px 1px ${alpha(theme.palette.primary[500], 0.6)})`,
};

export const hoverStyles = {
  color: theme.palette.primary[600],
};

const StyledCheckbox = styled(MuiCheckbox)(({ theme }) => {
  return {
    "&.MuiButtonBase-root": {
      padding: 0,
      backgroundColor: "transparent",
    },

    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "1rem",
    },

    "&.MuiCheckbox-colorPrimary": {
      color: theme.palette.grey[500],
    },
    "&.MuiCheckbox-colorPrimary:hover": hoverStyles,

    "&.Mui-checked": {
      color: theme.palette.primary[500],
    },

    "&:focus-within": focusStyles,

    "&.MuiCheckbox-colorError": {
      color: theme.palette.red[500],
    },

    "&.Mui-disabled": {
      color: theme.palette.grey[300],
      cursor: "not-allowed",
      pointerEvents: "all",
    },

    "&.Mui-disabled:hover": {
      color: theme.palette.grey[300],
    },
  };
});

const StyledFormControlLabel = styled(MuiFormControlLabel, {
  shouldForwardProp: (prop) => prop !== "cursorPointerOnLabel",
})<FormControlLabelProps>(({ size, color, theme, cursorPointerOnLabel }) => {
  const labelFontStyles =
    size === "small"
      ? {
          fontSize: "0.75rem",
          lineHeight: "1rem",
        }
      : {
          fontSize: "0.875rem",
          lineHeight: "1.125rem",
        };

  const labelColor =
    color === "error"
      ? {
          color: theme.palette.red[500],
        }
      : {
          color: theme.palette.grey[700],
        };
  return {
    margin: 0,
    gap: "8px",
    paddingLeft: "4px",
    paddingRight: "8px",
    cursor: cursorPointerOnLabel ? "pointer" : "default",
    "& .MuiTypography-root": {
      fontWeight: 500,
      ...labelFontStyles,
      ...labelColor,
    },

    "&.Mui-disabled": {
      cursor: "not-allowed",
    },
  };
});

export const Checkbox: React.FC<
  CheckboxProps & { cursorPointerOnLabel?: boolean }
> = ({ label, color, size, labelStyles, cursorPointerOnLabel, ...props }) => {
  if (label) {
    return (
      <StyledFormControlLabel
        control={
          <StyledCheckbox disableRipple color={color} size={size} {...props} />
        }
        label={label}
        size={size}
        color={color}
        sx={labelStyles}
        cursorPointerOnLabel={cursorPointerOnLabel}
      />
    );
  }
  return <StyledCheckbox disableRipple color={color} size={size} {...props} />;
};
