import React from "react";
import { Link, LinkProps } from "../Link/Link";
import { SxProps } from "@mui/material";

interface AppRedirectLinkProps extends LinkProps {
  children: React.ReactNode;
  basePath: string;
  locale: string;
  destinationAppPath: string;
  pathname?: string;
  sx?: SxProps;
}

/**
 * AppRedirectLink is used to move using the Link component between applications in the project.
 *
 * @param {React.ReactNode} children - The content to be rendered inside the link.
 * @param {string} basePath - Current basePath. Should be taken from useRouter hook.
 * @param {string} locale - Current locale. Should be taken from useRouter hook.
 * @param {string} destinationAppPath - Path to the application we want to redirect to. Should be taken from the environment files.
 * @returns {JSX.Element} A JSX element representing the button.
 */

export const AppRedirectLink: React.FC<AppRedirectLinkProps> = ({
  basePath: currentBasePath,
  children,
  locale,
  destinationAppPath,
  pathname,
  sx,
  ...props
}) => {
  const href =
    currentBasePath === destinationAppPath
      ? `${pathname ? `/${locale}/${pathname}` : `/${locale}`}`
      : `${destinationAppPath}/${locale}${pathname ? `/${pathname}` : ""}`;

  return (
    <Link href={href} locale={false} sx={sx} {...props}>
      {children}
    </Link>
  );
};
