import MuiBackdrop from "@mui/material/Backdrop";
import { BackdropProps as MuiBackdropProps, styled } from "@mui/material";

const StyledBackdrop = styled(MuiBackdrop)`
  background: ${(p) => p.theme.palette.blue[700]};
  z-index: ${(p) => p.theme.customZIndex.appBackdrop};
`;

export const Backdrop: React.FC<MuiBackdropProps> = (props) => {
  return (
    <StyledBackdrop
      {...props}
      style={{ opacity: 0.4 }}
      transitionDuration={{ exit: 100 }}
    />
  );
};
