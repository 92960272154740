import { styled } from "@mui/material";
import { Box } from "../../reexports";
import { theme } from "../../theme/theme";

interface CircleIconProps {
  isActive?: boolean;
}

export const CircleIcon = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ isActive }: CircleIconProps) => {
  const activeStyles = isActive
    ? {
        backgroundColor: theme.palette.primary[500],
        color: theme.palette.common.white,
      }
    : {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[600],
      };

  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.625rem",
    lineHeight: "0.75rem",
    minWidth: "20px",
    height: "20px",
    fontWeight: 500,
    borderRadius: "9999px",
    ...activeStyles,
  };
});
