import { Verdict } from "@applied-ai/types";
import { useTranslation } from "next-i18next";
import { ComplianceIcon } from "./ComplianceIcon";
import type { VerdictSize } from "./ComplianceIcon";
import { Box } from "../../reexports";
import { Typography } from "../../atoms";
import { TypographyProps } from "@mui/material";
import { verdictName } from "@applied-ai/utils";
import { EllipsisWithTooltip } from "../EllipsisWithTooltip/EllipsisWithTooltip";

export type SizeMap = {
  [Key in VerdictSize]: TypographyProps["variant"];
};

interface RiskClassificationVerdictIconProps {
  verdict?: Verdict;
  withLabel?: boolean;
  size?: VerdictSize;
}

export const riskClassificationVerdictName = (verdict: Verdict) => {
  switch (verdict) {
    case Verdict.HIGH_RISK:
    case Verdict.LOW_RISK:
    case Verdict.HIGH_RISK_AND_HUMAN_INTERACTION:
    case Verdict.HUMAN_INTERACTION:
    case Verdict.PROHIBITED:
    case Verdict.NOT_IN_SCOPE:
      return `verdicts.${verdict}`;
    default:
      return "verdicts.NONE";
  }
};

export const RiskClassificationVerdictIcon: React.FC<
  RiskClassificationVerdictIconProps
> = ({ verdict = Verdict.IN_PROGRESS, size = "medium" }) => {
  if (verdict === Verdict.HIGH_RISK_AND_HUMAN_INTERACTION)
    return (
      <>
        <ComplianceIcon type={Verdict.HIGH_RISK} size={size} />
        <ComplianceIcon type={Verdict.HUMAN_INTERACTION} size={size} />
      </>
    );

  return <ComplianceIcon type={verdict} size={size} />;
};

export const RiskClassificationVerdict = ({
  verdict,
  size = "small",
  typographyProps,
}: {
  verdict: Verdict;
  size?: VerdictSize;
  typographyProps?: TypographyProps;
}) => {
  const { t } = useTranslation(["rct", "common"]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 1,
      }}
      data-testid="risk-classification-icon-and-verdict"
    >
      <RiskClassificationVerdictIcon verdict={verdict} size={size} />
      <Typography
        variant="bodyRegular14"
        sx={{ color: "grey.900" }}
        {...typographyProps}
        data-testid="risk-classification-verdict"
      >
        <EllipsisWithTooltip>{t(verdictName(verdict))}</EllipsisWithTooltip>
      </Typography>
    </Box>
  );
};
