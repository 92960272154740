import { StagesAndStatuses } from "@applied-ai/types";
import { BarDatum } from "@nivo/bar";
import { FunnelDatum } from "@nivo/funnel";

export const transformDataForBarChart = (data: StagesAndStatuses[]) => {
  return data.map((item) => {
    const transformedStatuses: { [key: string]: number } = {};

    Object.entries(item.statuses).forEach(([, status]) => {
      transformedStatuses[`${status.name}`] = status.total;
      transformedStatuses[`${status.name}_percent`] = status.percent;
    });

    return transformedStatuses;
  });
};

export const extractKeysFromData = (data: BarDatum[]) => {
  const keysSet = new Set();

  data.forEach((obj) => {
    for (const key in obj) {
      if (!key.includes("_percent") && key !== "Draft") {
        keysSet.add(key);
      }
    }
  });

  return [...keysSet] as string[];
};

interface CustomFunnelDatum extends FunnelDatum {
  percent: number;
  name: string;
}

export const transformToFunnelData = (
  data: StagesAndStatuses[]
): CustomFunnelDatum[] => {
  return data.map((item) => ({
    ...item,
    value: item.total,
  }));
};
