import { useTheme } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Typography } from "../../atoms/Typography/Typography";
import { Box } from "../../reexports/Box";
import { useEffect, useState } from "react";
import { StyledAccordionSummary } from "./StyledAccordionSummary";
import { getExpandedItems } from "./getExpandedItems";
import { FieldGroup, FieldGroupId } from "./types";

interface AccordionProps {
  data: FieldGroup[];
  activeItemId?: FieldGroupId;
  onItemClick: (id: FieldGroupId) => void;
  dataTestid?: string;
}

const AccordionSubheader: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        color: theme.palette.grey[600],
        textTransform: "uppercase",
        fontSize: "0.625rem",
        fontWeight: 500,
        lineHeight: "0.75rem",
        minHeight: "10px",
      }}
    >
      {children}
    </Box>
  );
};

export const Accordion = ({
  data,
  onItemClick,
  activeItemId,
  dataTestid,
}: AccordionProps) => {
  const [expanded, setExpanded] = useState<FieldGroupId[]>([]);

  useEffect(() => {
    if (activeItemId) {
      const expandedItems = getExpandedItems(data, activeItemId);
      setExpanded(expandedItems);
    }
  }, [activeItemId, data]);

  const toggleItemExpand = (id: FieldGroupId) => {
    setExpanded((prevExpanded) =>
      expanded.includes(id)
        ? prevExpanded.filter((expandedId) => expandedId !== id)
        : [...prevExpanded, id]
    );
  };

  const renderAccordion = (item: FieldGroup, isNested = false) => {
    const hasChildren = item?.field_groups
      ? item?.field_groups?.length > 0
      : false;

    const muiAccordionStyle = {
      boxShadow: "none",
      "&:before": { display: "none" },
      borderBottom: "none",
    };

    const itemDisabledStyles = item.disabled
      ? { color: "grey.300", backgroundColor: "grey.100" }
      : {};

    if (!hasChildren) {
      return (
        <MuiAccordion
          key={item.id}
          sx={muiAccordionStyle}
          disableGutters
          onClick={(e) => {
            e.stopPropagation();

            if (item.disabled) {
              return null;
            }
            toggleItemExpand(item.id);
            onItemClick(item.id);
          }}
        >
          <StyledAccordionSummary
            key={item.id}
            isActive={item.id === activeItemId}
            aria-controls={`panel-${item.id}-content`}
            id={`panel-${item.id}-header`}
            sx={{
              boxShadow: "none",
              "&:before": { display: "none" },
              ...itemDisabledStyles,
              "& .MuiAccordionSummary-content": {
                display: "flex",
                pl: isNested ? 2 : 4,
                flexDirection: "column",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              {item.leftComponent}
              <Box>
                <Typography variant="bodyMedium16">{item.name}</Typography>
                <AccordionSubheader>{item.subheader}</AccordionSubheader>
              </Box>
            </Box>
          </StyledAccordionSummary>
        </MuiAccordion>
      );
    }

    return (
      <MuiAccordion
        key={item.id}
        sx={muiAccordionStyle}
        disableGutters
        onClick={(e) => {
          e.stopPropagation();

          if (item.disabled) {
            return null;
          }
          toggleItemExpand(item.id);
          onItemClick(item.id);
        }}
        expanded={expanded.includes(item.id)}
      >
        <StyledAccordionSummary
          isActive={item.id === activeItemId}
          expandIcon={<ArrowRightIcon />}
          aria-controls={`panel-${item.id}-content`}
          id={`panel-${item.id}-header`}
          sx={{
            flexDirection: "row-reverse",
            ...itemDisabledStyles,
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(90deg)",
            },

            ...(isNested
              ? {
                  pl: 3,
                }
              : {}),
          }}
        >
          <Box sx={{ flexDirection: "column" }}>
            <Typography variant="bodyMedium16">{item.name}</Typography>
            <AccordionSubheader>{item.subheader}</AccordionSubheader>
          </Box>
        </StyledAccordionSummary>

        <AccordionDetails sx={{ py: 0, pr: 0 }}>
          <Box sx={{ width: "100%" }}>
            {item?.field_groups?.map((subItem) =>
              renderAccordion(subItem, true)
            )}
          </Box>
        </AccordionDetails>
      </MuiAccordion>
    );
  };

  return (
    <div data-testid={dataTestid || "Accordion"}>
      {data.map((item) => renderAccordion(item))}
    </div>
  );
};
