import { QuestionTile } from "../QuestionTile/QuestionTile";
import { TableField, TableFieldProps } from "../TableField/TableField";

interface QuestionTableTileProps extends TableFieldProps {
  id: string;
  question: string;
  iconClickHandler: () => void;
  onCardFocus: () => void;
  required?: boolean;
  isHintAvailable?: boolean;
  label?: string;
}

export function QuestionTableTile({
  id,
  question,
  iconClickHandler,
  required,
  isHintAvailable,
  label,
  columns,
  rows,
  handleChangeValue,
  error,
  resetError,
  canEdit,
  onCardFocus,
}: QuestionTableTileProps) {
  return (
    <QuestionTile
      key={id}
      question={question}
      htmlFor={id}
      onIconClick={iconClickHandler}
      required={required}
      isHintAvailable={isHintAvailable}
      label={label}
      onFocus={onCardFocus}
    >
      <TableField
        name={question}
        columns={columns}
        canEdit={canEdit}
        resetError={resetError}
        handleChangeValue={handleChangeValue}
        rows={rows}
        error={error}
      />
    </QuestionTile>
  );
}
