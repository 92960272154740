import { createElement } from "react";
import { useTranslation } from "next-i18next";
import { Verdict } from "@applied-ai/types";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import {
  Box,
  None,
  SquareOutlined,
  SvgIconProps,
  Triangle,
} from "../../reexports";
import { Tooltip } from "../../atoms";
import { ProhibitedIcon } from "./ProhibitedIcon";

export type VerdictSize = "xs" | "small" | "medium";

interface ComplianceIconProps {
  type: Verdict;
  size: VerdictSize;
}

type ComplianceLevelMap = {
  [Key in Verdict]: string;
};

type ComplianceLevelIconMap = {
  [Key in Verdict]: React.FC<SvgIconProps>;
};

type SizeMap = {
  [Key in VerdictSize]: [string, string];
};

const colorMap: Partial<ComplianceLevelMap> = {
  LOW_RISK: "green.500",
  HUMAN_INTERACTION: "accent.blue",
  HIGH_RISK: "accent.orange",
  PROHIBITED: "red.500",
  IN_PROGRESS: "grey.200",
  NOT_IN_SCOPE: "grey.500",
};

const sizeMap: SizeMap = {
  medium: ["2rem", "1.5rem"],
  small: ["1.5rem", "1.125rem"],
  xs: ["1rem", "0.9375rem"],
};

const levelIconMap: Partial<ComplianceLevelIconMap> = {
  LOW_RISK: Triangle,
  HUMAN_INTERACTION: SquareOutlined,
  HIGH_RISK: Triangle,
  IN_PROGRESS: None,
  NOT_IN_SCOPE: DoDisturbIcon,
};

const iconSxPropsMap: Partial<{
  [Key in Verdict]: Record<string, string>;
}> = {
  LOW_RISK: {
    transform: "rotate(180deg)",
  },
};

export const ComplianceIcon: React.FC<ComplianceIconProps> = ({
  type,
  size = "medium",
}) => {
  const { t } = useTranslation(["common"]);
  const iconColor = [Verdict.IN_PROGRESS].includes(type)
    ? "grey.400"
    : "common.white";
  const [boxSize, iconSize] = sizeMap[size];

  const renderIcon = (type: Verdict) => {
    if (type === Verdict.PROHIBITED) {
      return <ProhibitedIcon />;
    }

    if (type === Verdict.HIGH_RISK) {
      return (
        <Triangle
          data-testid="HighRiskIcon"
          sx={{
            color: "white",
            padding: "4px",
            borderRadius: "0.25rem",
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            backgroundColor: colorMap[type] || colorMap.IN_PROGRESS!,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: boxSize,
            height: boxSize,
            position: "relative",
            fontSize: "1.125rem",
          }}
        />
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return createElement(levelIconMap[type] || levelIconMap.IN_PROGRESS!, {
      sx: { color: iconColor, fontSize: iconSize, ...iconSxPropsMap[type] },
    });
  };

  return (
    <Tooltip arrow placement="top" title={t(`common:verdicts:${type}`)}>
      <div>
        <Box
          sx={{
            padding: "4px",
            borderRadius: "0.25rem",
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            backgroundColor: colorMap[type] || colorMap.IN_PROGRESS!,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: boxSize,
            height: boxSize,
            position: "relative",
          }}
        >
          {renderIcon(type)}
        </Box>
      </div>
    </Tooltip>
  );
};
