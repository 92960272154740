import { SxProps } from "@mui/material";
import { Button, Typography } from "../../atoms";
import { ReactNode } from "react";

interface ButtonProps {
  onClick: () => void;
  icon: ReactNode;
  value: string;
  disabled?: boolean;
  sx?: SxProps;
}

export const ActionButton = ({
  onClick,
  value,
  icon,
  disabled,
  sx,
}: ButtonProps) => {
  return (
    <Button
      variant="text"
      data-testid="share-button"
      onClick={onClick}
      // startIcon={icon}
      disabled={disabled}
      sx={{ ...sx }}
    >
      {icon}
      <Typography variant="bodyMedium14" sx={{ color: "primary.500" }}>
        {value}
      </Typography>
    </Button>
  );
};
