import { NextRouter } from "next/router";

const createRcfBasePath = (router: NextRouter) => {
  const basePath = `/use-cases/${router.query.useCaseId}/stage/${router.query.stageId}`;
  return basePath;
};

interface Props {
  path?: string;
  query?: Record<string, string>;
  hash?: string;
  router: NextRouter;
}

export const createRcfRouteState = ({ path, query, hash, router }: Props) => {
  let basePath = createRcfBasePath(router);
  let urlParams = { fieldGroupId: router.query.fieldGroupId as string };

  if (path) {
    basePath = basePath + path;
  }

  if (query) {
    urlParams = { ...urlParams, ...query };
  }

  return {
    pathname: basePath,
    query: urlParams,
    hash: hash,
  };
};
