import { BreadcrumbLink } from "../../atoms";
import { Breadcrumbs } from "../../reexports";
import { EllipsisWithTooltip } from "../EllipsisWithTooltip/EllipsisWithTooltip";

interface BreadcrumbsData {
  href: string;
  title: string;
}

interface BreadcrumbsProps {
  data: BreadcrumbsData[];
}

export function CustomBreadcrumbs({ data }: BreadcrumbsProps) {
  return (
    <Breadcrumbs
      sx={{ height: "20px", lineHeight: "20px" }}
      data-testid="breadcrumbs-wrapper"
    >
      {data.map((item, index) => (
        <BreadcrumbLink
          href={item.href}
          data-testid="breadcrumb-link"
          key={index}
        >
          <EllipsisWithTooltip
            tooltipSx={{
              "& .MuiTooltip-tooltip": {
                maxWidth: "267px",
                padding: "4px 8px",
                margin: "8px 0 0 !important",
                wordBreak: "break-word",
              },
            }}
          >
            <span>{item.title}</span>
          </EllipsisWithTooltip>
        </BreadcrumbLink>
      ))}
    </Breadcrumbs>
  );
}
