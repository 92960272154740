import {
  AppBar,
  Box,
  Container,
  Divider,
  Toolbar,
  WhiteUctsLogo,
} from "@applied-ai/components";
import { filterNavLinksByFeatureFlags } from "@applied-ai/utils";
import { AppsConfigPayload, Page, UctsConfigPayload } from "@applied-ai/types";
import { Session } from "next-auth";
import React from "react";
import { HelpMenu } from "./components/HelpMenu";
// import { LanguageMenu } from "./components/LanguageMenu";
import { NavigationTabs } from "./components/NavigationTabs";
import { ProfileMenu } from "./components/ProfileMenu";

export const Navbar: React.FC<{
  session?: Session | null;
  pages?: Page[];
  position?: React.ComponentProps<typeof AppBar>["position"];
  config?: AppsConfigPayload;
}> = ({ pages = [], session, position = "fixed", config }) => {
  const uctsConfig = config?.ucts as UctsConfigPayload;

  const navLinksFilteredByFeatureFlags = filterNavLinksByFeatureFlags(
    pages,
    uctsConfig?.feature_flags
  );

  return (
    <Box sx={{ height: "48px" }}>
      <AppBar position={position} sx={{ boxShadow: "unset" }}>
        <Container>
          <Toolbar disableGutters variant="dense">
            <Box
              sx={{
                width: "140px",
                height: "28px",
                "& > svg": { width: "100%", height: "100%" },
              }}
            >
              <WhiteUctsLogo />
            </Box>
            {session && (
              <NavigationTabs
                pages={navLinksFilteredByFeatureFlags}
                config={config}
              />
            )}
            {/* // TODO: temporarily commented as we turn off language selection */}
            {/* <Box sx={{ mr: "8px", ml: "auto" }}>
              <LanguageMenu />
            </Box> */}
            <HelpMenu />
            {session && (
              <>
                <Divider
                  sx={{
                    height: "24px",
                    marginInline: "24px",
                    color: "primary.400",
                    borderRight: "1px solid",
                  }}
                />
                <ProfileMenu user={session.user} />
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};
