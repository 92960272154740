import { SxProps, Theme, alpha, styled } from "@mui/material";
import { MentionsInput, Mention, DataFunc, MentionProps } from "react-mentions";
import { theme } from "../../theme/theme";
import { Typography } from "../../atoms";
import { MentionUser } from "./MentionTypes";
import { EllipsisWithTooltip } from "../EllipsisWithTooltip/EllipsisWithTooltip";

const MentionInputStyled = styled(MentionsInput)`
  font-size: 0.875rem;

  textarea {
    padding: 8px 12px 8px 8px;
    background-color: ${theme.palette.grey[50]};
    line-height: 1.25rem;

    border: 1px solid ${theme.palette.grey[300]};
    border-radius: 4px;

    color: ${theme.palette.grey[700]};
    word-break: break-all;

    &::placeholder {
      color: ${theme.palette.grey[500]};
      opacity: 1;
    }

    &:hover {
      border-color: ${theme.palette.primary[500]};
    }

    &:focus {
      outline: 0px;
      border-color: ${theme.palette.primary[500]};
      box-shadow: 0px 0px 4px 0px ${alpha(theme.palette.primary[500], 0.6)};
    }
  }

  .mentionInput__highlighter {
    padding: 8px 12px 8px 8px;
    line-height: 1.25rem;
    word-break: break-all;
  }

  .mention {
    z-index: 1;
    position: relative;

    color: ${theme.palette.blue[500]};
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    text-decoration: none;
    pointer-events: none;
  }

  .mentionInput__suggestions {
    z-index: 2 !important;
    top: 18px !important;
    padding: 8px 0;
    border: solid 1px ${theme.palette.grey[100]};

    &__list {
      width: 240px;
      max-height: 208px;
      overflow-y: auto;
    }

    &__item {
      display: grid;
      gap: 4px;
      padding: 8px 16px;
    }

    &__item--focused {
      background-color: ${theme.palette.grey[100]};
    }
  }
`;

interface Props {
  value: string;
  getMentions: DataFunc;
  onAdd?: MentionProps["onAdd"];
  sx?: SxProps<Theme>;

  onChange: (value: string, mentions: string[]) => void;
  placeholder: string;
}

export const MentionTextField = ({
  onChange,
  value,
  getMentions,
  onAdd,
  placeholder,
  sx,
}: Props) => {
  return (
    <MentionInputStyled
      placeholder={placeholder}
      value={value}
      onChange={(e, _newValue, _newPlainTextValue, mentions) => {
        const mentionsIds = mentions.map((mention) => mention.id);
        const setOfMentionsIds = [...new Set(mentionsIds)];
        onChange(e.target.value, setOfMentionsIds);
      }}
      className="mentionInput"
      sx={sx}
    >
      <Mention
        className="mention"
        trigger="@"
        markup="@{{__id__||__display__}}"
        displayTransform={(_, display) => `@${display}`}
        onAdd={onAdd}
        data={getMentions}
        renderSuggestion={(item) => {
          const typedItem = item as MentionUser;
          return (
            <>
              <Typography variant="bodyRegular14">
                <EllipsisWithTooltip disableTooltip>
                  {typedItem.display}
                </EllipsisWithTooltip>
              </Typography>
              <Typography variant="bodyRegular12">
                <EllipsisWithTooltip disableTooltip>
                  {typedItem.email}
                </EllipsisWithTooltip>
              </Typography>
            </>
          );
        }}
      />
    </MentionInputStyled>
  );
};
