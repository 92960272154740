import { SortByValues, sortByValues } from "@applied-ai/types";
import { CARD_LIMIT_ON_FIRST_PAGE, CARD_LIMIT_ON_PAGE } from "../../constants";

export const calculateOffset = (page: number) => {
  if (page === 1) {
    return 0;
  }
  return CARD_LIMIT_ON_FIRST_PAGE + CARD_LIMIT_ON_PAGE * (page - 2);
  // page -2 because we adding CARD_LIMIT_ON_PAGE only for pages after first page ex. on page 2 we will only have offset = CARD_LIMIT_ON_FIRST_PAGE
};

export const calculatePageCount = (itemCount: number) =>
  Math.ceil((itemCount - CARD_LIMIT_ON_FIRST_PAGE) / CARD_LIMIT_ON_PAGE) + 1;

export const isSortByValue = (sortBy: unknown): sortBy is SortByValues => {
  return sortByValues.some((value) => value === sortBy);
};
