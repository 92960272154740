import ChecklistOutlined from "@mui/icons-material/ChecklistOutlined";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";

import { APP_CONTAINER_PADDING, Box, Button } from "../../atoms";
import { Drawer as MuiDrawer, useTheme } from "../../reexports";
import {
  COLLAPSED_SIDEBAR_WIDTH,
  SIDEBAR_WIDTH,
  SIDEBAR_X_PADDING,
} from "@applied-ai/utils";

interface SidebarDrawerProps {
  isOpen: boolean;
  toggleOpen: () => void;
  children: React.ReactNode;
  topSlot?: React.ReactNode;
}

export const SidebarDrawer = ({
  isOpen,
  toggleOpen,
  children,
  topSlot,
}: SidebarDrawerProps) => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          position: "relative",
          height: "100%",
        }}
      >
        <MuiDrawer
          open={isOpen}
          onClose={toggleOpen}
          variant="permanent"
          sx={{
            width: isOpen ? SIDEBAR_WIDTH : COLLAPSED_SIDEBAR_WIDTH,
            "& .MuiDrawer-paper": {
              position: "relative",
              border: "unset",
              visibility: "visible",
              transform: "none",
            },
            "*::-webkit-scrollbar-track": {
              backgroundColor: "common.white",
            },
          }}
        >
          {topSlot && (
            <Box
              sx={{
                display: isOpen ? "flex" : "none",
                width: "100%",
                height: "68px",
                padding: "16px",
                borderBottom: `1px solid ${theme.palette.grey[100]}`,
              }}
            >
              {topSlot}
            </Box>
          )}
          {isOpen && (
            <Box
              sx={{
                padding: "0 16px",
                height: "100%",
                overflowY: "scroll",
              }}
            >
              {children}
            </Box>
          )}
          {!isOpen && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                padding: "24px 28px",
              }}
            >
              <ChecklistOutlined fontSize="small" />
            </Box>
          )}
        </MuiDrawer>
      </Box>
      <Button
        sx={{
          width: "16px",
          height: "36px",
          position: "absolute",
          top: "50%",
          left: isOpen
            ? SIDEBAR_WIDTH + SIDEBAR_X_PADDING * 2 - APP_CONTAINER_PADDING
            : COLLAPSED_SIDEBAR_WIDTH,
          zIndex: 1201,
          backgroundColor: "common.white",
          paddingLeft: "4px",
          paddingRight: "6px",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          border: "1px solid !important",
          borderColor: `${theme.palette.grey[200]} !important`,
          borderLeft: "none !important",
        }}
        onClick={toggleOpen}
        variant="text"
      >
        {isOpen ? <ChevronLeft /> : <ChevronRight />}
      </Button>
    </>
  );
};
