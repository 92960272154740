import { Box } from "../../atoms";

export const ModalFooter: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Box
    sx={{
      padding: "16px 24px",
      borderTop: "1px solid",
      borderColor: "grey.200",
    }}
  >
    {children}
  </Box>
);
