import React from "react";

export function ProhibitedIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="ProhibitedIcon"
    >
      <path
        d="M4.1875 13.75L0.25 9.8125V4.1875L4.1875 0.25H9.8125L13.75 4.1875V9.8125L9.8125 13.75H4.1875ZM4.8625 10.1875L7 8.05L9.1375 10.1875L10.1875 9.1375L8.05 7L10.1875 4.8625L9.1375 3.8125L7 5.95L4.8625 3.8125L3.8125 4.8625L5.95 7L3.8125 9.1375L4.8625 10.1875ZM4.825 12.25H9.175L12.25 9.175V4.825L9.175 1.75H4.825L1.75 4.825V9.175L4.825 12.25Z"
        fill="white"
      />
    </svg>
  );
}
