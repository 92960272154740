import { useRouter } from "next/router";

export const useRedirect = (fallback?: string) => {
  const router = useRouter();

  let redirectToUrl: string;

  if (router.query["redirectUrl"])
    redirectToUrl = router.query["redirectUrl"] as string;
  else if (fallback) redirectToUrl = fallback;

  const redirectTo = () => {
    if (redirectToUrl && redirectToUrl.startsWith("/")) {
      return router.push(redirectToUrl);
    }

    if (redirectToUrl && redirectToUrl.startsWith("http")) {
      window.location.href = redirectToUrl;
      return;
    }

    return router.back();
  };
  return { redirectTo };
};
