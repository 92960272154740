import { useState } from "react";
import { useTranslation } from "next-i18next";
import { AaiFileObjectDto, UserDto } from "@applied-ai/types";
import { Typography } from "../../atoms";
import { Box, useTheme } from "../../reexports";
import { z } from "zod";
import { CustomFile, DropArea, onDropFile } from "./DropArea";
import { FileStatus, FileTile } from "./FileTile";
import { parseAddedFiles } from "./parseAddedFiles";

export const acceptedFileExtensions = [
  "image/jpeg",
  "image/png",
  "application/pdf",
  "application/json",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/csv",
  "text/plain",
] as const;

type AcceptedFileExtensions = (typeof acceptedFileExtensions)[number];

interface FileValidatorProps {
  file: File;
  nameLength: number;
  validFileExtensions: readonly AcceptedFileExtensions[];
  maxSizeMB: number;
}

interface UploadResponse {
  status: string;
  value: {
    id: string;
    file: AaiFileObjectDto;
    created_by: UserDto;
    created_at: string;
    updated_at: string;
  };
}

export interface UploadedFile {
  id: string;
  filename: string;
  url: string;
  status: FileStatus;
  errors?: string[];
}

export interface FileToUpload {
  filename: string;
  status: FileStatus;
  errors?: string[];
  file: CustomFile;
}

interface Props<T> {
  uploadFileFn: (file: Blob) => Promise<T>;
  description: string;
  canEdit: boolean;
  successCallback: (
    value: string | string[],
    successCb: () => void
  ) => Promise<void>;
  options: UploadedFile[];
  errorMessage?: string;
}

export const UploadFileField = <T,>({
  description,
  uploadFileFn,
  successCallback,
  options,
  errorMessage,
  canEdit,
}: Props<T>) => {
  const { t } = useTranslation(["common"]);
  const theme = useTheme();
  const MAX_FILENAME_LENGTH = 100;
  const MAX_FILE_SIZE = 100;
  const [files, setFiles] = useState<UploadedFile[]>(options);

  const getExistingFilesIds = () => {
    return files.reduce((acc, crr) => {
      if (crr?.id) {
        acc.push(crr.id);
      }

      return acc;
    }, [] as string[]);
  };

  const onFilesUpload = async (onDropFiles: onDropFile[]) => {
    // Validating and passing errors
    const newFiles: FileToUpload[] = onDropFiles.map(({ file, errors }) => {
      const validateNameDuplication = () => {
        return files.reduce((acc, current) => {
          if (current.filename === file.name) {
            return acc + 1;
          } else {
            return acc;
          }
        }, 0);
      };

      const nameDuplicationResult = validateNameDuplication();
      if (nameDuplicationResult >= 1)
        errors = [t("common:thisFileAlreadyExists")];

      if (errors) {
        console.error(errors);
      }

      return {
        filename: file.name,
        file,
        errors,
        status: errors ? "error" : "loading",
      };
    });

    // Building promises
    const filesUploadPromises = newFiles
      .filter((f) => f.status === "loading")
      .map(({ file }) => {
        return uploadFileFn(file);
      });
    const filesWithValidationError = newFiles.filter((f) => f.errors);
    const uploadedFiles = await Promise.allSettled(filesUploadPromises);

    // Taking ids of already uploaded files
    const uploadedFilesIds = uploadedFiles
      .filter((f) => {
        return f.status === "fulfilled";
      })
      .map((response) => {
        const result = response as unknown as UploadResponse;
        return result.value.id;
      })
      .filter((i) => i);

    await successCallback([...uploadedFilesIds, ...getExistingFilesIds()], () =>
      setFiles((alreadyUploadedFiles) => [
        ...alreadyUploadedFiles,
        ...parseAddedFiles(uploadedFiles),
        ...parseAddedFiles(filesWithValidationError),
      ])
    );
  };

  const onFileDelete = async (id: string, name?: string) => {
    if (!id) {
      const fileToDelete = files.find(
        (file) => file?.filename === name && file.errors
      );

      const filesToDisplay = files
        .map((file) => file)
        .filter((item) => {
          return item !== fileToDelete;
        });

      setFiles(filesToDisplay);
      return;
    }

    const updatedFiles = () => files.filter((file) => file.id !== id);

    const filesToDisplay = updatedFiles();

    const filesIds = filesToDisplay
      .map((file) => file.id)
      .filter((id) => id !== undefined);

    await successCallback(filesIds as string[] | string, () =>
      setFiles(filesToDisplay)
    );
  };

  const fileValidator = ({
    file,
    nameLength,
    validFileExtensions,
    maxSizeMB,
  }: FileValidatorProps) => {
    const maxSizeB = maxSizeMB * 1024 * 1024;

    const FileSchema = z.object({
      name: z.string().max(nameLength, t("fileLengthError", { nameLength })),
      type: z.string().refine(
        (ext) => {
          return validFileExtensions.includes(ext as AcceptedFileExtensions);
        },
        {
          message: t("invalidFileType") + validFileExtensions.join(", "),
        }
      ),
      size: z.number().max(maxSizeB, {
        message: t("fileSizeError", { maxSizeMB: maxSizeB / (1024 * 1024) }),
      }),
    });

    const result = FileSchema.safeParse(file);

    if (!result.success) {
      return result.error.issues.map((issue) => {
        return issue.message;
      });
    }
  };

  return (
    <Box
      sx={{
        display: "grid",
        [`@media (min-width: ${theme.breakpoints[1600]})`]: {
          width: "50%",
        },
      }}
    >
      <Typography variant="bodyMedium14" sx={{ color: "grey.600", mb: "4px" }}>
        {t("common:uploadFilesTitle")}
      </Typography>

      <Typography variant="bodyRegular12" sx={{ color: "grey.600" }}>
        {description}
      </Typography>
      <Box
        sx={{
          display: "grid",
          gap: "8px",
          marginTop: "16px",
          width: "416px",
        }}
      >
        <DropArea
          disabled={!canEdit}
          onDrop={onFilesUpload}
          validation={(file) =>
            fileValidator({
              file,
              validFileExtensions: acceptedFileExtensions,
              nameLength: MAX_FILENAME_LENGTH,
              maxSizeMB: MAX_FILE_SIZE,
            })
          }
        />
        {files.map(({ url: fileUrl, filename, id, errors, status }, index) => {
          if (errors && errors[0].includes("File extension forbidden"))
            errors = [t("common:invalidFileTypeWithInfo")];

          if (
            errors &&
            errors[0].includes("File with given path already exists")
          )
            errors = [t("common:thisFileAlreadyExists")];

          return (
            <FileTile
              id={id ?? ""}
              key={index}
              name={filename}
              status={status}
              errorMessages={errors}
              onDeleteFile={onFileDelete}
              canEdit={canEdit}
              fileUrl={fileUrl}
            />
          );
        })}
        {errorMessage && (
          <Typography variant="bodyRegular12" sx={{ color: "red.500" }}>
            {errorMessage}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
