import AccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "../../reexports/styleUtils";

export const StyledAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{ isActive: boolean }>`
  padding: 12px;
  border-radius: 4px;
  gap: 8px;
  background-color: ${(p) =>
    p.isActive ? p.theme.palette.primary[50] : p.theme.palette.common.white};

  &:hover {
    background-color: ${(p) =>
      p.isActive ? p.theme.palette.primary[50] : p.theme.palette.grey[100]};
  }

  & .MuiAccordionSummary-content {
    margin: 0;
    &.Mui-expanded {
      margin: 0;
    }
  }

  &.Mui-expanded {
    min-height: 0;
  }
`;
