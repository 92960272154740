export const CheckIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <mask id="a" width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
      <path
        fill="#D1E9EB"
        d="M0 12c0-2.453 0-3.679.457-4.781.456-1.103 1.324-1.97 3.058-3.704C5.249 1.78 6.116.913 7.219.457 8.32 0 9.547 0 12 0s3.679 0 4.781.457c1.103.456 1.97 1.324 3.704 3.058 1.735 1.734 2.602 2.601 3.058 3.704C24 8.32 24 9.547 24 12s0 3.679-.457 4.781c-.456 1.103-1.323 1.97-3.058 3.704-1.734 1.735-2.601 2.602-3.704 3.058C15.68 24 14.453 24 12 24s-3.679 0-4.781-.457c-1.103-.456-1.97-1.323-3.704-3.058C1.78 18.751.913 17.884.457 16.781 0 15.68 0 14.453 0 12Z"
      />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#D1E9EB"
        d="M0 12c0-2.453 0-3.679.457-4.781.456-1.103 1.324-1.97 3.058-3.704C5.249 1.78 6.116.913 7.219.457 8.32 0 9.547 0 12 0s3.679 0 4.781.457c1.103.456 1.97 1.324 3.704 3.058 1.735 1.734 2.602 2.601 3.058 3.704C24 8.32 24 9.547 24 12s0 3.679-.457 4.781c-.456 1.103-1.323 1.97-3.058 3.704-1.734 1.735-2.601 2.602-3.704 3.058C15.68 24 14.453 24 12 24s-3.679 0-4.781-.457c-1.103-.456-1.97-1.323-3.704-3.058C1.78 18.751.913 17.884.457 16.781 0 15.68 0 14.453 0 12Z"
      />
      <path
        fill="#00747B"
        fillRule="evenodd"
        d="M16.578 9.625a.596.596 0 0 1 0 .843l-5.368 5.368a.597.597 0 0 1-.843 0l-3.28-3.28a.596.596 0 0 1 0-.844l.843-.843a.596.596 0 0 1 .843 0l1.594 1.593c.233.233.61.233.843 0l3.681-3.68a.596.596 0 0 1 .844 0l.843.843Z"
        clipRule="evenodd"
      />
    </g>
  </svg>
);
