import { useTranslation } from "next-i18next";
import { Box, Modal } from "../../reexports";
import Typography from "@mui/material/Typography";
import { Button } from "../../atoms";
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
  ModalWrapper,
} from "../ModalComponents";
import { ToastVariants, useToastStore } from "../../store/toastStore";
import { useRouter } from "next/router";
import { SnackbarOrigin } from "@mui/material";
import { ApiError } from "@applied-ai/types";

interface DeleteModalProps {
  isOpen: boolean;
  toggleDeleteModal: (event: React.MouseEvent<HTMLElement>) => void;
  title: string;
  description: React.ReactNode;
  idToDelete: string;
  deleteFunction: (id: string) => Promise<void>;
  toast?: {
    message: string;
    undoAction?: (id: string) => Promise<unknown>;
  };
  redirect?: string;
  forOpenPoints?: boolean;
}

export const DeleteModal = ({
  isOpen,
  toggleDeleteModal,
  title,
  description,
  idToDelete,
  deleteFunction,
  toast,
  redirect,
  forOpenPoints,
}: DeleteModalProps) => {
  const router = useRouter();
  const { t } = useTranslation(["common", "ucts"]);
  const { addToast } = useToastStore();

  const handleDeleteItem = async (event: React.MouseEvent<HTMLElement>) => {
    try {
      await deleteFunction(idToDelete);
    } catch (e) {
      addToast({
        type: ToastVariants.ERROR,
        message:
          (e as ApiError).message || t("common:somethingWentWrongTryAgain"),
        position: { vertical: "top", horizontal: "right" },
      });
      return;
    }

    toggleDeleteModal(event);
    router.push(redirect || router.asPath);

    const deleteToastConfig = {
      type: ToastVariants.INFO,
      message: toast?.message,
      position: { vertical: "bottom", horizontal: "left" } as SnackbarOrigin,
      actionLabel: t("common:undo"),
      callbackAction: () => handleUndo(toast?.undoAction),
    };

    const deletOpenPointToastConfig = {
      type: ToastVariants.SUCCESS,
      message: toast?.message,
      position: { vertical: "top", horizontal: "right" } as SnackbarOrigin,
    };

    if (toast) {
      if (forOpenPoints) {
        addToast(deletOpenPointToastConfig);
      } else {
        addToast(deleteToastConfig);
      }
    }
  };

  const handleUndo = async (undoAction?: (id: string) => Promise<unknown>) => {
    if (undoAction) {
      try {
        await undoAction(idToDelete);
      } catch (e) {
        addToast({
          type: ToastVariants.ERROR,
          message:
            (e as ApiError).message || t("common:somethingWentWrongTryAgain"),
          position: { vertical: "top", horizontal: "right" },
        });
        return;
      }
    }

    router.push(router.asPath);

    addToast({
      type: ToastVariants.INFO,
      message: t("ucts:useCase:actionUndone"),
      position: { vertical: "bottom", horizontal: "left" },
    });
  };

  return (
    <Modal open={isOpen} onClose={toggleDeleteModal} data-testid="delete-modal">
      <ModalWrapper>
        <ModalHeader toggleModal={toggleDeleteModal}>{title}</ModalHeader>
        <ModalContent>
          <Typography variant="bodyMedium16">{description}</Typography>
        </ModalContent>
        <ModalFooter>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="text"
              sx={{ marginLeft: "auto" }}
              onClick={toggleDeleteModal}
              data-testid="cancel-button"
            >
              {t("cancel")}
            </Button>
            <Button onClick={handleDeleteItem} data-testid="delete-button">
              {t("delete")}
            </Button>
          </Box>
        </ModalFooter>
      </ModalWrapper>
    </Modal>
  );
};
