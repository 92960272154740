import { SectionDto } from "@applied-ai/types";

export function findNextArrayElement(
  array: SectionDto[],
  searchElement: string
) {
  const index = array.findIndex((section) => section.id === searchElement);

  return array[index + 1];
}
