import { LinkProps as MuiLinkProps } from "@mui/material";
import MuiLink from "@mui/material/Link";
import NextLink from "next/link";
import React from "react";
import { UrlObject } from "url";

type ButtonSize = "medium" | "large";

export interface LinkProps extends MuiLinkProps {
  children: React.ReactNode;
  id?: string;
  size?: ButtonSize;
  as?: string | UrlObject;
  disabled?: boolean;
  locale?: false | string;
}

const SIZE_STYLES = {
  medium: {
    fontSize: "0.75rem",
    lineHeight: "1rem",
  },
  large: {
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
};

export const Link: React.FC<LinkProps> = ({
  children,
  size = "medium",
  disabled,
  ...props
}) => {
  return (
    <MuiLink
      component={NextLink}
      sx={{
        fontWeight: 500,
        ...(SIZE_STYLES[size] || {}),

        "&:hover": {
          color: "primary.600",
        },
        "&:focus": {
          textDecoration: "underline",
          outline: "none",
        },
        "&:visited": {
          color: "accent.blue",
        },
        ...(disabled
          ? {
              pointerEvents: "none",
              color: "grey.600",
            }
          : {}),
        ...(props.sx || {}),
      }}
      underline="hover"
      {...props}
    >
      {children}
    </MuiLink>
  );
};
