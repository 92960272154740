export const getBorderShadow = (
  color: string,
  placement: "all" | "top" | "right" | "bottom" | "left" = "all",
  border = 1
) => {
  switch (placement) {
    case "top": {
      return `inset 0 ${border}px 0 0 ${color}`;
    }
    case "bottom": {
      return `inset 0 -${border}px 0 0 ${color}`;
    }
    case "right": {
      return `inset -${border}px 0 0 0 ${color}`;
    }
    case "left": {
      return `inset ${border}px 0 0 0 ${color}`;
    }
    default:
      return `inset 0 0 0 ${border}px ${color}`;
  }
};
