export const generatePageTitle = (path: string) => {
  const baseTitle = "UCM";

  const segments = path.startsWith("/")
    ? path.slice(1).split("/")
    : path.split("/");

  const titleMap: { [key: string]: { base?: string; withDetails?: string } } = {
    "": { base: "Dashboard" },
    "use-cases": { withDetails: "Use Case Form" },
    "data-sources": {
      base: "Data Sources",
      withDetails: "Data Source Form",
    },
    models: {
      base: "Models",
      withDetails: "Model Form",
    },
    portfolio: { base: "Portfolio", withDetails: "Portfolio" },
    "my-profile": { base: "My Profile" },
    "404": { base: "404" },
    "403": { base: "403" },
    "500": { base: "500" },
  };

  const firstSegment = segments[0];
  const hasDetails = segments.length > 1;

  if (titleMap[firstSegment]) {
    return `${baseTitle} | ${
      hasDetails
        ? titleMap[firstSegment].withDetails
        : titleMap[firstSegment].base
    }`;
  }
};
