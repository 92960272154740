import { Component, ErrorInfo, ReactNode } from "react";
import Router from "next/router";

interface Props {
  children?: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
  fallback?: ReactNode;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  handleNavigate = () => {
    this.setState({ hasError: false });
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  componentDidMount() {
    Router.events.on("routeChangeComplete", this.handleNavigate);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps !== this.props) {
      this.handleNavigate();
    }
  }

  componentWillUnmount() {
    Router.events.off("routeChangeComplete", this.handleNavigate);
  }

  public render() {
    if (this.state.hasError) return this.props.fallback;

    return this.props.children;
  }
}
