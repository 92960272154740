import { theme } from "../../theme/theme";
import MuiCircularProgress from "@mui/material/CircularProgress";
import { CircularProgressProps, styled } from "@mui/material";

type SIZE_VARIANT = "small" | "medium" | "large";
type COLOR_VARIANT = "primary" | "secondary";

export interface SpinnerProps extends CircularProgressProps {
  size?: SIZE_VARIANT;
  color?: COLOR_VARIANT;
}

interface SpinnerStyledProps extends SpinnerProps {
  size: SIZE_VARIANT;
  color: COLOR_VARIANT;
}

const SIZE_MAPPING: Record<SIZE_VARIANT, string> = {
  large: "48px",
  medium: "24px",
  small: "16px",
};

const COLOR_MAPPING: Record<COLOR_VARIANT, string> = {
  primary: theme.palette.primary[500],
  secondary: theme.palette.common.white,
};

const StyledSpinner = styled(MuiCircularProgress)<SpinnerStyledProps>(
  ({ size, color }) => {
    return {
      color: COLOR_MAPPING[color],
      height: SIZE_MAPPING[size],
      width: SIZE_MAPPING[size],
    };
  }
);

export const Spinner: React.FC<SpinnerProps> = ({
  size = "medium",
  color = "primary",
  ...props
}) => {
  return <StyledSpinner size={size} color={color} {...props} />;
};
