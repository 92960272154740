import { Verdict } from "@applied-ai/types";

export const verdictName = (verdict: Verdict) => {
  switch (verdict) {
    case Verdict.HIGH_RISK:
    case Verdict.LOW_RISK:
    case Verdict.HIGH_RISK_AND_HUMAN_INTERACTION:
    case Verdict.HUMAN_INTERACTION:
    case Verdict.PROHIBITED:
    case Verdict.NOT_IN_SCOPE:
      return `common:verdicts.${verdict}`;
    default:
      return "common:verdicts.NONE";
  }
};
