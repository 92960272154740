import { ApiError } from "@applied-ai/types";
import { useTranslation } from "next-i18next";
import { ReactNode, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../../atoms";
import { Box, Modal } from "../../reexports";
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalWrapper,
} from "../ModalComponents";
import { TextFieldWithCounter } from "../TextFieldWithCounter/TextFieldWithCounter";

interface CreateModalProps {
  isOpen: boolean;
  toggleCreateModal: (event: React.MouseEvent<HTMLElement>) => void;
  modalTitle: string;
  description: ReactNode;
  createFunction: (title: string) => Promise<void>;
  submitButtonLabel?: string;
}

type CreateModalFormValues = {
  title: string;
};

export const CreateModal = ({
  isOpen,
  toggleCreateModal,
  modalTitle,
  description,
  createFunction,
  submitButtonLabel,
}: CreateModalProps) => {
  const { t } = useTranslation(["common"]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    watch,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<CreateModalFormValues>({
    mode: "onChange",
    defaultValues: { title: "" },
  });

  const handleCreateItem = async (data: { title: string }) => {
    if (!data.title.trim().length) {
      setError("title", { type: "required" });

      return;
    }

    try {
      clearErrors("title");
      setIsLoading(true);
      await createFunction(data.title);
    } catch (e) {
      const error = e as ApiError;

      setError("title", {
        type: "backend",
        message: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const title = watch("title");

  const renderErrorMessage = () => {
    if (errors.title?.type === "required") {
      return t("common:validation:provideAtLeastOneCharacter");
    }

    if (errors.title?.type === "maxLength") {
      return t("common:limitExceeded");
    }

    if (errors.title?.type === "backend") {
      return errors.title?.message;
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={isLoading ? () => null : toggleCreateModal}
      data-testid="create-modal"
    >
      <ModalWrapper>
        <ModalHeader toggleModal={toggleCreateModal} isLoading={isLoading}>
          {modalTitle}
        </ModalHeader>
        <ModalContent>
          {description}
          <Box sx={{ pt: "16px" }}>
            <form onSubmit={handleSubmit(handleCreateItem)}>
              <TextFieldWithCounter
                placeholder={t("enterTitle")}
                id={"title"}
                type={"text"}
                label={""}
                fullWidth
                required={true}
                maxLength={80}
                error={renderErrorMessage()}
                data-testid="enter-title-input"
                control={control}
                disabled={false}
                autoFocus
              />
            </form>
          </Box>
        </ModalContent>
        <ModalFooter>
          <Box sx={{ display: "flex", gap: 2, justifyContent: "end" }}>
            <Button
              variant="text"
              onClick={toggleCreateModal}
              disabled={isLoading}
              data-testid="cancel-button"
            >
              {t("cancel")}
            </Button>
            <Button
              onClick={handleSubmit(handleCreateItem)}
              disabled={!title || !!errors.title}
              data-testid="add-new-button"
            >
              {submitButtonLabel ?? t("addNew")}
            </Button>
          </Box>
        </ModalFooter>
      </ModalWrapper>
    </Modal>
  );
};
