import React from "react";
import { Tag } from "../../atoms";
import { styled } from "@mui/material";
import { theme } from "../../theme/theme";
import { UseCaseStatusStyle } from "@applied-ai/types";

interface StatusTagProps {
  label: string;
  clickable: boolean;
  customstyle: UseCaseStatusStyle;
}

interface StatusVariant {
  backgroundColor: string;
  borderColor: string;
  fontColor: string;
}

const statuses: Record<UseCaseStatusStyle, StatusVariant> = {
  [UseCaseStatusStyle.LIGHT_GREEN]: {
    backgroundColor: theme.palette.green[100],
    borderColor: theme.palette.green[300],
    fontColor: theme.palette.green[500],
  },
  [UseCaseStatusStyle.DARK_GREY]: {
    backgroundColor: theme.palette.grey[600],
    borderColor: theme.palette.grey[700],
    fontColor: theme.palette.common.white,
  },
  [UseCaseStatusStyle.WHITE]: {
    backgroundColor: theme.palette.grey[100],
    borderColor: theme.palette.grey[300],
    fontColor: theme.palette.grey[700],
  },
};

const StyledStatusTag = styled(Tag)<StatusTagProps>`
  background-color: ${(props) => statuses[props.customstyle]?.backgroundColor};
  border: 1px solid ${(props) => statuses[props.customstyle]?.borderColor};
  color: ${(props) => statuses[props.customstyle]?.fontColor};
  font-size: 0.75rem;
  line-height: 1rem;
  border-radius: 24px;
  box-shadow: none;
  padding: 3px 8px;
  cursor: ${(p) => (p.clickable ? "pointer" : "default")};

  &:hover {
    background-color: ${(props) =>
      statuses[props.customstyle]?.backgroundColor} !important;
    box-shadow: none !important;
  }
`;

export const StatusTag: React.FC<StatusTagProps> = ({
  clickable,
  label,
  customstyle,
}) => {
  return (
    <StyledStatusTag
      clickable={clickable}
      label={label}
      customstyle={customstyle}
    />
  );
};
