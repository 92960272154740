import Close from "@mui/icons-material/Close";
import { Box, Button, Typography } from "../../atoms";
import React from "react";

export const ModalHeader: React.FC<{
  children: React.ReactNode;
  toggleModal?: (event: React.MouseEvent<HTMLElement>) => void;
  isLoading?: boolean;
}> = ({ children, toggleModal, isLoading }) => (
  <Box
    sx={{
      py: 1.5,
      px: 2,
      pl: 3,
      borderBottom: "1px solid",
      borderColor: "grey.200",
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        variant="bodyMedium20"
        sx={{
          overflow: "hidden",
          textWrap: "nowrap",
          textOverflow: "ellipsis",
          color: "grey.900",
        }}
        data-testid="modal-title"
      >
        {children}
      </Typography>
      {toggleModal && (
        <Button
          icon={<Close sx={{ color: "grey.700" }} />}
          variant="text"
          onClick={toggleModal}
          disabled={isLoading}
          data-testid="close-modal-button"
        />
      )}
    </Box>
  </Box>
);
