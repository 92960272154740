import {
  UploadBeforeHandler,
  UploadBeforeReturn,
} from "suneditor-react/dist/types/upload";
import { FormatTagName } from "suneditor/src/options";

// todo during upload images implementation move SunEditorEventProps and handleImageUploadBefore to the RichEditor.tsx
interface SunEditorEventProps {
  onImageUploadBefore?: (
    files: File[],
    info: object,
    uploadHandler: UploadBeforeHandler
  ) => UploadBeforeReturn;
}

export const handleImageUploadBefore: SunEditorEventProps["onImageUploadBefore"] =
  (files, info, uploadHandler) => {
    // Define the response object according to the expected structure
    // Ensure that the size is a number
    const response = {
      result: [
        {
          url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEBqYEUHs9SPync2bo8AmdYjzW5WYicOWF8lreCXnMcQ&s",
          name: "CustomImage.jpg",
          size: 1234, // Adjust this size to the actual size if available
        },
      ],
      // Include any other necessary properties expected by the UploadBeforeResponse
    };

    // Call the uploadHandler with the correct response object
    uploadHandler(response);

    // Return true to prevent default behavior
    return false;
  };

export const buttonList = [
  ["formatBlock"],
  ["bold", "italic", "underline", "hiliteColor", "link"],
  ["list"],
  ["table"],
  ["removeFormat"],
  ["undo", "redo"],
];

export const ToolbarIcons: Record<string, string> = {
  bold: `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatBoldIcon"><path d="M15.6 10.79c.97-.67 1.65-1.77 1.65-2.79 0-2.26-1.75-4-4-4H7v14h7.04c2.09 0 3.71-1.7 3.71-3.79 0-1.52-.86-2.82-2.15-3.42M10 6.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-3zm3.5 9H10v-3h3.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5"></path></svg>`,
  italic: `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatItalicIcon"><path d="M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4z"></path></svg>`,
  underline: `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatUnderlinedIcon"><path d="M12 17c3.31 0 6-2.69 6-6V3h-2.5v8c0 1.93-1.57 3.5-3.5 3.5S8.5 12.93 8.5 11V3H6v8c0 3.31 2.69 6 6 6m-7 2v2h14v-2z"></path></svg>`,
  highlight_color: `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="BorderColorIcon"><path d="M22 24H2v-4h20zM13.06 5.19l3.75 3.75L7.75 18H4v-3.75zm4.82 2.68-3.75-3.75 1.83-1.83c.39-.39 1.02-.39 1.41 0l2.34 2.34c.39.39.39 1.02 0 1.41z"></path></svg>`,
  link: `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="InsertLinkIcon"><path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1M8 13h8v-2H8zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5"></path></svg>`,
  list_bullets: `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatListBulletedOutlinedIcon"><path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5m0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5m0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5M7 19h14v-2H7zm0-6h14v-2H7zm0-8v2h14V5z"></path></svg>`,
  list_number: `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatListNumberedOutlinedIcon"><path d="M2 17h2v.5H3v1h1v.5H2v1h3v-4H2zm1-9h1V4H2v1h1zm-1 3h1.8L2 13.1v.9h3v-1H3.2L5 10.9V10H2zm5-6v2h14V5zm0 14h14v-2H7zm0-6h14v-2H7z"></path></svg>`,
  table: `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="TableChartOutlinedIcon"><path d="M20 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m0 2v3H5V5zm-5 14h-5v-9h5zM5 10h3v9H5zm12 9v-9h3v9z"></path></svg>`,
  image: `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ImageOutlinedIcon"><path d="M19 5v14H5V5zm0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m-4.86 8.86-3 3.87L9 13.14 6 17h12z"></path></svg>`,
  erase: `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatClearOutlinedIcon"><path d="M20 8V5H6.39l3 3h1.83l-.55 1.28 2.09 2.1L14.21 8zM3.41 4.86 2 6.27l6.97 6.97L6.5 19h3l1.57-3.66L16.73 21l1.41-1.41z"></path></svg>`,
  undo: `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="UndoOutlinedIcon"><path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8"></path></svg>`,
  redo: `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="RedoOutlinedIcon"><path d="M18.4 10.6C16.55 8.99 14.15 8 11.5 8c-4.65 0-8.58 3.03-9.96 7.22L3.9 16c1.05-3.19 4.05-5.5 7.6-5.5 1.95 0 3.73.72 5.12 1.88L13 16h9V7z"></path></svg>`,
};

export const richEditorOptions = {
  formats: ["h1", "h2", "h3", "p"] as FormatTagName[],
  linkProtocol: "https://",
  icons: ToolbarIcons,
  buttonList: buttonList,
  linkTargetNewWindow: true,
};

export const handleOnKeyDown = (e: KeyboardEvent) => {
  const { key, shiftKey } = e;

  // disable SHIFT+TAB as it causes an unidentified error
  if (key === "Tab" && shiftKey) {
    e.preventDefault();
    return false;
  }
};

function isOnlyHtmlElements(stringToCheck: string) {
  const strippedString = stringToCheck
    .replace(/<[^>]*>/g, "")
    .replace(/\s+/g, "")
    .replace(/\u200B/g, "");

  return strippedString === "";
}

export const isContentEmpty = (content: string) => {
  const trimmedContent = content.replace(/&nbsp;/g, "").replace(/\s+/g, "");

  return isOnlyHtmlElements(trimmedContent);
};

export const sanitizeEmptyContent = (content: string) => {
  return isContentEmpty(content) ? "" : content;
};
