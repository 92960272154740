interface HiddenIfNoPermissionProps {
  children: JSX.Element;
  grantedPermissions: string[];
  requiredPermission?: string;
  fallback?: JSX.Element;
}

export const HiddenIfNoPermission = ({
  children,
  requiredPermission,
  grantedPermissions = [],
  fallback,
}: HiddenIfNoPermissionProps) => {
  if (!requiredPermission) return children;

  const hasPermission = grantedPermissions.includes(requiredPermission);

  if (hasPermission) return children;

  return fallback ? fallback : null;
};
