import { styled, SxProps } from "@mui/material";
import { Box } from "../../atoms";
import { Radio, RadioProps } from "../../atoms/Radio/Radio";
import { theme } from "../../theme/theme";

const StyledContainer = styled(Box)<{ checked?: boolean }>(({ checked }) => {
  const backgroundColor = checked
    ? { backgroundColor: theme.palette.primary[50] }
    : { backgroundColor: theme.palette.common.white };
  const borderColor = checked
    ? theme.palette.primary[200]
    : theme.palette.grey[200];
  return {
    padding: "8px",
    border: `1px solid ${borderColor}`,
    borderRadius: "4px",
    cursor: "pointer",
    ...backgroundColor,
    "& .MuiFormControlLabel-root": {
      padding: "4px",
      marginLeft: "4px",
    },
  };
});

const StyledRadio = styled(Radio)`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  width: 100%;
`;

export const SingleRadioItem: React.FC<
  RadioProps & { checked: boolean; containerSx?: SxProps }
> = (props) => {
  return (
    <StyledContainer checked={props.checked} sx={props.containerSx}>
      <StyledRadio {...props} />
    </StyledContainer>
  );
};
