import { getBorderShadow } from "../../utils/getBorderShadow";
import MuiButton from "@mui/material/Button";
import { Color, ButtonProps as MuiButtonProps } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import React, { ForwardedRef, forwardRef } from "react";
import { AppPalette } from "../../theme/theme";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    ghost: true;
  }
}

type PADDING_VARIANTS =
  | "default"
  | "endIcon"
  | "startIcon"
  | "bothIcons"
  | "icon";

interface PaddingVariant {
  paddingVariant: PADDING_VARIANTS;
}
type Size = Pick<MuiButtonProps, "size">;
type SizeValues = Size[keyof Size];

interface PaddingValueArgs extends PaddingVariant {
  size: Exclude<SizeValues, "large">;
}

export interface ButtonProps
  extends Pick<
    MuiButtonProps,
    | "color"
    | "disabled"
    | "fullWidth"
    | "variant"
    | "children"
    | "startIcon"
    | "endIcon"
    | "onClick"
    | "sx"
    | "id"
    | "type"
    | "ref"
    | "component"
    | "href"
  > {
  icon?: React.ReactNode;
  size?: Exclude<SizeValues, "large">;
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>;
  download?: boolean;
  themeColor?: string;
}

const PADDINGS = {
  medium: {
    default: `8px 16px`,
    endIcon: "8px 12px 8px 16px",
    startIcon: "8px 16px 8px 12px",
    bothIcons: "8px 12px",
    icon: "8px",
  },

  small: {
    default: "4px 12px",
    endIcon: "4px 8px 4px 12px",
    startIcon: "4px 12px 4px 8px",
    bothIcons: "4px 8px",
    icon: "4px",
  },
};

const getPaddingVariant = (
  iconsArgs: Partial<Record<PADDING_VARIANTS, unknown>>
) => {
  switch (true) {
    case !!iconsArgs.icon:
      return "icon";
    case !!iconsArgs.endIcon && !!iconsArgs.startIcon:
      return "bothIcons";
    case !!iconsArgs.endIcon:
      return "endIcon";
    case !!iconsArgs.startIcon:
      return "startIcon";

    default:
      return "default";
  }
};

const getPaddingValue = ({
  size = "medium",
  paddingVariant,
}: PaddingValueArgs) => PADDINGS[size][paddingVariant];

const StyledButton = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== "themeColor",
})<ButtonProps>(({ theme, size, icon, endIcon, startIcon, themeColor }) => {
  const paddingVariant = getPaddingVariant({ icon, endIcon, startIcon });
  const paddingValue = getPaddingValue({ size, paddingVariant });
  const focusShadow = `0 0 8px ${alpha(theme.palette.primary[500], 0.6)}`;
  const colorKey = (themeColor || "primary") as keyof AppPalette;
  const themeColorObject = theme.palette[colorKey] as Color;

  const variantStyles = {
    "&.MuiButton-contained": {
      color: "#FFF",
      backgroundColor: themeColorObject[500],

      "&:hover": {
        backgroundColor: themeColorObject[600],
      },

      "&.Mui-disabled": {
        backgroundColor: theme.palette.grey[100],
      },
    },

    "&.MuiButton-outlined": {
      "&:hover": {
        backgroundColor: "unset",
        boxShadow: getBorderShadow(theme.palette.primary[600]),
        color: theme.palette.primary[600],
      },
      "&:hover:active": {
        boxShadow: `${getBorderShadow(
          theme.palette.primary[600]
        )}, ${focusShadow}`,
      },
      "&.Mui-disabled": {
        boxShadow: getBorderShadow(theme.palette.grey[200]),
      },
    },

    "&.MuiButton-text": {
      color: theme.palette.primary[500],
      boxShadow: "none",

      "&:hover": {
        backgroundColor: theme.palette.primary[50],
      },

      "&.Mui-focusVisible, &:active": {
        boxShadow: focusShadow,
      },
    },

    "&.MuiButton-ghost": {
      color: theme.palette.grey[700],
      boxShadow: "none",

      "&:hover": {
        backgroundColor: theme.palette.primary[50],
      },

      "&.Mui-focusVisible, &:active": {
        boxShadow: focusShadow,
      },
    },
  };

  const sizeStyles = {
    "&.MuiButton-sizeMedium, &.MuiButton-textSizeMedium": {
      fontSize: "0.875rem",
      lineHeight: "1.25rem",

      ".MuiSvgIcon-root": {
        fontSize: "1.25rem",
      },
    },

    "&.MuiButton-sizeSmall": {
      fontSize: "0.75rem",
      lineHeight: "1rem",

      ".MuiSvgIcon-root": {
        fontSize: "1rem",
      },
    },
  };

  const iconStyles = {
    "& .MuiButton-endIcon": {
      marginLeft: "4px",
    },

    "& .MuiButton-startIcon": {
      marginRight: "4px",
    },
  };

  return {
    fontWeight: 500,
    minWidth: "unset",
    textTransform: "revert",
    boxShadow: getBorderShadow(themeColorObject[500]),
    border: "0 !important",
    transitionProperty: "box-shadow, background-color, color",
    transitionDuration: "200ms",
    transitionTimingFunction: "ease-out",
    padding: paddingValue,

    "&.Mui-focusVisible, &:active": {
      boxShadow: `${getBorderShadow(themeColorObject[500])}, ${focusShadow}`,
    },

    ...variantStyles,
    ...sizeStyles,
    ...iconStyles,

    "&.Mui-disabled": {
      color: theme.palette.grey[600],
    },
  };
});

const CursorWrapper = styled("span")(
  `
    cursor: not-allowed;
  `
);

export const Button = forwardRef(
  (
    {
      children,
      variant = "contained",
      size = "medium",
      icon,
      onMouseDown,
      themeColor,
      ...rest
    }: ButtonProps,
    ref
  ) => {
    const Wrapper = rest.disabled ? CursorWrapper : React.Fragment;

    return (
      <Wrapper>
        <StyledButton
          {...rest}
          icon={icon}
          variant={variant}
          size={size}
          disableElevation={true}
          disableRipple={true}
          onMouseDown={onMouseDown}
          themeColor={themeColor}
          ref={ref as ForwardedRef<HTMLButtonElement>}
        >
          {icon || children}
        </StyledButton>
      </Wrapper>
    );
  }
);
