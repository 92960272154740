import {
  INIT_FILTERS,
  ParsedQueries,
  calculateOffset,
  isEqual,
  isSortByValue,
} from "@applied-ai/utils";
import debounce from "lodash.debounce";
import { useRouter } from "next/router";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";

enum QUERY_ACTIONS {
  SET_QUERY = "SET_QUERY",
  REMOVE_QUERY = "REMOVE_QUERY",
  CLEAR_FILTERS = "CLEAR_FILTERS",
  SET_INITIAL_QUERY = "SET_INITIAL_QUERY",
}

interface SetFilterAction {
  type: QUERY_ACTIONS.SET_QUERY;
  payload: {
    key: string;
    value: string | boolean | number;
    notFilterParams?: string[];
  };
}
interface RemoveFilterAction {
  type: QUERY_ACTIONS.REMOVE_QUERY;
  payload: {
    key: string;
    notFilterParams?: string[];
  };
}

interface ClearFiltersAction {
  type: QUERY_ACTIONS.CLEAR_FILTERS;
  payload: {
    notFilterParams: string[];
  };
}

interface QueriesState extends ParsedQueries {
  lastUpdate: number;
  filtersCount: number;
  previousFiltersCount?: number;
}

interface SetInitialQueryAction {
  type: QUERY_ACTIONS.SET_INITIAL_QUERY;
}

const reducer = (
  state: QueriesState,
  action:
    | SetFilterAction
    | RemoveFilterAction
    | ClearFiltersAction
    | SetInitialQueryAction
) => {
  switch (action.type) {
    case QUERY_ACTIONS.SET_QUERY: {
      const newState = {
        ...state,
        [action.payload.key]: action.payload.value,
      };

      const filtersCount = countAppliedFilters(
        removeObsoleteParams(newState, action.payload.notFilterParams)
      );

      const isNotPageParam = action.payload.key !== "page";
      if (isNotPageParam) {
        newState["page"] = 1;
      }
      return {
        ...newState,
        filtersCount,
        lastUpdate: new Date().getTime(),
      };
    }
    case QUERY_ACTIONS.REMOVE_QUERY: {
      const newState = { ...state };
      const previousFiltersCount = newState.filtersCount;
      delete newState[action.payload.key];
      const filtersCount = countAppliedFilters(
        removeObsoleteParams(newState, action.payload.notFilterParams)
      );

      return {
        ...newState,
        lastUpdate: new Date().getTime(),
        page: 1,
        filtersCount,
        previousFiltersCount,
      };
    }
    case QUERY_ACTIONS.CLEAR_FILTERS: {
      const previousFiltersCount = state.filtersCount;
      const newState = Object.entries(state).reduce((acc, [key, value]) => {
        if (
          [
            ...NOT_FILTER_QUERY_PARAMS,
            ...action.payload.notFilterParams,
          ].includes(key)
        )
          acc[key] = value;
        return acc;
      }, {} as ParsedQueries);

      return {
        ...newState,
        filtersCount: 0,
        page: 1,
        lastUpdate: new Date().getTime(),
        previousFiltersCount,
      };
    }

    case QUERY_ACTIONS.SET_INITIAL_QUERY: {
      return {
        filtersCount: 0,
        lastUpdate: new Date().getTime(),
        ...INIT_FILTERS,
      };
    }
    default: {
      return state;
    }
  }
};

const NOT_FILTER_QUERY_PARAMS = [
  "page",
  "sortBy",
  "lastUpdate",
  "searchPhrase",
  "previousFiltersCount",
];

const removeObsoleteParams = (
  query: ParsedQueries,
  notFilterParams: string[] = []
) => {
  const result = { ...query };
  [...NOT_FILTER_QUERY_PARAMS, ...notFilterParams].forEach(
    (key) => delete result[key]
  );

  return result;
};

const countAppliedFilters = (filters: ParsedQueries) => {
  const filtersCount = Object.values(filters).reduce(
    (acc: number, currentValue) => {
      if (Array.isArray(currentValue)) {
        acc += currentValue.length;
        return acc;
      }
      if (typeof currentValue === "string") {
        acc += currentValue.split(",").length;
        return acc;
      }
      if (typeof currentValue === "boolean") {
        acc += 1;
      }
      return acc;
    },
    0
  );

  return filtersCount;
};

export interface QueryManagerOptions {
  minSearchLength?: number;
  debounceTime?: number;
  withInitialFilters?: boolean;
  notFilterParams?: string[];
}

const QueryManagerContext = createContext<
  | ({
      changeFilter: (key: string, value?: string | boolean) => void;
      clearAllFilters: () => void;
      sortBy: (value: unknown) => void;
      sortedBy: string;
      page: number;
      offset: number;
      changePage: (value: number) => void;
      searchBy: (value: string) => void;
      searchedBy: string;
      previousSearch: string;
      filtersCount: number;
      previousFiltersCount?: number;
    } & QueriesState)
  | undefined
>(undefined);

function QueryManagerProvider<T>({
  options,
  children,
}: {
  options: QueryManagerOptions;
  children: React.ReactNode;
}) {
  const router = useRouter();
  const {
    minSearchLength = 2,
    debounceTime = 500,
    withInitialFilters = true,
    notFilterParams = [],
  } = options;

  const parseSearchQuery = (
    querySearch: string | string[] | undefined
  ): string => {
    return querySearch?.toString().trim() || "";
  };

  const getSearchFromQuery = (): string => {
    const { searchPhrase: querySearch } = router.query;
    return parseSearchQuery(querySearch);
  };

  const getFiltersCountFromQuery = () => {
    const filters = removeObsoleteParams(router.query, notFilterParams);
    const filtersCount = countAppliedFilters(filters);

    return (
      { ...INIT_FILTERS, ...router.query, filtersCount } || {
        filtersCount: 0,
      }
    );
  };

  const [previousSearch, setPreviousSearch] = useState<string>("");
  const [searchedBy, setSearchedBy] = useState<string>(getSearchFromQuery());

  const [state, dispatch] = useReducer(reducer, {
    ...getFiltersCountFromQuery(),
    lastUpdate: new Date().getTime(),
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { lastUpdate, filtersCount, previousFiltersCount, ...query } = state;

    if (!withInitialFilters) {
      Object.keys(INIT_FILTERS).forEach((key) => {
        if (query[key]) {
          delete query[key];
        }
      });
    }

    if (!isEqual(query, router.query)) {
      router.replace({ query });
    }
  }, [state.lastUpdate]);

  useEffect(() => {
    if (Object.keys(router.query).length === 0 && withInitialFilters) {
      dispatch({
        type: QUERY_ACTIONS.SET_INITIAL_QUERY,
      });
      setPreviousSearch("");
      setSearchedBy("");
    }
  }, [router.query]);

  const changeQuery = (key: string, value?: string | boolean) => {
    if ((Array.isArray(value) && !!value.length) || !!value) {
      return dispatch({
        type: QUERY_ACTIONS.SET_QUERY,
        payload: { key, value, notFilterParams },
      });
    }

    dispatch({
      type: QUERY_ACTIONS.REMOVE_QUERY,
      payload: { key, notFilterParams },
    });
  };

  const clearAllFilters = () => {
    return dispatch({
      type: QUERY_ACTIONS.CLEAR_FILTERS,
      payload: { notFilterParams },
    });
  };

  const sortBy = (value: unknown) => {
    dispatch({
      type: QUERY_ACTIONS.SET_QUERY,
      payload: {
        key: "sortBy",
        value: isSortByValue(value) ? value : "-created_at",
        notFilterParams,
      },
    });
  };

  const changePage = (value: number) =>
    dispatch({
      type: QUERY_ACTIONS.SET_QUERY,
      payload: {
        key: "page",
        value,
        notFilterParams,
      },
    });

  useEffect(() => searchDebounce(searchedBy, previousSearch), [searchedBy]);

  const searchBy = (value: string) => {
    setSearchedBy(value);
  };

  const searchDebounce = useCallback(
    debounce((value: string, previousValue: string) => {
      if (value.length < minSearchLength) {
        if (previousValue !== "") {
          changeQuery("searchPhrase");
          setPreviousSearch("");
          return;
        }
        return;
      }
      setPreviousSearch(value);
      return changeQuery("searchPhrase", value);
    }, debounceTime),
    [router.query]
  );

  const page = Number(state["page"] || 1);

  return (
    <QueryManagerContext.Provider
      value={{
        ...(state as QueriesState & T),
        changeFilter: changeQuery,
        clearAllFilters,
        sortBy,
        sortedBy: (state["sortBy"] as string) || "",
        page: page,
        offset: calculateOffset(page),
        changePage,
        searchBy,
        searchedBy,
        previousSearch,
      }}
    >
      {children}
    </QueryManagerContext.Provider>
  );
}

function useQueryManager() {
  const context = useContext(QueryManagerContext);
  if (context === undefined) {
    throw new Error(
      "QueryManagerContext must be used within a QueryManagerProvider"
    );
  }
  return context;
}

export { useQueryManager, QueryManagerProvider, INIT_FILTERS };
