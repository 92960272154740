import { QuestionTile } from "../QuestionTile/QuestionTile";
import { Box, useTheme, Slider } from "../../reexports";
import { SyntheticEvent, useState } from "react";
import { Typography } from "../../atoms";

interface SliderFieldTileProps {
  question: string;
  value: number;
  fieldId: string;
  minValue: number;
  maxValue: number;
  step: number;
  disabled: boolean;
  handleValueChange: (value: number) => Promise<void>;
  fieldErrorMessage?: string;
  required?: boolean;
  isHintAvailable?: boolean;
  label?: string;
  onIconClick?: () => void;
  onFocus?: () => void;
  withMarks?: boolean;
}

function generateSteps(min: number, max: number, step: number) {
  const steps = [];
  for (let i = min; i <= max; i += step) {
    steps.push({ value: i, label: i });
  }
  return steps;
}

export const SliderFieldTile = ({
  question,
  value,
  fieldId,
  maxValue,
  minValue,
  step,
  disabled,
  handleValueChange,
  fieldErrorMessage,
  required,
  isHintAvailable,
  label,
  onIconClick,
  withMarks,
  onFocus,
}: SliderFieldTileProps) => {
  const theme = useTheme();
  const [fieldValue, setFieldValue] = useState<number>(value);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setFieldValue(newValue as number);
  };

  const handleFieldChange = async (
    event: Event | SyntheticEvent<Element, Event>,
    newValue: number | number[]
  ) => {
    await handleValueChange(newValue as number);
  };

  return (
    <QuestionTile
      id={fieldId}
      question={question}
      htmlFor={fieldId}
      required={required}
      onIconClick={onIconClick}
      isHintAvailable={isHintAvailable}
      label={label}
      onFocus={onFocus}
    >
      <Box
        sx={{
          [`@media (min-width: ${theme.breakpoints[1440]})`]: {
            width: "50%",
          },
        }}
      >
        <Slider
          onChange={handleChange}
          onChangeCommitted={handleFieldChange}
          value={fieldValue}
          max={maxValue}
          min={minValue}
          step={step}
          disabled={disabled}
          valueLabelDisplay="auto"
          marks={withMarks && generateSteps(minValue, maxValue, step)}
          slotProps={{
            markLabel: {
              style: {
                color: theme.palette.grey[500],
                fontSize: "10px",
                fontWeight: 500,
                lineHeight: "12px",
                textTransform: "uppercase",
                marginTop: "8px",
              },
            },
          }}
        />
      </Box>
      {!!fieldErrorMessage && (
        <Typography variant="bodyRegular12" sx={{ color: "red.500" }}>
          {fieldErrorMessage}
        </Typography>
      )}
    </QuestionTile>
  );
};
