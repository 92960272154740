import React from "react";
import { Box, Typography } from "../../atoms";

export const SummaryLayout: React.FC<{
  children?: React.ReactNode;
  title: string;
}> = ({ children, title }) => {
  return (
    <Box
      sx={{
        padding: "24px",
      }}
    >
      <Box
        sx={{
          paddingBottom: "24px",
        }}
      >
        <Typography
          variant="bodyMedium16"
          sx={{
            color: "gray.600",
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
