import MuiTooltip from "@mui/material/Tooltip";
import { TooltipProps, styled } from "@mui/material";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  "& .MuiTooltip-tooltipArrow": {
    color: theme.palette.grey[700],
  },
  "& .MuiTooltip-tooltip": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[700],
    fontSize: "0.75rem",
    fontWeight: "500",
    lineHeight: "0.875rem",
    padding: "12px",
    borderRadius: "3px",
  },
}));

export const Tooltip: React.FC<TooltipProps> = (props) => {
  return <StyledTooltip {...props} />;
};
