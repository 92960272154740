import { SxProps } from "@mui/material";
import { Box } from "../../atoms";

export const ModalContent: React.FC<{
  children: React.ReactNode;
  sx?: SxProps;
}> = ({ children, sx }) => (
  <Box
    sx={{
      py: 2,
      px: 3,
      overflowWrap: "break-word",
      overflowY: "auto",
      ...sx,
    }}
  >
    {children}
  </Box>
);
