export const createDownloadFilename = (url?: string, name?: string) => {
  if (url) {
    const lastSlashIndex = url.lastIndexOf("/");
    if (lastSlashIndex === -1) {
      return null;
    }
    const filenameWithExtension = url.slice(lastSlashIndex + 1);
    const lastDotIndex = filenameWithExtension.lastIndexOf(".");
    if (lastDotIndex === -1) {
      return null;
    }
    const filename = name || filenameWithExtension.slice(0, lastDotIndex);
    const extension = filenameWithExtension.slice(lastDotIndex + 1);
    return `${filename}.${extension}`;
  }
};
