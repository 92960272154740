import { AppsConfigPayload, UctsConfigPayload } from "@applied-ai/types";

export function getIsAIAssistanceEnabled(config: AppsConfigPayload) {
  if (!config.ucts) return false;

  return (
    (config.ucts as UctsConfigPayload)?.feature_flags?.find(
      (feature) => feature.name === "ai-assistant"
    )?.enabled ?? false
  );
}
