import { ConfigPayload, AppsConfigPayload } from "@applied-ai/types";
import { CoreService } from "@applied-ai/api";
import useSWR from "swr";

const fallbackData = {
  config: {
    rct: {},
    ucts: {
      knowledge_base: {
        enabled: false,
        site_url: "string",
      },
    },
  },
};

export const useConfig = (): {
  config?: AppsConfigPayload;
} => {
  const fetcher = CoreService.aaiToolsuiteCoreApiCoreGetConfig;

  const { data: config } = useSWR<ConfigPayload>(["config"], fetcher, {
    fallbackData,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return config || fallbackData;
};
