import { Avatar, Box, Button, TextField, Typography } from "../../atoms";
import { Divider, MenuItem, Modal, useTheme } from "../../reexports";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "next-i18next";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Check from "@mui/icons-material/Check";
import { ROLES, UserWithRole } from "@applied-ai/types";
import { useState } from "react";
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalWrapper,
} from "../ModalComponents";
import { EllipsisWithTooltip } from "../EllipsisWithTooltip/EllipsisWithTooltip";

interface SharedUserProps {
  modalTitle: string;
  disabled: boolean;
  areActionsVisible: boolean;
  user: UserWithRole;
  defaultRole: ROLES;
  remove: () => void;
  menuItems: {
    value: ROLES;
    label: string;
  }[];
  toggleParentVisibility?: () => void;
  avatar?: string;
  onRoleChange?: (role: ROLES) => void;
  canRemove?: boolean;
  users?: UserWithRole[];
}

export const SharedUser: React.FC<SharedUserProps> = ({
  avatar,
  disabled,
  modalTitle,
  user,
  onRoleChange,
  toggleParentVisibility,
  remove,
  areActionsVisible,
  canRemove,
  defaultRole,
  users,
  menuItems,
}) => {
  const { t } = useTranslation("common");
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const theme = useTheme();

  const userRole = user.role;

  const toggleRemoveModal = () => {
    setIsRemoveModalOpen((prevValue) => !prevValue);
    toggleParentVisibility?.();
  };

  return (
    <Box
      sx={{ px: 2, py: 1, display: "flex", alignItems: "center" }}
      data-testid="share-modal-user-tile"
    >
      <Avatar src={avatar}>{user.details.display_name.charAt(0)}</Avatar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          pl: 1,
          maxWidth: "320px",
        }}
      >
        <Typography variant="bodyMedium14">
          <EllipsisWithTooltip disableTooltipEvents={true}>
            {user.details.display_name}
          </EllipsisWithTooltip>
        </Typography>
        <Typography
          variant="bodyMedium12"
          sx={{ color: "grey.600", fontWeight: 400 }}
        >
          {user.details.email}
        </Typography>
      </Box>
      <Box
        sx={{
          ml: "auto",
        }}
      >
        <TextField
          select
          disabled={
            areActionsVisible && users && users.length > 1 ? disabled : true
          }
          value={userRole}
          variant="outlined"
          data-testid="share-modal-selected-ownership-type-dropdown-button"
          sx={{
            borderRadius: "4px",
            m: "4px",
            "&:focus-within": {
              boxShadow: "0px 0px 4px 0px rgba(0, 116, 123, 0.60)",
              borderRadius: "4px",
            },

            "&:hover": {
              background: theme.palette.primary[50],
              border: "0 !important",
            },
            ".MuiInputBase-root": {
              borderColor: "transparent",
              height: "32px",
              border: "0 !important",
              background: theme.palette.common.white,
              cursor: "pointer !important",
              boxShadow: "none !important",

              "&:hover": {
                background: theme.palette.primary[50],
                borderRadius: "4px",
                border: "0 !important",
              },
              "&:active": {
                border: 0,
              },
            },
            "&.Mui-selected": {
              outline: "none",
            },
            "& .MuiOutlinedInput-input": {
              border: "0 !important",
              outline: "0 !important",
            },
          }}
          SelectProps={{
            IconComponent: ArrowDropDownIcon,
            renderValue: (value) => (
              <Typography variant="bodyMedium14">
                {t(value as string)}
              </Typography>
            ),
          }}
        >
          {menuItems.map(({ value, label }) => (
            <MenuItem
              value={value}
              onClick={() => onRoleChange?.(value)}
              key={value}
            >
              {label} {userRole === value && <Check sx={{ ml: 2 }} />}
            </MenuItem>
          ))}
          <Divider sx={{ my: "4px !important", borderColor: "grey.100" }} />
          <MenuItem
            value="value3"
            onClick={toggleRemoveModal}
            disabled={!canRemove}
          >
            {t("remove")}
          </MenuItem>
        </TextField>
      </Box>

      <Modal open={isRemoveModalOpen} onClose={toggleRemoveModal}>
        <ModalWrapper>
          <ModalHeader toggleModal={toggleRemoveModal}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "min-content 1fr",
                alignItems: "center",
              }}
            >
              <Button
                icon={<ArrowBack />}
                sx={{ color: `${theme.palette.grey[700]} !important` }}
                variant="text"
                onClick={toggleRemoveModal}
              />
              <Typography
                variant="h3"
                sx={{
                  overflow: "hidden",
                  textWrap: "nowrap",
                  textOverflow: "ellipsis",
                  pr: 3,
                  ml: 0.5,
                }}
              >
                {modalTitle}
              </Typography>
            </Box>
          </ModalHeader>
          <ModalContent>
            {t("removeCollaboratorConfirmation", {
              collaborator: user.details.display_name,
            })}
          </ModalContent>
          <ModalFooter>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                variant="text"
                sx={{ marginLeft: "auto" }}
                onClick={toggleRemoveModal}
              >
                {t("cancel")}
              </Button>
              <Button
                onClick={remove}
                data-testid="delete-user-modal-confirm-button"
              >
                {t("confirm")}
              </Button>
            </Box>
          </ModalFooter>
        </ModalWrapper>
      </Modal>
    </Box>
  );
};
