import { ReactNode } from "react";
import { Box, Typography } from "../../atoms";
import { theme } from "../../theme/theme";

interface ActionTileProps {
  title: string;
  description: string;
  actionComponent: ReactNode;
  dataTestid?: string;
}

export const ActionTile: React.FC<ActionTileProps> = ({
  title,
  description,
  actionComponent,
  dataTestid,
}) => {
  return (
    <Box
      data-testid={dataTestid}
      sx={{
        padding: "24px",
        backgroundColor: theme.palette.common.white,
        border: "1px solid grey.200",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Typography
            variant="bodyMedium16"
            sx={{
              color: "gray.900",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="bodyRegular14"
            sx={{
              color: "gray.700",
            }}
          >
            {description}
          </Typography>
        </Box>
        <Box>{actionComponent}</Box>
      </Box>
    </Box>
  );
};
