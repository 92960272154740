import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { Box, Typography } from "../../atoms";
import { IconButton } from "../../reexports/IconButton";
import { theme } from "../../theme/theme";
import { MarkdownComponent } from "../MarkdownComponent/MarkdownComponent";
import { MessageCard } from "../Card/MessageCard";
import emptyBox from "@applied-ai/root/public/images/emptyBox.svg";
import { useTranslation } from "next-i18next";

interface HintPanelProps {
  onCloseIconClick: () => void;
  hintHeader: string;
  hintLabel: string;
  hintTitle?: string;
  hintDescription?: string;
}

export const HintPanel: React.FC<HintPanelProps> = ({
  onCloseIconClick,
  hintHeader,
  hintLabel,
  hintDescription,
  hintTitle,
}) => {
  const { t } = useTranslation(["common"]);
  const isEmpty = !hintDescription || !hintTitle;

  return (
    <>
      <Box
        sx={{
          padding: "24px",
          borderBottom: `1px solid ${theme.palette.grey[100]}`,
          display: "flex",
          justifyContent: "space-between",
        }}
        data-testid="hint-panel"
      >
        <Typography
          variant="bodyMedium16"
          sx={{
            color: theme.palette.grey[600],
          }}
        >
          {hintHeader}
        </Typography>
        <IconButton
          sx={{
            padding: 0,
            color: theme.palette.grey[700],
            borderRadius: "4px",
            "&:hover": {
              background: theme.palette.primary[50],
            },
            "&:active": {
              boxShadow: "0px 0px 4px 0px rgba(0, 116, 123, 0.6)",
            },
          }}
          onClick={onCloseIconClick}
          data-testid="hide-hint-panel-icon-button"
        >
          <CloseOutlined />
        </IconButton>
      </Box>
      {isEmpty && (
        <Box
          sx={{
            paddingLeft: "16px",
            paddingRight: "24px",
            paddingTop: "24px",
            overflowY: "auto",
            height: "calc(100% - 73px)",
          }}
        >
          {hintLabel && (
            <Box>
              <Typography
                variant="bodyMedium10"
                sx={{
                  color: theme.palette.grey[600],
                  textTransform: "uppercase",
                }}
              >
                {hintLabel}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              pt: "48px",
              margin: "auto",
            }}
          >
            <MessageCard
              image={{ src: emptyBox, alt: "no data selected", height: 114 }}
              title={t("common:hintNotAvailable")}
              size="small"
            />
          </Box>
        </Box>
      )}
      {!isEmpty && (
        <Box
          sx={{
            paddingLeft: "16px",
            paddingRight: "24px",
            paddingTop: "24px",
            overflowY: "auto",
            height: "calc(100% - 73px)",
          }}
        >
          <Box>
            <Typography
              variant="bodyMedium10"
              sx={{
                color: theme.palette.grey[600],
                textTransform: "uppercase",
              }}
            >
              {hintLabel}
            </Typography>
          </Box>
          <Box
            sx={{
              paddingTop: "8px",
            }}
          >
            <Typography
              variant="bodyMedium16"
              sx={{
                color: theme.palette.grey[900],
              }}
            >
              {hintTitle}
            </Typography>
          </Box>
          <MarkdownComponent>{hintDescription}</MarkdownComponent>
        </Box>
      )}
    </>
  );
};
