import { DatePicker, useTheme } from "../../reexports";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QuestionTile } from "../QuestionTile/QuestionTile";
import { useEffect, useState } from "react";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { useTranslation } from "next-i18next";
import { Typography } from "../../atoms";

interface DateFieldTileProps {
  question: string;
  fieldId: string;
  value: string;
  disabled: boolean;
  handleValueChange: (value: string) => Promise<void>;
  fieldErrorMessage?: string;
  required?: boolean;
  isHintAvailable?: boolean;
  label?: string;
  onFocus?: () => void;
  onIconClick?: () => void;
  dataTestId?: string;
}

export const DataFieldTile = ({
  question,
  value,
  fieldId,
  disabled,
  handleValueChange,
  fieldErrorMessage,
  required,
  isHintAvailable,
  label,
  onIconClick,
  dataTestId = "date-picker",
  onFocus,
}: DateFieldTileProps) => {
  const { t } = useTranslation(["common"]);
  const theme = useTheme();
  const dateFormat = "DD/MM/YYYY";
  const defaultFieldValue = value ? dayjs(value, dateFormat) : null;
  const [fieldValue, setFieldValue] = useState<dayjs.Dayjs | null>(
    defaultFieldValue
  );

  const renderErrorMessage = (fieldErrorMessage?: string) => {
    if (fieldErrorMessage && fieldErrorMessage.includes("Invalid Date")) {
      return t("invalidDate");
    }

    return fieldErrorMessage;
  };

  const errorMessage = renderErrorMessage(fieldErrorMessage);

  useEffect(() => {
    setFieldValue(dayjs(value, dateFormat));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onValueChange = (value: Dayjs | null) => {
    setFieldValue(value);
  };

  const handleBlur = async (value: dayjs.Dayjs | null) => {
    const formattedDate = dayjs(value).format(dateFormat);

    await handleValueChange(value === null ? "" : formattedDate);
  };

  return (
    <QuestionTile
      id={fieldId}
      question={question}
      htmlFor={fieldId}
      required={required}
      onIconClick={onIconClick}
      isHintAvailable={isHintAvailable}
      label={label}
      sx={{
        ".MuiFormControl-root": {
          width: "50%",

          ".Mui-disabled": {
            backgroundColor: "grey.100",
          },
        },
      }}
      onFocus={onFocus}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disabled={disabled}
          value={fieldValue}
          onChange={onValueChange}
          defaultValue={fieldValue}
          onAccept={(value) => {
            onValueChange(value);
            handleBlur(value);
          }}
          format={dateFormat}
          data-testid={dataTestId}
          slots={{
            openPickerIcon: CalendarMonthOutlinedIcon,
          }}
          sx={{}}
          slotProps={{
            layout: {
              sx: {
                border: "1px solid",
                borderColor: "grey.100",
                borderRadius: "4px",
                "& .MuiDayCalendar-weekDayLabel": {
                  padding: "0 12px",
                  color: theme.palette.grey[600],
                },

                "& .MuiYearCalendar-root": {
                  padding: "0 12px 16px !important",
                },
              },
            },
            calendarHeader: {
              sx: {
                color: "grey.700",
                margin: "8px 0 10px",
                padding: "0 12px 4px 24px !important",
                borderBottom: "1px solid",
                borderColor: "grey.200",

                ".MuiPickersCalendarHeader-labelContainer": {
                  borderRadius: "4px !important",
                  paddingLeft: "8px",

                  "&:hover": {
                    background: `${theme.palette.primary[100]} !important`,
                  },
                },

                ".MuiPickersCalendarHeader-label": {
                  fontSize: "0.875rem !important",
                  marginRight: "0 !important",
                  height: "20px !important",
                  borderRadius: "4px !important",
                },

                "& svg": {
                  color: "grey.700",
                  borderRadius: "4px !important",

                  "&:hover": {
                    background: `${theme.palette.primary[100]} !important`,
                  },
                },

                "& button": {
                  borderRadius: "4px !important",
                  padding: "8px !important",

                  "&:hover": {
                    background: `unset`,
                  },
                },

                ".MuiPickersCalendarHeader-switchViewIcon": {
                  width: "18px",
                  height: "18px",
                  borderRadius: "4px !important",
                },

                ".MuiPickersArrowSwitcher-button": {
                  "& svg": {
                    width: "20px !important",
                    height: "20px !important",
                  },
                },
              },
            },
            yearButton: {
              sx: {
                borderRadius: "4px !important",
                fontSize: "0.875rem !important",
                lineHeight: "1.25rem",
                color: "grey.700",
                margin: "0 3px 4px 0 !important",
                width: "67.5px",

                "&.MuiPickersYear-yearButton": {
                  "&.Mui-selected": {
                    background: theme.palette.primary[500],

                    "&:hover": {
                      background: `${theme.palette.primary[600]} !important`,
                    },
                  },
                },

                "&.MuiPickersYear-yearButton:hover": {
                  background: theme.palette.primary[100],
                },
              },
            },
            day: {
              sx: {
                borderRadius: "4px !important",
                fontSize: "0.875rem !important",
                lineHeight: "1.313rem",
                color: "grey.700",
                padding: "12px",
                width: "36px",

                "&:hover": {
                  background: `${theme.palette.primary[100]} !important`,
                },

                "&.MuiPickersDay-today": {
                  borderColor: "primary.500",
                },

                "&.MuiPickersDay-dayWithMargin": {
                  "&.Mui-selected": {
                    background: theme.palette.primary[500],

                    "&:hover": {
                      background: `${theme.palette.primary[600]} !important`,
                    },
                  },
                },
              },
            },

            textField: {
              error: !!fieldErrorMessage,
              helperText: (
                <Typography variant="bodyRegular12" sx={{ color: "red.500" }}>
                  {errorMessage}
                </Typography>
              ),
              onBlur: () => handleBlur(fieldValue),
              sx: {
                ".MuiFormHelperText-root": {
                  margin: "4px 0 0",
                },
                ".MuiInputAdornment-root": {
                  padding: "8px 8px 8px 0",
                  "& button": {
                    width: "24px",
                    height: "24px",
                    borderRadius: "2px !important",
                    padding: "14px",
                    color: errorMessage
                      ? `${theme.palette.red[500]} !important`
                      : `inherit`,
                    "&:hover": {
                      background: theme.palette.primary[100],
                    },
                    svg: {
                      width: "20px",
                      height: "20px",
                    },
                  },
                },
                "& .MuiOutlinedInput-root": {
                  padding: "8px 12px",
                  "& input": {
                    padding: 0,
                    height: "20px",
                    color: "grey.500",
                  },
                  "& fieldset": {
                    border: "1px solid",
                    borderColor: "grey.300",
                  },
                  "&:hover fieldset": {
                    border: "1px solid",
                    borderColor: "primary.500",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid",
                    borderColor: "primary.500",
                    boxShadow: "0px 0px 4px 0px rgba(0, 116, 123, 0.60)",
                  },
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </QuestionTile>
  );
};
