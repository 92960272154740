import MuiAvatar from "@mui/material/Avatar";
import { AvatarProps } from "@mui/material";
import { Typography } from "../Typography/Typography";

export const Avatar: React.FC<AvatarProps> = (props) => (
  <MuiAvatar
    {...props}
    sx={{
      width: 40,
      height: 40,
      backgroundColor: "primary.100",
      color: "primary.500",
      ...(props.sx || {}),
    }}
  >
    <Typography variant="bodyMedium12" sx={{ display: "flex" }}>
      {props.children}
    </Typography>
  </MuiAvatar>
);
