import { useTranslation } from "next-i18next";
import { useState, useRef, useEffect } from "react";
import { Box, Button } from "../../atoms";
import { QuestionTableColumnProps, QuestionTableRowProps } from "./types";
import { EditionMode } from "./types";
import { Table as MuiTable } from "../../reexports";
import { TableBody, TableHead } from "./TableComponents";
import { TableRowCreationModal } from "./TableRowCreationModal";

export interface TableFieldProps {
  name: string;
  columns: QuestionTableColumnProps[];
  canEdit: boolean;
  resetError: () => void;
  handleChangeValue: (
    rows: QuestionTableRowProps[],
    successCallback: () => void
  ) => Promise<void>;
  rows?: QuestionTableRowProps[];
  error?: string;
}

export function TableField({
  name,
  columns,
  rows,
  handleChangeValue,
  error,
  resetError,
  canEdit,
}: TableFieldProps) {
  const { t } = useTranslation(["common"]);
  const [isOverflowed, setIsOverflow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState<EditionMode>("create");
  const [editData, setEditData] = useState<QuestionTableRowProps | null>(null);
  const tableWrapperRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const { scrollWidth, clientWidth } = tableWrapperRef?.current || {};

    if (scrollWidth !== undefined && clientWidth !== undefined) {
      setIsOverflow(scrollWidth > clientWidth);
    }
  }, [columns, rows]);

  const handleSubmitNewValues = async (
    modifiedRow: QuestionTableRowProps,
    mode: EditionMode
  ) => {
    let rowsToSend;
    const isExistingRow = rows?.find(
      (existingRow) => existingRow.row_id === modifiedRow.row_id
    );

    if (isExistingRow && mode === "delete") {
      rowsToSend = rows?.filter(
        (existingRow) => existingRow.row_id !== modifiedRow.row_id
      );
    }

    if (isExistingRow && mode === "edit") {
      rowsToSend = rows?.map((existingRow) => {
        if (existingRow.row_id === modifiedRow.row_id) {
          return modifiedRow;
        }

        return existingRow;
      });
    }

    if (mode === "create") {
      rowsToSend = [...(rows || []), modifiedRow];
    }

    await handleChangeValue(rowsToSend || [], closeModal);
  };

  const setRowToEdit = (row: QuestionTableRowProps) => {
    setMode("edit");
    setEditData(row);
    setIsModalOpen(true);
  };

  const setRowToDelete = (row: QuestionTableRowProps) => {
    setMode("delete");
    setEditData(row);
    setIsModalOpen(true);
  };

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => {
    setIsModalOpen(false);
    setEditData(null);
    setMode("create");
  };

  const isNotEmptyData = !!rows?.length;

  return (
    <>
      <Box
        ref={tableWrapperRef}
        data-testid={name}
        sx={{
          borderRadius: "4px",
          border: "1px solid",
          borderColor: "grey.200",
          overflow: "auto",
          position: "relative",
          mb: "16px",
          "&::-webkit-scrollbar": {
            width: "10px",
            height: "7px",
            backgroundColor: "grey.100",
          },
        }}
      >
        <MuiTable
          data-testid="table-field"
          sx={{
            scrollX: "auto",
            borderCollapse: "separate",
            color: "grey.900",
            "& tr td:last-child": {
              borderLeft: `1px ${
                isOverflowed && isNotEmptyData ? "solid" : "none"
              }`,
              borderColor: "grey.200",
            },
            "& tr th:last-child": {
              borderLeft: `1px ${
                isOverflowed && isNotEmptyData ? "solid" : "none"
              }`,
              borderColor: "grey.200",
            },
            "& tr:last-child td": {
              borderBottom: 0,
            },
          }}
        >
          <TableHead columns={columns} isNotEmptyData={isNotEmptyData} />
          <TableBody
            columns={columns}
            rows={rows}
            onEditClick={setRowToEdit}
            onDeleteClick={setRowToDelete}
            canEdit={canEdit}
          />
        </MuiTable>
      </Box>
      <Button
        variant="outlined"
        onClick={openModal}
        disabled={!canEdit}
        data-testid="table-field-add-new-row"
      >
        {t("common:addNew")}
      </Button>
      <TableRowCreationModal
        isModalOpen={canEdit && isModalOpen}
        closeModal={closeModal}
        columns={columns}
        onSubmitHandler={handleSubmitNewValues}
        editData={editData}
        error={error}
        resetError={resetError}
        mode={mode}
      />
    </>
  );
}
