import MuiPagination from "@mui/material/Pagination";
import MuiPaginationItem from "@mui/material/PaginationItem";
import {
  PaginationProps as MuiPaginationProps,
  PaginationItemProps as MuiPaginationItemProps,
  alpha,
  styled,
} from "@mui/material";
import { theme } from "../../theme/theme";

const StyledPaginationItem = styled(MuiPaginationItem)`
  line-height: 1.25rem;
  color: ${(p) => p.theme.palette.grey[700]};

  &:hover {
    background-color: ${(p) => p.theme.palette.primary[50]};
  }
  &.Mui-selected {
    border: 1px solid ${(p) => p.theme.palette.primary[500]};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    color: ${(p) => p.theme.palette.primary[500]};
    background: inherit;
  }
  &.Mui-disabled {
    color: ${(p) => p.theme.palette.grey[500]};
    cursor: not-allowed;
    opacity: 1;
  }
  &:active {
    background-color: ${(p) => p.theme.palette.common.white};
    box-shadow: 0px 0px 4px 0px ${alpha(theme.palette.primary[500], 0.6)};
  }
`;

export const PaginationItem: React.FC<MuiPaginationItemProps> = (props) => {
  return <StyledPaginationItem {...props} shape="rounded" />;
};

export const Pagination: React.FC<MuiPaginationProps> = (props) => {
  if (!props.count || props.count < 2) {
    return null;
  }

  return (
    <MuiPagination
      renderItem={(item) => <PaginationItem {...item} />}
      boundaryCount={1}
      {...props}
    />
  );
};
