import { styled, useTheme } from "@mui/material";
import { Box } from "../../reexports";
import { Tag, Typography } from "../../atoms";
import { capitalizeFirstLetter } from "@applied-ai/utils";

interface StyledComponentsProps {
  isActive?: boolean;
  disabled?: boolean;
}

const ItemContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<StyledComponentsProps>(({ isActive, theme }) => {
  const backgroundColor = isActive
    ? theme.palette.primary[50]
    : theme.palette.common.white;

  return {
    display: "flex",
    gap: "8px",
    padding: "8px",
    backgroundColor,
    lineHeight: "1.25rem",
    borderRadius: "4px",

    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.grey[100],
    },
  };
});

const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SubtitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const Subtitle = styled(Typography)(() => ({
  color: "grey.600",
  fontSize: "0.625rem",
  lineHeight: "0.75rem",
  fontWeight: 500,
  textTransform: "uppercase",
}));

const StyledTag = styled(Tag)`
  text-transform: none;
  min-width: 24px;
`;

interface ProgressBarProps {
  title: string;
  subtitle: string;
  Icon: JSX.Element;
  resultLabels?: string[];
  isActive?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

const isNoneLabel = (label: string) => label === "-";

export const ProgressBarItem = ({
  title,
  Icon,
  subtitle,
  resultLabels = ["-"],
  isActive,
  onClick,
}: ProgressBarProps) => {
  const theme = useTheme();

  return (
    <ItemContainer
      isActive={isActive}
      onClick={onClick}
      data-testid="RiskClassificationSection"
    >
      {Icon}
      <TextContainer>
        <Typography variant="bodyRegular14" sx={{ wordBreak: "break-word" }}>
          {title}
        </Typography>
        <SubtitleContainer>
          <Subtitle>{subtitle}</Subtitle>
          {resultLabels.map((label, index) => (
            <StyledTag
              key={index}
              borderColor={
                isNoneLabel(label)
                  ? theme.palette.grey[200]
                  : theme.palette.grey[300]
              }
              backgroundColor={
                isNoneLabel(label)
                  ? theme.palette.grey[50]
                  : theme.palette.grey[100]
              }
              data-testid="SectionVerdictStatusBadge"
              label={capitalizeFirstLetter(label)}
            />
          ))}
        </SubtitleContainer>
      </TextContainer>
    </ItemContainer>
  );
};
