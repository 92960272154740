import { ROLES, UserDto, UserWithRole } from "@applied-ai/types";
import { ApproversInput } from "./InviteUsersInput/ApproversInput";
import { SetStateAction, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { getDisplayName } from "@applied-ai/utils";
import { SharedUser } from "./SharedUser";
import { useTranslation } from "next-i18next";
import { isUserApprover } from "./userRoleUtils";

interface ApproversTabProps<User> {
  isVisible: boolean;
  initialUsers: User[];
  getUsers: (q: string) => Promise<UserDto[]>;
  addUsers: (users: string[], role: ROLES, successCallback: () => void) => void;
  toggleParentVisibility: (isVisible: SetStateAction<boolean>) => void;
  removeUser: (
    userId: string,
    users: UserWithRole[],
    updateUsers: React.Dispatch<React.SetStateAction<User[]>>
  ) => () => void;
  canAssignApprover: boolean;
}

export const ApproversTab = <User extends UserWithRole>({
  isVisible,
  initialUsers,
  getUsers,
  addUsers,
  toggleParentVisibility,
  removeUser,
  canAssignApprover,
}: ApproversTabProps<User>) => {
  const [users, setUsers] = useState<User[]>(initialUsers);
  const { t } = useTranslation("common");
  const menuItems = [{ value: ROLES.APPROVER, label: t("approver") }];
  const isOptionApprover = (user: UserDto, users: UserWithRole[]) =>
    !!users.find(
      (userWithRole) =>
        userWithRole.id === user.id && isUserApprover(userWithRole)
    );
  const usersToDisplay = users
    .sort((userA, userB) =>
      getDisplayName(userA).localeCompare(getDisplayName(userB))
    )
    .filter(isUserApprover);

  if (!isVisible) return null;

  return (
    <>
      <ApproversInput
        initialUsers={initialUsers}
        users={users}
        setUsers={setUsers}
        getUsers={getUsers}
        getOptionDisabled={(user: UserDto, users: UserWithRole[]) =>
          isOptionApprover(user, users)
        }
        addUsers={addUsers}
        canAssignApprover={canAssignApprover}
      />
      {!!usersToDisplay.length && (
        <>
          <Divider sx={{ my: "16px" }} />
          <Box
            sx={{
              border: "1px solid",
              borderColor: "grey.100",
              borderRadius: 1,
              py: 1,
              mt: 2,
              gap: 1,
            }}
          >
            {usersToDisplay.map((user) => {
              return (
                <SharedUser
                  users={users}
                  defaultRole={ROLES.APPROVER}
                  modalTitle={t("deleteUser")}
                  toggleParentVisibility={() =>
                    toggleParentVisibility((prevValue) => !prevValue)
                  }
                  disabled={!canAssignApprover}
                  areActionsVisible={canAssignApprover}
                  avatar="invalid_link" // TODO: adjust that in https://appliedaide.atlassian.net/browse/UCTSX-334
                  key={user.id}
                  user={user}
                  canRemove={canAssignApprover}
                  remove={removeUser(user.id, users, setUsers)}
                  menuItems={menuItems}
                />
              );
            })}
          </Box>
        </>
      )}
    </>
  );
};
