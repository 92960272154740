/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfigPayload } from "@applied-ai/types";
import type { UserWithRolesAndPermissionsDto } from "@applied-ai/types";
import type { CancelablePromise } from "@applied-ai/types";
import { OpenAPI } from "@applied-ai/types";
import { request as __request } from "@applied-ai/types";
export class CoreService {
  /**
   * Get User Self Details
   * @returns UserWithRolesAndPermissionsDto OK
   * @throws ApiError
   */
  public static aaiToolsuiteCoreApiCoreGetUserSelfDetails(): CancelablePromise<UserWithRolesAndPermissionsDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/internal/users/me",
      errors: {
        422: `Unprocessable Entity`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Get Config
   * Fetch Config variables
   * @returns ConfigPayload OK
   * @throws ApiError
   */
  public static aaiToolsuiteCoreApiCoreGetConfig(): CancelablePromise<ConfigPayload> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/internal/config",
    });
  }
}
