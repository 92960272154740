import { Button, Link, Menu, MenuItem } from "@applied-ai/components";
import { useTranslation } from "next-i18next";
import React, { useState } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const HELP_LINKS = [
  {
    label: "help.privacyPolicy",
    url: "https://www.appliedai.de/en/privacy-policy/",
  },
  {
    label: "help.aboutUs",
    url: "https://www.appliedai.de/en/philosophy/",
  },
  {
    label: "help.imprint",
    url: "https://www.appliedai.de/en/imprint/",
  },
];

export const HelpMenu: React.FC = () => {
  const { t } = useTranslation("common");
  const [anchorElHelp, setAnchorElHelp] = useState<null | HTMLElement>(null);

  const handleOpenHelpMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorElHelp(event.currentTarget);

  const handleCloseHelpMenu = () => setAnchorElHelp(null);

  return (
    <>
      <Button sx={{ p: "4px" }} onClick={handleOpenHelpMenu}>
        <HelpOutlineIcon
          sx={{
            height: "24px",
            width: "24px",
          }}
        />
      </Button>
      <Menu
        sx={{ mt: "9px" }}
        id="help-menu-appbar"
        anchorEl={anchorElHelp}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          sx: {
            border: "1px solid",
            borderColor: "grey.100",
            overflow: "hidden",
            borderRadius: "4px",
            minWidth: "160px",
          },
        }}
        open={!!anchorElHelp}
        onClose={handleCloseHelpMenu}
      >
        {HELP_LINKS.map(({ label, url }, index) => {
          return (
            <MenuItem
              onClick={handleCloseHelpMenu}
              key={index}
              sx={{ p: 0, minWidth: "166px" }}
            >
              <Link
                href={url}
                target="_blank"
                rel="noreferrer"
                sx={{
                  fontWeight: 400,
                  fontSize: "0.875rem",
                  color: "grey.900",
                  width: "100%",
                  height: "100%",
                  p: 1,
                  ml: 1,

                  "&:hover, &:visited, &:active, &:focus": {
                    color: "grey.900",
                    textDecoration: "none",
                  },
                }}
                size="large"
              >
                {t(label)}
              </Link>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
