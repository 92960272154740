import { QuestionNode } from "@applied-ai/types";

export const isQuestionNodeFinished = (questionNode: QuestionNode): boolean => {
  if (!questionNode.givenAnswerIds.length) {
    return false;
  }
  if (!questionNode.childrenQuestionNodes.length) {
    return true;
  }
  return questionNode.childrenQuestionNodes.every((question) =>
    isQuestionNodeFinished(question)
  );
};
