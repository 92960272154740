import {
  QuestionNode,
  QuestionWithIndex,
  QuestionDto,
  QuestionAnswers,
} from "@applied-ai/types";

export const generateQuestionNode = (
  nodeQuestion: QuestionWithIndex,
  questions: QuestionDto[],
  answers: QuestionAnswers[]
): QuestionNode => {
  const givenAnswerIds =
    answers.find((answer) => answer.questionId === nodeQuestion.id)
      ?.answerIds || [];

  const nextQuestions = givenAnswerIds.reduce((accumulator, givenAnswerId) => {
    const givenAnswer = nodeQuestion.answers.find(
      (answer) => answer.id === givenAnswerId
    );
    const questionAfterGivenAnswer = givenAnswer?.next_question;
    if (questionAfterGivenAnswer) accumulator.push(questionAfterGivenAnswer);
    return accumulator;
  }, [] as QuestionDto[]);

  const childrenQuestionNodes: QuestionNode[] = nextQuestions.reduce(
    (accumulator, currentQuestion, questionIdx) => {
      if (currentQuestion)
        accumulator.push(
          generateQuestionNode(
            {
              ...currentQuestion,
              index: `${nodeQuestion.index}.${questionIdx + 1}`,
            },
            questions,
            answers
          )
        );
      return accumulator;
    },
    [] as QuestionNode[]
  );

  return {
    question: nodeQuestion,
    givenAnswerIds,
    childrenQuestionNodes,
  };
};
