import { CustomSession } from "@applied-ai/types";
import useSWR from "swr";

// TODO: This will come from enum when backend cleanup swagger
const ADMIN = "admin";
export const usePermissions = () => {
  // Fetching session to retrieve the latest data
  const { data } = useSWR(
    `${process.env.NEXT_PUBLIC_UCTS_PATH}/api/auth/session`,
    async (url) => {
      const res = await fetch(url);
      if (!res.ok) {
        throw new Error();
      }
      return res.json();
    },
    {
      revalidateOnFocus: false,
    }
  );

  const userData = (data as CustomSession)?.user || {};
  const roles = [
    ...Object.keys(userData?.roles?.ucts || {}),
    ...Object.keys(userData?.roles?.rct || {}),
  ];
  const permissions = userData?.permissions || [];
  const isAdmin = roles.includes(ADMIN);
  const hasPermission = (permissionToCheck: string) =>
    permissions.includes(permissionToCheck);

  return { permissions, hasPermission, roles, isAdmin };
};
