import { AccentColor, Color, CommonColors, createTheme } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";

export const theme = createTheme({
  components: {
    MuiModal: {
      defaultProps: {
        disableScrollLock: true,
      },
    },
    MuiPopover: {
      defaultProps: {
        disableScrollLock: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        // WEBKIT
        "*::-webkit-scrollbar": {
          width: "10px",
          height: "10px",
          backgroundColor: "#F0F2F3",
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "#C3CACE",
          borderRadius: "8px",
          border: "2px solid #F0F2F3",
        },
        "*::-webkit-scrollbar-track": {
          backgroundColor: "#F0F2F3",
        },

        html: {
          height: "100%",
          width: "100vw",
          overflowX: "hidden",
        },
        body: {
          backgroundColor: "#F0F2F3",
          "&, & #__next": {
            height: "100%",
          },
          overflow: "hidden",
        },
      },
    },
  },
  palette: {
    common: {
      white: "#FFF",
    },
    primary: {
      50: "#EBF9FA",
      100: "#D1E9EB",
      200: "#99C7CA",
      300: "#66ACB0",
      400: "#339095",
      500: "#00747B",
      600: "#105c61",
      700: "#144549",
      800: "#133032",
      900: "#0f1b1c",
    },
    grey: {
      50: "#FCFCFD",
      100: "#F0F2F3",
      200: "#E1E4E7",
      300: "#C3CACE",
      400: "#95A2AA",
      500: "#687a85",
      600: "#536169",
      700: "#3f484e",
      800: "#2b3135",
      900: "#191c1e",
    },
    purple: {
      50: "#F0EEF9",
      100: "#E2DEF2",
      200: "#C5BDE6",
      300: "#A79BD9",
      400: "#8A7ACD",
      500: "#6D59C0",
      600: "#57479A",
      700: "#413573",
      800: "#2C244D",
      900: "#161226",
    },
    accent: {
      orange: "#FA8F0D",
      blue: "#32548B",
    },
    aiPurple: {
      50: "#F0EEF9",
      100: "#E2DEF2",
      200: "#C5BDE6",
      300: "#A79BD9",
      400: "#8A7ACD",
      500: "#6D59C0",
      600: "#57479A",
      700: "#413573",
      800: "#2C244D",
      900: "#161226",
    },
    green: {
      50: "#E6F6EC",
      100: "#CEECD9",
      300: "#6BC78C",
      500: "#09A240",
      700: "#1a5f29",
      900: "#122313",
    },
    yellow: {
      50: "#FEF8E8",
      100: "#FEF1D1",
      300: "#FBD675",
      500: "#F9BA19",
      700: "#8e6b1b",
      900: "#312611",
    },
    red: {
      50: "#FEECEC",
      100: "#FDDADA",
      300: "#F98F8F",
      500: "#F54444",
      700: "#8d2f2b",
      900: "#311613",
    },
    blue: {
      100: "#DAEBFF",
      500: "#4791FF",
      700: "#1E3253",
    },
    funnelChartPalette: {
      "0.5": "#E2F1F2",
      "1": "#C9E3E5",
      "1.5": "#B0D5D7",
      "2": "#97C7CA",
      "3": "#64ACB0",
      "4": "#329095",
      "5": "#00747B",
      "6": "#005D62",
      "7": "#00464A",
      "8": "#002E31",
      "8.5": "#002325",
      "9": "#001719",
    },
    chartPalette: {
      1: "#00747B",
      2: "#FA8F0D",
      3: "#32548B",
      4: "#FBD675",
      5: "#1a5f29",
      6: "#4791FF",
      7: "#F54444",
      8: "#8498B9",
      9: "#144549",
      10: "#F9BA19",
      11: "#2B5799",
      12: "#6BC78C",
    },
  },
  pxToRem: {
    8: "0.5rem",
    10: "0.625rem",
    12: "0.75rem",
    14: "0.875rem",
    16: "1rem",
    18: "1.125rem",
    20: "1.25rem",
    24: "1.5rem",
    28: "1.75rem",
    32: "2rem",
    36: "2.25rem",
    40: "2.5rem",
  },
  customZIndex: {
    appBackdrop: 1600,
    toast: 1601,
  },
  typography: {
    fontFamily: "'Work Sans Variable', sans-serif",
    bodyRegular16: {
      color: "#3F484E",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.5rem",
    },
    bodyRegular14: {
      color: "#3F484E",
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1.25rem",
    },
    bodyRegular12: {
      color: "#3F484E",
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "1rem",
    },
    bodyRegular10: {
      color: "#3F484E",
      fontSize: "0.625rem",
      fontWeight: 400,
      lineHeight: "0.75rem",
    },
    bodyMedium16: {
      color: "#3F484E",
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "1.5rem",
    },
    bodyMedium20: {
      color: "#3F484E",
      fontSize: "1.25rem",
      fontWeight: 500,
      lineHeight: "1.75rem",
    },
    bodyMedium14: {
      color: "#3F484E",
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: "1.25rem",
    },
    bodyMedium12: {
      color: "#3F484E",
      fontSize: "0.75rem",
      fontWeight: 500,
      lineHeight: "1rem",
    },
    bodyMedium10: {
      color: "#3F484E",
      fontSize: "0.625rem",
      fontWeight: 500,
      lineHeight: "0.75rem",
    },
    bodyMediumUnderline16: {
      color: "#3F484E",
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "1.5rem",
      textDecorationLine: "underline",
    },
    bodyMediumUnderline14: {
      color: "#3F484E",
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: "1.25rem",
      textDecorationLine: "underline",
    },
    bodyMediumUnderline12: {
      color: "#3F484E",
      fontSize: "0.75rem",
      fontWeight: 500,
      lineHeight: "1rem",
      textDecorationLine: "underline",
    },
    h1: {
      color: "#191C1E",
      fontSize: "2.5rem",
      fontWeight: 500,
      lineHeight: "3.25rem",
      letterSpacing: "-0.8px",
    },
    h2: {
      color: "#191C1E",
      fontSize: "2rem",
      fontWeight: 500,
      lineHeight: "2.5rem",
      letterSpacing: "-0.64px",
    },
    h3: {
      color: "#191C1E",
      fontSize: "1.25rem",
      fontWeight: 500,
      lineHeight: "1.75rem",
      letterSpacing: "-0.2px",
    },
    h4: {
      color: "#191C1E",
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "1.5rem",
    },
  } as ExtendedTypographyOptions,
  breakpoints: {
    1280: "1280px",
    1440: "1440px",
    1600: "1600px",
    1920: "1920px",
  },
  transitions: {},
});

interface FunnelChartPalette {
  "0.5": string;
  "1": string;
  "1.5": string;
  "2": string;
  "3": string;
  "4": string;
  "5": string;
  "6": string;
  "7": string;
  "8": string;
  "8.5": string;
  "9": string;
}

interface ChartPalette {
  1: string;
  2: string;
  3: string;
  4: string;
  5: string;
  6: string;
  7: string;
  8: string;
  9: string;
  10: string;
  11: string;
  12: string;
}

export interface AppPalette {
  common: CommonColors;
  primary: Color;
  grey: Color;
  accent: AccentColor;
  aiPurple: Color;
  green: Color;
  yellow: Color;
  red: Color;
  blue: Color;
  purple: Color;
  funnelChartPalette: FunnelChartPalette;
  chartPalette: ChartPalette;
}

interface Breakpoints {
  1280: string;
  1440: string;
  1600: string;
  1920: string;
}

declare module "@mui/material/styles" {
  interface Theme {
    palette: AppPalette;
    pxToRem: {
      [key: number]: string;
    };
    customZIndex: {
      appBackdrop: number;
      toast: number;
    };
    breakpoints: Breakpoints;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    pxToRem: {
      [key: number]: string;
    };
    customZIndex: {
      appBackdrop: number;
      toast: number;
    };
    breakpoints: Breakpoints;
  }

  export interface FunnelChartPalette {
    "0.5": string;
    "1": string;
    "1.5": string;
    "2": string;
    "3": string;
    "4": string;
    "5": string;
    "6": string;
    "7": string;
    "8": string;
    "8.5": string;
    "9": string;
  }

  interface Palette {
    green: Partial<Color>;
    yellow: Partial<Color>;
    red: Partial<Color>;
    blue: Partial<Color>;
    purple: Partial<Color>;
    common: CommonColors;
    accent: AccentColor;
    funnelChartPalette: FunnelChartPalette;
  }

  interface AccentColor {
    orange: string;
    blue: string;
  }

  interface PaletteOptions {
    green: Partial<Color>;
    yellow: Partial<Color>;
    red: Partial<Color>;
    blue: Partial<Color>;
    purple: Partial<Color>;
    accent: AccentColor;
    aiPurple: Partial<Color>;
    funnelChartPalette: FunnelChartPalette;
    chartPalette: ChartPalette;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    bodyRegular16: true;
    bodyRegular14: true;
    bodyRegular12: true;
    bodyRegular10: true;
    bodyMedium16: true;
    bodyMedium20: true;
    bodyMedium14: true;
    bodyMedium12: true;
    bodyMedium10: true;
    bodyMediumUnderline16: true;
    bodyMediumUnderline14: true;
    bodyMediumUnderline12: true;
    h1: true;
    h2: true;
    h3: true;
    h4: true;
  }
}

interface TypographyVariantOptions {
  color: string;
  fontSize: number | string;
  lineHeight: number | string;
  fontWeight?: number | string;
  letterSpacing?: string;
  textDecorationLine?: string;
}

interface ExtendedTypographyOptions extends TypographyOptions {
  bodyRegular16: TypographyVariantOptions;
  bodyRegular14: TypographyVariantOptions;
  bodyRegular12: TypographyVariantOptions;
  bodyRegular10: TypographyVariantOptions;
  bodyMedium16: TypographyVariantOptions;
  bodyMedium20: TypographyVariantOptions;
  bodyMedium14: TypographyVariantOptions;
  bodyMedium12: TypographyVariantOptions;
  bodyMedium10: TypographyVariantOptions;
  bodyMediumUnderline16: TypographyVariantOptions;
  bodyMediumUnderline14: TypographyVariantOptions;
  bodyMediumUnderline12: TypographyVariantOptions;
}
