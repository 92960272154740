import { styled } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { StyledAccordionSummary } from "../Accordion/StyledAccordionSummary";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Typography } from "../../atoms";
import { ReactNode, useState } from "react";

const StyledAccordion = styled(Accordion)(() => {
  return {
    boxShadow: "none",
    borderBottom: "none",
    borderRadius: "4px",
    padding: 0,
    margin: 0,
    "&:before": { display: "none" },
    "&.Mui-expanded": {
      margin: 0,
    },
    "& .MuiAccordionDetails-root": {
      padding: "12px 12px 12px 36px",
    },
  };
});

const AccordionSubheader: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        color: theme.palette.grey[600],
        textTransform: "uppercase",
        fontSize: "0.625rem",
        fontWeight: 500,
        lineHeight: "0.75rem",
        minHeight: "10px",
      }}
    >
      {children}
    </Box>
  );
};

export interface ProgressBarGroupFieldProps {
  id: string;
  header: ReactNode;
  subheader?: string;
  active?: boolean;
  disabled?: boolean;
  showArrow?: boolean;
  children?: ReactNode;
  expanded?: boolean;
  onClick?: () => void;
}

export const ProgressBarGroupField = ({
  active,
  disabled,
  id,
  header,
  subheader,
  showArrow,
  children,
  expanded,
  onClick,
}: ProgressBarGroupFieldProps) => {
  const [expand, setExpand] = useState(!!expanded);
  const disabledStyles = disabled
    ? { color: "grey.300", backgroundColor: "grey.100" }
    : {};
  const handleClick = () => {
    if (onClick) onClick();
    if (children) setExpand((prev) => !prev);
  };

  const expandIcon = () => {
    if (!showArrow) return undefined;
    if (children) return <ArrowRightIcon />;
    return <Box sx={{ width: "24px" }} />;
  };

  return (
    <StyledAccordion expanded={expand}>
      <StyledAccordionSummary
        onClick={handleClick}
        isActive={!!active}
        expandIcon={expandIcon()}
        data-testid="Section"
        aria-controls={`panel-${id}-content`}
        id={`panel-${id}-header`}
        sx={{
          flexDirection: "row-reverse",
          ...disabledStyles,
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
          },
        }}
      >
        <Box sx={{ flexDirection: "column" }}>
          <Typography variant="bodyMedium16">{header}</Typography>
          {subheader && <AccordionSubheader>{subheader}</AccordionSubheader>}
        </Box>
      </StyledAccordionSummary>
      {children && (
        <AccordionDetails sx={{ py: 0, pr: 0 }}>{children}</AccordionDetails>
      )}
    </StyledAccordion>
  );
};
