import { styled } from "../../reexports";
import { getBorderShadow } from "../../utils";
import { Link } from "../Link/Link";

export const BreadcrumbLink = styled(Link)`
  max-width: 220px;
  display: inline-block;
  vertical-align: bottom;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.grey[700]};
  font-weight: 500;
  line-height: 20px;

  &:visited {
    color: ${({ theme }) => theme.palette.grey[700]};
  }
  &:hover {
    color: ${({ theme }) => theme.palette.grey[900]};
    text-decoration: none;
    box-shadow: ${({ theme }) =>
      getBorderShadow(theme.palette.grey[900], "bottom")};
  }
`;
