import { MouseEventHandler, ReactNode } from "react";
import { Button } from "../Button/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "../../reexports";
import { theme } from "../../theme/theme";

interface LoadingButtonProps {
  onClick?: MouseEventHandler;
  loading: boolean;
  children: ReactNode;
}

export const LoadingButton = ({
  onClick,
  loading,
  children,
}: LoadingButtonProps) => {
  return (
    <Button
      onClick={onClick}
      disabled={loading}
      sx={{
        display: "grid",
        "& > *": {
          gridArea: "1/1",
        },
        "&.Mui-disabled": {
          background: `${theme.palette.primary[500]} !important`,
        },
      }}
    >
      <Box
        sx={{
          visibility: loading ? "hidden" : "visible",
          display: "flex",
        }}
      >
        {children}
      </Box>
      {loading && (
        <CircularProgress
          size="1rem"
          sx={{
            placeSelf: "center",
            color: `${theme.palette.common.white} !important`,
          }}
        />
      )}
    </Button>
  );
};
