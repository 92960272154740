import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material";
import { ReactNode } from "react";

const StyledTableContainer = styled(TableContainer)`
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.palette.grey[200]};
`;

const StyledTableHead = styled(TableHead)`
  border-bottom: 1px solid ${(p) => p.theme.palette.grey[200]};
`;

const StyledTableRow = styled(TableRow)`
  display: flex;
  &:last-child td,
  &:last-child th {
    border-bottom: none;
  }

  & td:not(:first-child),
  & th:not(:first-child) {
    border-left: 1px solid ${(p) => p.theme.palette.grey[200]};
  }
`;

const StyledTableCell = styled(TableCell)`
  flex: 1;
  padding: 12px;
  background-color: ${(p) => p.theme.palette.common.white};
  color: ${(p) => p.theme.palette.grey[900]};
  word-break: break-word;

  &.MuiTableCell-head {
    font-weight: 500;
    background-color: ${(p) => p.theme.palette.grey[50]};
  }
`;

interface SimpleTableProps {
  headers: string[];
  rows: (string | ReactNode)[][];
}

export const SimpleTable = ({ headers, rows }: SimpleTableProps) => {
  return (
    <StyledTableContainer>
      <Table aria-label="table" data-testid="table">
        <StyledTableHead>
          <StyledTableRow>
            {headers.map((header) => (
              <StyledTableCell key={header}>{header}</StyledTableCell>
            ))}
          </StyledTableRow>
        </StyledTableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.toString()}>
              {row.map((cell) => (
                <StyledTableCell
                  key={cell?.toString()}
                  component="th"
                  scope="row"
                >
                  {cell}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};
