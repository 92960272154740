export const DATE_FORMAT = {
  year: "numeric",
  month: "short",
  day: "numeric",
} as const;

export const LOCALES: Record<string, string> = {
  default: "en-GB",
  en: "en-GB",
  de: "de-DE",
};
