import React from "react";
import { Box } from "../../atoms";
import { SxProps } from "@mui/material";

interface ModalWrapperProps {
  children: React.ReactNode;
  sxProps?: SxProps;
}

export const ModalWrapper: React.FC<ModalWrapperProps> = React.forwardRef(
  ({ children, sxProps }, ref) => (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        maxWidth: 600,
        bgcolor: "common.white",
        borderRadius: 1,
        display: "flex",
        flexDirection: "column",
        maxHeight: "95vh",
        ...(sxProps || {}),
      }}
    >
      {children}
    </Box>
  )
);
