import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { Box, TextField, Typography } from "../../atoms";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "next-i18next";
import { TextFieldProps } from "@mui/material";

const PLACEHOLDERS = {
  text: "enterTextHere",
  number: "numberInputPlaceholder",
};

const INPUTS_PROPS = {
  number: {
    min: 0,
    valueAsNumber: true,
  },
};

export function TextFieldWithCounter<T extends FieldValues>({
  type,
  id,
  label,
  control,
  disabled,
  maxLength,
  required,
  error,
  ...props
}: {
  id: string;
  type: string;
  label: string;
  control: Control<T>;
  disabled: boolean;
  maxLength?: number;
  required?: boolean;
  error?: string;
} & Omit<TextFieldProps, "error">) {
  const lowerCasedType = type.toLowerCase();
  const isTextType = type.toLowerCase() === "text";
  const { t } = useTranslation(["common"]);
  const [valueLength, setValueLength] = useState(
    isTextType ? control?._formValues?.[id]?.length || 0 : 0
  );

  const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    isTextType && setValueLength(e?.target?.value?.length);
  };

  const inputPlaceholder = t(
    PLACEHOLDERS[lowerCasedType as keyof typeof PLACEHOLDERS]
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Controller
        name={id as Path<T>}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        control={control}
        rules={{
          maxLength,
          required,
          ...(isTextType
            ? {
                validate: (value) => (value ? !!value.trim() : true),
              }
            : INPUTS_PROPS[lowerCasedType as keyof typeof INPUTS_PROPS]),
        }}
        render={({ field }) => {
          return (
            <TextField
              {...field}
              disabled={disabled}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onValueChange(e);
                field.onChange(e);
              }}
              type={type}
              label={label}
              placeholder={inputPlaceholder}
              error={!!error}
              inputProps={{
                ...(props.inputProps || {}),
                ...(INPUTS_PROPS[lowerCasedType as keyof typeof INPUTS_PROPS] ||
                  {}),
              }}
              autoFocus={props.autoFocus}
              {...props}
            />
          );
        }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "4px",
        }}
      >
        <Typography
          variant="bodyRegular12"
          sx={{ color: "red.500", ml: "8px" }}
        >
          {error}
        </Typography>
        {maxLength && isTextType && (
          <Typography variant="bodyRegular12" sx={{ color: "grey.700" }}>
            {`${valueLength}/${maxLength}`}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
