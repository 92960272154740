import { BoxProps, styled } from "@mui/material";
import { Box, Typography } from "../../atoms";

export const StyledTextFieldViewBox = styled(Box)<
  { disabled?: boolean } & BoxProps
>`
  border-radius: 2px;
  padding: 2px;
  white-space: pre-line;
  :hover {
    cursor: ${(p) => (p.disabled ? "default" : "pointer")};
    background-color: ${(p) => !p.disabled && p.theme.palette.primary[50]};
  }
`;

interface TextFieldViewBoxProps {
  value: string;
  onClick: () => void;
  disabled?: boolean;
}

export const TextFieldViewBox = ({
  disabled,
  value,
  onClick,
}: TextFieldViewBoxProps) => {
  return (
    <StyledTextFieldViewBox
      disabled={disabled}
      {...(disabled ? {} : { onClick })}
    >
      <Typography variant="bodyRegular14" sx={{ overflowWrap: "break-word" }}>
        {value}
      </Typography>
    </StyledTextFieldViewBox>
  );
};
