import {
  CssBaseline,
  ErrorBoundary,
  ErrorComponent,
  ThemeProvider,
  ToastManager,
} from "@applied-ai/components";
import { ErrorPageLayout } from "@applied-ai/layout";
import { OpenAPI } from "@applied-ai/types/apiTypes/core/OpenAPI";
import "@fontsource-variable/work-sans";
import { SessionProvider } from "next-auth/react";
import { appWithTranslation } from "next-i18next";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { generatePageTitle } from "@applied-ai/utils";

function UCTSApp({ Component, pageProps }: AppProps) {
  OpenAPI.TOKEN = pageProps.session?.user?.accessToken;
  const router = useRouter();

  return (
    <ThemeProvider>
      <CssBaseline />
      <ToastManager />

      <SessionProvider
        session={pageProps.session}
        basePath={`${process.env.NEXT_PUBLIC_UCTS_PATH}/api/auth`}
        refetchOnWindowFocus={false}
      >
        <Head>
          <title>{generatePageTitle(router.pathname)}</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
        <ErrorBoundary
          fallback={
            <ErrorPageLayout session={pageProps.session} displayFooter>
              <ErrorComponent
                appPath={process.env.NEXT_PUBLIC_UCTS_PATH}
                errorStatus={"somethingWentWrong"}
              />
            </ErrorPageLayout>
          }
        >
          <Component {...pageProps} />
        </ErrorBoundary>
      </SessionProvider>
    </ThemeProvider>
  );
}

export default appWithTranslation(UCTSApp);
