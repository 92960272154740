import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const useLoadingOnRouteChange = (initialState = true) => {
  const [isLoading, setIsLoading] = useState(initialState);
  const router = useRouter();

  const routeChangeStart = () => setIsLoading(true);
  const routeChangeEnd = () => setIsLoading(false);

  useEffect(() => {
    setIsLoading(!router.isReady);
  }, []);

  useEffect(() => {
    router.events.on("routeChangeStart", routeChangeStart);
    router.events.on("routeChangeComplete", routeChangeEnd);

    return () => {
      router.events.off("routeChangeStart", routeChangeStart);
      router.events.off("routeChangeComplete", routeChangeEnd);
    };
  }, []);

  return { isLoading, setIsLoading };
};
