import { Page, UctsFeatureFlagsPayload } from "@applied-ai/types";

export const filterNavLinksByFeatureFlags = (
  pages: Page[],
  flags?: UctsFeatureFlagsPayload[]
): Page[] =>
  pages.filter(
    (navLink) =>
      !flags?.find((flag) => flag.name === navLink.id && !flag.enabled)
  );
