import { ReactNode } from "react";
import { Box } from "../../reexports";
import WarningAmber from "@mui/icons-material/WarningAmber";
import Typography from "@mui/material/Typography";
import { BoxProps } from "@mui/material";
import { theme } from "../../theme/theme";

interface DisclaimerProps extends BoxProps {
  title: string;
  children: ReactNode;
}

export const Disclaimer: React.FC<DisclaimerProps> = ({
  title,
  children,
  ...boxProps
}) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
      {...boxProps}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: theme.palette.accent.orange,
        }}
      >
        <WarningAmber sx={{ fontSize: "1rem", paddingRight: "4px" }} />
        <Typography variant="bodyMedium14">{title}</Typography>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};
