export const useVisitedHandler = (keyName: string) => {
  if (typeof window === "undefined") {
    return {
      wasVisited: false,
      setVisited: () => {
        return;
      },
    };
  }

  return {
    wasVisited: localStorage?.[keyName] === "true",
    setVisited: () => localStorage.setItem(keyName, "true"),
  };
};
