import {
  CAN_VIEW_AI_MODELS,
  CAN_VIEW_DATA_SOURCES,
  CAN_VIEW_PORTFOLIO,
  CAN_VIEW_USE_CASES,
} from "@applied-ai/auth/permissions";
import { INIT_FILTERS } from "./filters";

export const NAV_LINKS = [
  {
    id: "use-cases",
    href: `/?${new URLSearchParams(INIT_FILTERS)}`,
    pathRegex: /^(\/\?.*|\/use-cases\/.*)$/,
    label: "ucts:useCase.useCases",
    requiredPermission: CAN_VIEW_USE_CASES,
  },
  {
    id: "data-sources",
    href: `/data-sources?${new URLSearchParams(INIT_FILTERS)}`,
    pathRegex: /^\/data-sources?.*$/,
    label: "ucts:dataSource.dataSources",
    requiredPermission: CAN_VIEW_DATA_SOURCES,
  },
  {
    id: "ai-models",
    href: `/models?${new URLSearchParams(INIT_FILTERS)}`,
    pathRegex: /^\/models?.*$/,
    label: "ucts:aiModel.models",
    requiredPermission: CAN_VIEW_AI_MODELS,
  },
  {
    id: "portfolio",
    href: "/portfolio",
    pathRegex: /^\/portfolio.*$/,
    label: "ucts:portfolio.portfolio",
    requiredPermission: CAN_VIEW_PORTFOLIO,
  },
];
