import { SxProps, Theme, styled } from "@mui/material";
import { Mention, MentionsInput } from "react-mentions";
import { theme } from "../../theme/theme";
import { MentionUser } from "./MentionTypes";

const MentionViewStyled = styled(MentionsInput)`
  font-size: 0.875rem;
  color: ${theme.palette.grey[900]};

  textarea {
    padding: 0;
    border: none !important;
    line-height: 1.25rem;
    word-break: break-all;
  }

  .mentionView__highlighter {
    padding: 0;
    line-height: 1.25rem;
    border: none !important;
    word-break: break-all;
  }

  .mention {
    z-index: 1;
    position: relative;

    color: ${theme.palette.blue[500]};
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    text-decoration: none;
    pointer-events: none;
  }
`;

interface Props {
  users: MentionUser[];
  value: string;
  sx?: SxProps<Theme>;
}

export const MentionView = ({ users, value, sx }: Props) => {
  return (
    <MentionViewStyled className="mentionView" sx={sx} value={value} disabled>
      <Mention
        className="mention"
        trigger="@"
        markup="@{{__id__}}"
        data={users}
        displayTransform={(id) => {
          const item = users.find((item) => String(item.id) === String(id));
          return `@${item?.display}`;
        }}
      />
    </MentionViewStyled>
  );
};
