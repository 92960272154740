/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Verdicts for sections and entire RCF.
 * Each answer has verdict which will be used to calculate section verdict.
 * The `IN_PROGRESS` verdict is considered as NOOP.
 *
 * NOTE: Changing this Enum has an impact on the Verdicts calculation
 * and AnswerModel data integrity.
 *
 * AnswerModel has non-explicit relation with this Enum,
 * as only the values declared here should be present as it's `verdict` value.
 * Changing any value in this Enum should also change related values in the DB at the same time.
 *
 * Adding any new value should also be reflected in the way verdicts are calculated.
 */
export enum Verdict {
  IN_PROGRESS = "IN_PROGRESS",
  PROVIDER = "PROVIDER",
  DEPLOYER = "DEPLOYER",
  PROVIDER_AND_DEPLOYER = "PROVIDER_AND_DEPLOYER",
  GPAI_PROVIDER = "GPAI_PROVIDER",
  GPAI_PROVIDER_AND_PROVIDER = "GPAI_PROVIDER_AND_PROVIDER",
  GPAI_PROVIDER_PROVIDER_AND_DEPLOYER = "GPAI_PROVIDER_PROVIDER_AND_DEPLOYER",
  OTHER_ROLE = "OTHER_ROLE",
  AI_SYSTEM = "AI_SYSTEM",
  IN_SCOPE = "IN_SCOPE",
  NOT_PROHIBITED = "NOT_PROHIBITED",
  NO_TRANSPARENCY_OBLIGATIONS = "NO_TRANSPARENCY_OBLIGATIONS",
  LOW_RISK = "LOW_RISK",
  HUMAN_INTERACTION = "HUMAN_INTERACTION",
  HIGH_RISK = "HIGH_RISK",
  HIGH_RISK_AND_HUMAN_INTERACTION = "HIGH_RISK_AND_HUMAN_INTERACTION",
  PROHIBITED = "PROHIBITED",
  NOT_IN_SCOPE = "NOT_IN_SCOPE",
}
