import { NavbarWithContentLayout } from "../NavbarWithContentLayout/NavbarWithContentLayout";
import { NoSidebarsLayout } from "../ContentLayout/NoSidebarsLayout/NoSidebarsLayout";
import { NAV_LINKS } from "@applied-ai/utils";
import { AppsConfigPayload } from "@applied-ai/types";
import { Session } from "next-auth/core/types";

export const ErrorPageLayout: React.FC<{
  children: React.ReactNode;
  session: Session | null;
  displayFooter?: boolean;
  config?: AppsConfigPayload;
}> = ({ children, session, config, displayFooter }) => {
  return (
    <NavbarWithContentLayout
      pages={NAV_LINKS}
      session={session}
      displayFooter={displayFooter}
      config={config}
    >
      <NoSidebarsLayout.Main>{children}</NoSidebarsLayout.Main>
    </NavbarWithContentLayout>
  );
};
