import { ReactNode } from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material";
import { Typography, Box } from "../../atoms";
import { theme } from "../../theme/theme";

const PaperBox = styled(Paper)`
  width: 100%;
  padding: 24px;
  box-shadow: 0px 0px 0px 0px;
  background-color: ${(p) => p.theme.palette.common.white};
  border: 1px solid ${(p) => p.theme.palette.grey[200]};
  border-radius: 8px;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Details = styled("div")`
  padding: 0px;
  padding-top: 16px;
`;

interface SectionTileProps {
  title: string;
  icon?: ReactNode;
  children: ReactNode;
}

export const SectionTile: React.FC<SectionTileProps> = ({
  children,
  icon,
  title,
}) => (
  <PaperBox data-testid="SectionTile">
    <HeaderWrapper>
      <Typography
        variant="bodyMedium16"
        sx={{ color: theme.palette.grey[900] }}
      >
        {title}
      </Typography>
      {icon || null}
    </HeaderWrapper>
    <Details>{children}</Details>
  </PaperBox>
);
