export function isBiggerThan(breakpoint: string) {
  const mediaQuery = `(min-width: ${breakpoint})`;
  const list = window.matchMedia(mediaQuery);

  return list.matches;
}

export function isSmallerThan(breakpoint: string) {
  const mediaQuery = `(max-width: ${breakpoint})`;
  const list = window.matchMedia(mediaQuery);

  return list.matches;
}
