import MuiAlert from "@mui/material/Alert";
import { AlertProps, styled } from "@mui/material";
import { theme } from "../../theme/theme";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";

const StyledAlert = styled(MuiAlert)`
  border: 1px solid;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: ${theme.palette.grey[900]};
  border-radius: 4px;
  white-space: pre-line;

  & .MuiAlert-icon {
    margin-right: 8px;
    font-size: 1.25rem;
    align-items: flex-start;
  }
  &.MuiAlert-standardSuccess {
    background-color: ${theme.palette.green[100]};
    border-color: ${theme.palette.green[500]};

    & .MuiAlert-icon {
      color: ${theme.palette.green[500]};
    }
  }
  &.MuiAlert-standardInfo {
    background-color: ${theme.palette.blue[100]};
    border-color: ${theme.palette.blue[500]};

    & .MuiAlert-icon {
      color: ${theme.palette.grey[900]};
    }
  }
  &.MuiAlert-standardWarning {
    background-color: ${theme.palette.yellow[100]};
    border-color: ${theme.palette.yellow[500]};

    & .MuiAlert-icon {
      color: ${theme.palette.grey[900]};
    }
  }
  &.MuiAlert-standardError {
    background-color: ${theme.palette.red[100]};
    border-color: ${theme.palette.red[500]};

    & .MuiAlert-icon {
      color: ${theme.palette.red[500]};
    }
  }
`;

export const Alert: React.FC<AlertProps> = (props) => {
  if (props.severity === "success") {
    return (
      <StyledAlert
        icon={props.icon ? props.icon : <CheckCircleOutline fontSize="small" />}
        {...props}
      />
    );
  }
  return <StyledAlert {...props} icon={props.icon ? props.icon : null} />;
};
