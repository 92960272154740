import dynamic from "next/dynamic";
import { MutableRefObject, forwardRef, useRef, useState } from "react";
import "suneditor/dist/css/suneditor.min.css";
import SunEditorCore from "suneditor/src/lib/core";
import { ClickAwayListener } from "../../reexports";
import { StyledEditorWrapper } from "./RichEditor.styled";
import {
  handleOnKeyDown,
  isContentEmpty,
  richEditorOptions,
  sanitizeEmptyContent,
} from "./utils";

interface RichEditorProps {
  editorValue: string | string[] | undefined;
  disabled: boolean;
  autoFocus?: boolean;
  onChange?: (data: string) => void;
  onBlur?: (data: string) => Promise<void>;
}

const SunEditor = dynamic(() => import("suneditor-react"), {
  ssr: false,
});

export const RichEditorComponent = forwardRef(
  (
    { editorValue, onBlur, onChange, disabled, autoFocus }: RichEditorProps,
    ref
  ) => {
    const editor = useRef<SunEditorCore>();
    const getSunEditorInstance = (sunEditor: SunEditorCore) => {
      editor.current = sunEditor;
      if (
        ref !== null &&
        (ref as MutableRefObject<unknown>)?.current !== undefined
      ) {
        (ref as MutableRefObject<unknown>).current = sunEditor;
      }
    };

    const [isEditorActive, setIsEditorActive] = useState(false);

    const handleBlur = (event: FocusEvent, content: string) =>
      onBlur?.(sanitizeEmptyContent(content));

    const onChangeHandler = (content: string) =>
      onChange?.(sanitizeEmptyContent(content));

    const editorContent =
      editor.current?.core.context.element.wysiwyg.innerHTML;

    const isValueEmpty =
      !editorContent || isContentEmpty(editorContent as string);

    return (
      <ClickAwayListener onClickAway={() => setIsEditorActive(false)}>
        <StyledEditorWrapper
          canEdit={!disabled}
          isActive={isEditorActive}
          isValueEmpty={isValueEmpty}
        >
          <SunEditor
            onFocus={() => setIsEditorActive(true)}
            autoFocus={autoFocus}
            disable={disabled}
            getSunEditorInstance={getSunEditorInstance}
            onBlur={handleBlur}
            onChange={onChangeHandler}
            setOptions={richEditorOptions}
            height="300px"
            defaultValue={editorValue as string}
            onKeyDown={handleOnKeyDown}
          />
        </StyledEditorWrapper>
      </ClickAwayListener>
    );
  }
);
