import { SortByOption } from "@applied-ai/types";

export const CARD_LIMIT_ON_FIRST_PAGE = 11;
export const CARD_LIMIT_ON_PAGE = 12;

export const SORT_BY_OPTIONS: SortByOption[] = [
  {
    value: "-created_at",
    label: "common:filtering:newestFirst",
  },
  {
    value: "created_at",
    label: "common:filtering:oldestFirst",
  },
];
