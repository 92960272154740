import { Box, TextField, Typography } from "../../atoms";
import { QuestionTile } from "../QuestionTile/QuestionTile";
import { TextFieldProps, alpha, useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useRef, useState } from "react";

interface QuestionTextFieldTileProps {
  question: string;
  fieldId: string;
  fieldValue: string;
  placeholder: string;
  handleValueChange: (
    value: string,
    successCallback?: () => void
  ) => Promise<void>;
  onIconClick: () => void;
  onFocus?: () => void;
  resetError: () => void;
  fieldErrorMessage?: string;
  helperText?: string;
  required?: boolean;
  onCommentIconClick?: () => void;
  disabled?: boolean;
  type?: TextFieldProps["type"];
  isHintAvailable?: boolean;
  isTextFieldActive?: boolean;
  label?: string;
  maxFieldLength?: number;
}

export const QuestionTextFieldTile = ({
  disabled,
  fieldId,
  question,
  fieldValue,
  fieldErrorMessage,
  placeholder,
  helperText,
  required,
  handleValueChange,
  onIconClick,
  onCommentIconClick,
  onFocus,
  type,
  isHintAvailable,
  isTextFieldActive,
  label,
  maxFieldLength,
  resetError,
}: QuestionTextFieldTileProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [maxFieldLengthError, setMaxFieldLengthError] = useState(false);
  const theme = useTheme();
  const [textFieldLength, setTextFieldLength] = useState(
    fieldValue?.length || 0
  );
  const { t } = useTranslation(["common"]);

  const handleBlur = async () => {
    if (maxFieldLengthError) {
      return;
    }

    const currentFieldValue = inputRef.current?.value.trim();

    if (currentFieldValue === fieldValue) {
      return;
    }

    await handleValueChange(currentFieldValue || "");
  };

  const handleInputTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetError();

    if (maxFieldLength) {
      setMaxFieldLengthError(e.target.value.length > maxFieldLength);
    }
    setTextFieldLength(e.target.value.length);
  };

  const textFieldProps = {
    multiline: true,
    minRows: 4,
  };

  return (
    <QuestionTile
      id={fieldId}
      helperText={helperText}
      question={question}
      htmlFor={fieldId}
      onIconClick={onIconClick}
      onFocus={onFocus}
      onCommentIconClick={onCommentIconClick}
      required={required}
      isHintAvailable={isHintAvailable}
      label={label}
      isActive={isTextFieldActive}
    >
      <TextField
        sx={{
          borderRadius: "4px",
          width: type !== "number" ? "100%" : "50%",
          border: isTextFieldActive
            ? `1px solid ${theme.palette.primary[500]}`
            : "",
          boxShadow: isTextFieldActive
            ? `0px 0px 4px 0px ${alpha(theme.palette.primary[500], 0.6)}`
            : "",
        }}
        id={fieldId}
        inputRef={inputRef}
        required
        type={type}
        {...(type !== "number" ? textFieldProps : {})}
        placeholder={placeholder}
        error={!!fieldErrorMessage || maxFieldLengthError}
        defaultValue={fieldValue}
        disabled={disabled}
        onBlur={handleBlur}
        onChange={handleInputTextChange}
      />
      {!!fieldErrorMessage && (
        <Typography variant="bodyRegular12" sx={{ color: "red.500" }}>
          {fieldErrorMessage}
        </Typography>
      )}
      {!!maxFieldLength && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "4px",
          }}
        >
          <Typography
            variant="bodyRegular12"
            sx={{ color: "red.500", ml: "8px" }}
          >
            {maxFieldLengthError
              ? t("characterLimitExceeded")
              : fieldErrorMessage && t(fieldErrorMessage)}
          </Typography>
          <Typography variant="bodyRegular12" sx={{ color: "grey.700" }}>
            {textFieldLength}/{maxFieldLength}
          </Typography>
        </Box>
      )}
    </QuestionTile>
  );
};
