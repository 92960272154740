import AddCardStrokes from "@applied-ai/root/public/images/addCardStrokes.svg";
import PlusSign from "@applied-ai/root/public/images/plusSign.svg";
import { Box, Typography } from "../../atoms";
import Image from "next/image";

export const AddCard: React.FC<{
  onClick: () => void;
  title: string;
  testId?: string;
}> = ({ onClick, title, testId }) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "grid",
        placeItems: "center",
        backgroundColor: "primary.100",
        borderRadius: "8px",
        cursor: "pointer",

        "& > *": {
          gridArea: "1/1",
        },

        "&:hover": {
          boxShadow: "0px 8px 16px 0px rgba(25, 28, 30, 0.16)",
        },
      }}
      onClick={onClick}
      data-testid={testId}
    >
      <Image
        src={AddCardStrokes}
        fill
        style={{ objectFit: "cover" }}
        alt={"Add card background"}
      />
      <Box
        sx={{ display: "grid", placeItems: "center", gap: "16px", zIndex: 1 }}
      >
        <Image width={72} height={72} src={PlusSign} alt="Plus sign" />
        <Typography variant="h3" sx={{ color: "primary.500" }}>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};
