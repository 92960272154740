import { FormControlLabelProps, alpha } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiRadio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";

type RadioSize = "medium" | "small";

export interface RadioProps
  extends Pick<
    FormControlLabelProps,
    | "checked"
    | "classes"
    | "disabled"
    | "label"
    | "required"
    | "onChange"
    | "sx"
    | "value"
  > {
  error?: boolean;
  size?: RadioSize;
  id?: string;
}

const LABEL_SIZE_STYLES = {
  medium: {
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  small: {
    fontSize: "0.75rem",
    lineHeight: "1rem",
    paddingLeft: "4px",
    paddingRight: "8px",
  },
};

const StyledFormControlLabel = styled(FormControlLabel)<RadioProps>(
  ({ theme, size, disabled }) => {
    const sizeStyle = size ? LABEL_SIZE_STYLES[size] : {};
    const hoverStyle = disabled
      ? {}
      : {
          "&:hover .MuiRadio-root": {
            color: theme.palette.primary[600],
          },
        };

    return {
      margin: 0,

      "& .MuiFormControlLabel-label": {
        ...sizeStyle,
        fontWeight: 500,
      },
      ...hoverStyle,

      "&.Mui-error": {
        color: theme.palette.red[500],

        "& .MuiRadio-root": {
          color: theme.palette.red[500],
        },
      },
    };
  }
);

const ICON_SIZE_STYLES = {
  medium: {
    fontSize: "1.125rem",
  },
  small: {
    fontSize: "0.75rem",
  },
};

const RADIO_MEDIUM_PADDING = 3;
const RADIO_SMALL_PADDING = 2;

const RADIO_SIZE_STYLES = {
  medium: {
    padding: `${RADIO_MEDIUM_PADDING}px`,
  },
  small: {
    padding: `${RADIO_SMALL_PADDING}px`,
  },
};

const RADIO_BACKGROUND_PADDING_SIZE = {
  medium: {
    padding: `${RADIO_MEDIUM_PADDING * 2}px`,
  },
  small: {
    padding: `${RADIO_SMALL_PADDING * 2}px`,
  },
};

const Background = styled("div")<{ size?: RadioSize }>`
  background-color: ${(p) => p.theme.palette.grey[100]};
  border-radius: 50%;
  width: 100%;
  height: 100%;
`;

const BackgroundContainer = styled("div")<{ size?: RadioSize }>`
  width: 100%;
  height: 100%;
  ${({ size }) => RADIO_BACKGROUND_PADDING_SIZE[size || "medium"]}
`;

const RelativeContainer = styled("div")`
  display: grid;
  place-items: center;
  position: relative;

  & > * {
    grid-area: 1/1;
  }
`;

const StyledRadio = styled(MuiRadio)(({ theme, size }) => {
  const iconSizeStyle = size ? ICON_SIZE_STYLES[size] : {};
  const radioSizeStyle = size ? RADIO_SIZE_STYLES[size] : {};

  return {
    ...radioSizeStyle,
    color: theme.palette.grey[500],

    "& .MuiSvgIcon-root": {
      ...iconSizeStyle,
    },

    "&.Mui-checked": {
      color: theme.palette.primary[500],
    },

    "&.Mui-disabled": {
      color: theme.palette.grey[300],
    },

    "&:focus .MuiSvgIcon-root[data-testid='RadioButtonUncheckedIcon'], &:focus-within .MuiSvgIcon-root[data-testid='RadioButtonUncheckedIcon']":
      {
        filter: `drop-shadow(0px 0px 1px ${alpha(
          theme.palette.primary[500],
          0.6
        )})`,
      },
  };
});

export const Radio: React.FC<RadioProps> = ({ size = "medium", ...props }) => {
  const Radio =
    props.disabled && !props.checked ? (
      <RelativeContainer>
        <BackgroundContainer size={size}>
          <Background />
        </BackgroundContainer>
        <StyledRadio
          disableFocusRipple
          disableRipple
          disableTouchRipple
          size={size}
          disabled={props.disabled}
        />
      </RelativeContainer>
    ) : (
      <StyledRadio
        disableFocusRipple
        disableRipple
        disableTouchRipple
        size={size}
      />
    );

  return <StyledFormControlLabel control={Radio} size={size} {...props} />;
};
