import { Box, styled } from "../../reexports";

export const StyledHTML = styled(Box)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.palette.grey[700]};
  a {
    color: ${({ theme }) => theme.palette.grey[700]};
  }
  strong {
    font-weight: 500;
  }
  h1 {
    font-size: 1rem;
    color: ${({ theme }) => theme.palette.grey[700]};
    font-weight: 500;
    line-height: 1.5rem;
  }
  h2 {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.palette.grey[700]};
    font-weight: 500;
    line-height: 1.25rem;
  }
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    font-size: 0.875rem;
  }

  ol,
  ul {
    display: flex;
    flex-direction: column;

    li {
      white-space: normal;
    }
  }
`;
