import { useTranslation } from "next-i18next";
import { Button } from "../../atoms";
import { Box, Modal } from "../../reexports";
import { ModalContent } from "./ModalContent";
import { ModalFooter } from "./ModalFooter";
import { ModalHeader } from "./ModalHeader";
import { ModalWrapper } from "./ModalWrapper";

interface ModalWithActionsProps {
  title: string;
  open: boolean;
  dataTestid: string;
  children: React.ReactNode;
  closeModal: () => void;
  onActionButtonClick: () => void;
  actionButtonLabel: string;
}

export function ModalWithActions({
  title,
  open,
  dataTestid,
  children,
  closeModal,
  onActionButtonClick,
  actionButtonLabel,
}: ModalWithActionsProps) {
  const { t } = useTranslation(["common"]);
  return (
    <Modal open={open} data-testid={dataTestid}>
      <ModalWrapper>
        <ModalHeader toggleModal={closeModal}>{title}</ModalHeader>
        <ModalContent>{children}</ModalContent>
        <ModalFooter>
          {/* TODO: Handle disable for cancel & action button */}
          <Box sx={{ display: "flex", gap: 2, justifyContent: "end" }}>
            <Button
              variant="text"
              onClick={closeModal}
              data-testid="cancel-button"
            >
              {t("cancel")}
            </Button>
            <Button onClick={onActionButtonClick} data-testid="action-button">
              {actionButtonLabel}
            </Button>
          </Box>
        </ModalFooter>
      </ModalWrapper>
    </Modal>
  );
}
