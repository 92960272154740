import { alpha } from "@mui/material";
import { Box, styled } from "../../reexports";

export const StyledEditorWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "canEdit" && prop !== "isValueEmpty" && prop !== "isActive",
})<{
  canEdit?: boolean;
  isActive?: boolean;
  isValueEmpty?: boolean;
}>`
  display: block;

  * {
    /* GLOBAL FONT FAMILY */
    font-family: Work Sans Variable, sans-serif !important;
  }

  .sun-editor {
    border-radius: 4px;

    .se-wrapper {
      overflow: visible;
    }

    .se-toolbar {
      border-radius: 4px 4px 0 0;

      /* TOOLBAR BUTTONS TRAY */
      .se-btn-tray {
        display: flex;
        align-items: center;
        padding: 8px 12px !important;
        background-color: ${(props) => props.theme.palette.grey[100]};

        border: ${(props) => `1px solid ${props.theme.palette.grey[100]}`};

        border-bottom: ${(props) =>
          !props.isActive ? `1px solid ${props.theme.palette.grey[100]}` : "0"};

        /* TOOLBAR BUTTONS */
        .se-btn {
          width: 24px !important;
          height: 24px !important;
          color: ${(props) => props.theme.palette.grey[700]};
          margin-inline: 4px !important;
          margin-block: 0 !important;

          &:hover {
            box-shadow: 0px 0px 4px 0px rgba(0, 116, 123, 0.6);
            background-color: ${(props) =>
              props.theme.palette.common.white} !important;
          }

          &:disabled {
            color: ${(props) => props.theme.palette.grey[500]} !important;
            box-shadow: none !important;
          }
        }

        /* ACTIVE CLASS FOR BUTTONS */
        .active {
          background: ${(props) => props.theme.palette.grey[200]} !important;

          svg {
            color: ${(props) => props.theme.palette.grey[900]} !important;
          }
        }

        /* TEXT FORMATS DROPDOWN */

        .se-btn-select {
          display: flex;
          align-items: center;
          width: 100%;
          min-width: 100px !important;
          height: 34px !important;

          .txt {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: ${(props) => props.theme.palette.grey[900]};
          }

          &:hover {
            background: ${(props) =>
              props.theme.palette.primary[50]} !important;
          }
        }

        /* DEFAULT TOOLBAR BUTTONS BORDER */
        .se-btn-module-border {
          border: none;
        }

        /* TOOLBAR BUTTONS SEPARATOR */
        .se-toolbar-separator-vertical {
          width: 1px;
          height: 16px;
          background: ${(props) => props.theme.palette.grey[200]};
        }
      }

      /* DROPDOWN LIST */
      .se-list-layer {
        border-radius: 4px;
        border: 1px solid ${(props) => props.theme.palette.grey[100]};

        background-color: ${(props) => props.theme.palette.common.white};

        box-shadow: 0px 4px 6px 0px rgba(25, 28, 30, 0.12);
        padding: 0;
      }

      /* TOOLTIP CONTENT */
      .se-tooltip .se-tooltip-inner .se-tooltip-text {
        border-radius: 3px;
        background: ${(props) => props.theme.palette.grey[700]};
        color: ${(props) => props.theme.palette.common.white};
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
      }
    }

    /* BORDER ON BOTTOM BAR */
    .se-resizing-bar {
      border: ${(props) =>
        !props.isActive
          ? `1px solid ${props.theme.palette.grey[100]}`
          : !props.canEdit
          ? "0"
          : `1px solid ${props.theme.palette.primary[500]}`};
      border-radius: 0px 0px 4px 4px;
      box-shadow: ${({ theme, isActive }) =>
        isActive &&
        `0px 0px 4px 0px ${alpha(theme.palette.primary[500], 0.6)}`};
    }

    /* EDITABLE MAIN CLASS */
    .sun-editor-editable {
      /* BORDER ON MAIN EDITABLE */
      margin-top: 1px;
      box-shadow: ${({ theme, isActive }) =>
        isActive &&
        `0px 0px 4px 0px ${alpha(theme.palette.primary[500], 0.6)}`};
      border: ${(props) =>
        !props.isActive
          ? `1px solid ${props.theme.palette.grey[100]}`
          : !props.canEdit
          ? "0"
          : `1px solid ${props.theme.palette.primary[500]}`};
      background: ${(props) => !props.canEdit && props.theme.palette.grey[100]};
      border-bottom: 0;

      /* FONT PROPERTIES USED IN EDITABLE */
      h1,
      h2,
      h3,
      p {
        font-weight: 600;
        margin: 0;
        color: ${(props) => props.theme.palette.grey[700]};
      }

      h1 {
        font-size: 24px;
        line-height: 32px;
      }

      h2 {
        font-size: 20px;
        line-height: 28px;
      }

      h3 {
        font-size: 16px;
        line-height: 24px;
      }

      p {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
      }
    }
  }

  /* LINK DIALOG */
  .se-dialog {
    position: absolute !important;

    /* DIALOG SUBMIT BUTTON */
    .se-btn-primary {
      background: ${(props) => props.theme.palette.primary[500]};
      color: ${(props) => props.theme.palette.common.white} !important;
      border: 1px solid ${(props) => props.theme.palette.primary[500]};
      font-weight: 500 !important;
      border-radius: 4px;
      box-shadow: none !important;

      &:hover,
      &:focus,
      &:active {
        border-radius: 4px !important;
        border: 1px solid 1px solid
          ${(props) => props.theme.palette.primary[600]} !important;
        background: 1px solid ${(props) => props.theme.palette.primary[600]} !important;
      }
    }

    /* MODAL INPUTS */
    input {
      border-radius: 4px !important;
      border: 1px solid ${(props) => props.theme.palette.grey[300]} !important;
      background: ${(props) => props.theme.palette.grey[50]} !important;
      padding: 8px 8px 8px 12px !important;
      &:hover {
        border-radius: 4px !important;
        border: 1px solid ${(props) => props.theme.palette.primary[500]} !important;
        background: ${(props) => props.theme.palette.grey[50]} !important;
      }

      &:focus {
        outline: 0;
        transition: none;
        box-shadow: none;
      }
    }

    /* DIALOG LABEL FONT */
    .se-dialog-content label {
      font-weight: 500 !important;
    }

    /* HIDE UNNECESSARY BOOKMARK BUTTON */
    ._se_bookmark_button {
      display: none;
    }

    /* HIDE UNNECESSARY OPTIONS */
    .se-dialog-form-footer {
      display: none;
    }
  }
`;
