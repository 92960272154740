import MuiBadge from "@mui/material/Badge";
import { styled, BadgeProps as MuiBadgeProps } from "@mui/material";

const StyledBadge = styled(MuiBadge)`
  & .MuiBadge-badge {
    min-width: 20px;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    padding: 2px 6px;
    border-radius: 16px;
  }
  ${(p) =>
    !p.color &&
    `& .MuiBadge-badge {
        background-color: ${p.theme.palette.grey[200]};
        color: ${p.theme.palette.grey[700]};
      }
    `};
`;

export const Badge: React.FC<MuiBadgeProps> = ({ ...props }) => {
  return <StyledBadge {...props} />;
};
