import React from "react";
import { Box } from "../../reexports";

export const FormViewModeWrapper: React.FC<{
  viewMode: boolean;
  children: React.ReactNode;
}> = ({ viewMode, children }) => (
  <Box
    sx={{
      ".MuiInputBase-root.Mui-disabled": {
        backgroundColor: viewMode ? undefined : "unset !important",
        pointerEvents: "none",
      },
    }}
  >
    {children}
  </Box>
);
