import MoreVert from "@mui/icons-material/MoreVert";
import { Box, Button, Typography } from "../../atoms";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { DATE_FORMAT, LOCALES } from "@applied-ai/utils";
import { renderUsersAvatar } from "../../utils/renderUsersAvatar";
import { useState } from "react";
import { styled } from "@mui/material";
import { EllipsisWithTooltip } from "../EllipsisWithTooltip/EllipsisWithTooltip";
import { Divider, Menu, MenuItem } from "../../reexports";

// !TODO https://appliedaide.atlassian.net/browse/UCTSX-829 Upgrade line-clamp Implementation for better support
export interface VerticalMenuOptions {
  label: string;
  onClick: (id: string) => void;
  disabled?: boolean;
}

interface CardProps {
  createdAt: string;
  title: string;
  users: string[];
  badge?: React.ReactNode;
  onCardClick?: () => void;
  footer?: React.ReactNode;
  options?: VerticalMenuOptions[];
  idToDelete?: string;
}

const CardWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;

  position: relative;
  padding: 24px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: solid 1px ${({ theme }) => theme.palette.grey[200]};
  border-radius: 8px;
  transition: box-shadow 0.1s ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 8px 16px 0px rgba(25, 28, 30, 0.16);
  }
`;

export const Card: React.FC<CardProps> = ({
  options,
  createdAt,
  title,
  users,
  badge,
  footer,
  onCardClick,
  idToDelete,
}) => {
  const router = useRouter();
  const [anchorElMoreButton, setAnchorElMoreButton] =
    useState<null | HTMLElement>(null);
  const { t } = useTranslation(["common", "ucts"]);

  const handleOpenMoreMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAnchorElMoreButton(e.currentTarget);
  };

  const handleCloseMoreMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAnchorElMoreButton(null);
  };

  return (
    <CardWrapper onClick={onCardClick} data-testid="card">
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Typography variant="bodyRegular12">
          {t("common:createdOn", {
            creationDate: new Date(createdAt).toLocaleDateString(
              LOCALES[router.locale as string],
              DATE_FORMAT
            ),
          })}
        </Typography>
        {!!options?.length && (
          <>
            <Button
              variant="text"
              sx={{
                position: "absolute",
                top: "14px",
                right: "14px",
                padding: "8px",
                color: "inherit",
              }}
              onClick={handleOpenMoreMenu}
              data-testid="card-menu-button"
            >
              <MoreVert
                sx={{ height: "20px", width: "20px", color: "grey.700" }}
              />
            </Button>
            <Menu
              anchorEl={anchorElMoreButton}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={!!anchorElMoreButton}
              onClose={handleCloseMoreMenu}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {options?.map((item) => {
                return (
                  <MenuItem
                    onClick={(e) => {
                      item.onClick(idToDelete || "");
                      handleCloseMoreMenu(e);
                    }}
                    disabled={item.disabled}
                    key={item.label}
                  >
                    <Typography
                      sx={{ display: "flex", alignItems: "center" }}
                      variant="bodyMedium14"
                    >
                      {item.label}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Menu>
          </>
        )}
        <Typography variant="h3" sx={{ margin: "18px 0 20px 0" }}>
          <EllipsisWithTooltip clamp={2} breakWord={false}>
            {title}
          </EllipsisWithTooltip>
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "8px",
        }}
        data-testid="use-case-owner-and-status"
      >
        <Box sx={{ maxWidth: "240px" }}>
          {renderUsersAvatar({ users: users })}
        </Box>
        {badge}
      </Box>

      {!!footer && (
        <Box sx={{ marginTop: "24px" }} data-testid="use-case-stage">
          <Divider />
          {footer}
        </Box>
      )}
    </CardWrapper>
  );
};
