import { isObject } from "./isObject";

export const isEqual = <T>(value: T, other: T): boolean => {
  if (value === other) {
    return true;
  }

  if (isObject(value) && isObject(other)) {
    const keysA = Object.keys(value);
    const keysB = Object.keys(other);

    if (keysA.length !== keysB.length) {
      return false;
    }

    for (const key of keysA) {
      if (!keysB.includes(key) || !isEqual(value[key], other[key])) {
        return false;
      }
    }

    return true;
  }

  return false;
};
