export const PORTFOLIO_NAV_LINKS = [
  {
    label: "ucts:portfolio.cockpit",
    value: "/portfolio",
  },
  {
    label: "ucts:portfolio.exploration",
    value: "/portfolio/exploration",
  },
  {
    label: "ucts:portfolio.assessmentMatrix",
    value: "/portfolio/assessment-matrix",
  },
];
