import { QuestionTableColumnProps, QuestionTableRowProps } from "./types";

export const getDefaultValues = (columns: QuestionTableColumnProps[]) =>
  columns.reduce((acc, crr) => {
    acc[crr.id] = "";
    return acc;
  }, {} as Record<string, string>);

export const parseEditValues = (editData: QuestionTableRowProps | null) =>
  editData?.values?.reduce((acc, currentValue) => {
    acc[`${currentValue["column_id"]}`] = currentValue["value"] ?? "";
    return acc;
  }, {} as Record<string, string>);

export const parseColumnsAndValuesIds = (editData: QuestionTableRowProps) =>
  editData.values.reduce((acc, currentCell) => {
    acc[currentCell.column_id] = currentCell.value_id;
    return acc;
  }, {} as Record<string, string>);
