import {
  Box,
  HiddenIfNoPermission,
  Link,
  Typography,
  styled,
} from "@applied-ai/components";
import { usePermissions } from "@applied-ai/hooks";
import { AppsConfigPayload, Page } from "@applied-ai/types";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

interface Props {
  pages: Page[];
  config?: AppsConfigPayload;
}

const Highlight = styled(Box)`
  position: absolute;
  bottom: 0;
  border-bottom: 3px solid ${({ theme }) => theme.palette.accent.orange};
  width: 100%;
`;

export const NavigationTabs = ({ pages, config }: Props) => {
  const { t } = useTranslation(["common", "ucts"]);
  const { permissions } = usePermissions();
  const router = useRouter();

  const parseConfigNavLinks = (config?: AppsConfigPayload) => {
    if (config?.ucts && "nav_links" in config.ucts) {
      const parsedNavLinks = config.ucts.nav_links
        .filter((item) => item.enabled)
        .map((item) => ({
          id: item.name,
          href: item.url,
          label: `ucts:${item.name}`,
          newTab: true,
          requiredPermission: "",
          locale: undefined,
        }));

      return parsedNavLinks;
    }
    return [];
  };

  const checkIfPageIsCurrent = (page: Page) => {
    if (page.pathRegex) {
      return new RegExp(page.pathRegex).test(router.asPath);
    }
    return router.asPath === page.href;
  };

  return (
    <Box
      role="navigation"
      sx={{
        flexGrow: 1,
        display: "flex",
        ml: "109px",
        alignSelf: "stretch",
        gap: "8px",
      }}
      data-testid="navbar-link-container"
    >
      {[...pages, ...parseConfigNavLinks(config)].map((page) => (
        <HiddenIfNoPermission
          requiredPermission={page.requiredPermission}
          grantedPermissions={permissions}
          key={page.href}
        >
          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& a:hover": {
                backgroundColor: "primary.600",
              },
            }}
            data-testid={`navbar-link-${page.id}`}
          >
            <Link
              href={page.href}
              locale={page.locale}
              size="large"
              target={page.newTab ? "_blank" : "_self"}
              sx={{
                color: "common.white",
                height: "100%",
                display: "flex",
                alignItems: "center",
                px: 1,

                "&:hover, &:visited, &:active, &:focus": {
                  color: "common.white",
                  textDecoration: "none",
                },
              }}
            >
              <Typography variant="bodyMedium14" sx={{ color: "common.white" }}>
                {t(page.label)}
              </Typography>
            </Link>
            {checkIfPageIsCurrent(page) && (
              <Highlight data-testid="navbar-link-highlight" />
            )}
          </Box>
        </HiddenIfNoPermission>
      ))}
    </Box>
  );
};
