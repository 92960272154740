import { ToastVariants, useToastStore } from "../../store/toastStore";
import { Alert, Typography } from "../../atoms";
import { Box, Snackbar, useTheme } from "../../reexports";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AlertColor } from "@mui/material";
import ProgressBar from "../ProgressBar/ProgressBar";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";

interface ToastManagerProps {
  autoHideDuration?: number;
}

const DEFAULT_TIME_OFFSET = 10000;
const SNACKBAR_OFFSET = 500;

export const ToastManager = ({
  autoHideDuration = DEFAULT_TIME_OFFSET,
}: ToastManagerProps) => {
  const { removeToast, toasts } = useToastStore();
  const theme = useTheme();

  const handleClose = (id: string) => {
    removeToast(id);
  };

  const getAlertStyles = (type: AlertColor) => {
    switch (type) {
      case ToastVariants.ERROR:
        return {
          backgroundColor: theme.palette.red[100],
          textColor: theme.palette.grey[900],
          icon: <InfoOutlinedIcon />,
          iconColor: theme.palette.grey[900],
          hideCloseIcon: null,
          borderColor: theme.palette.red[500],
        };
      case ToastVariants.INFO:
        return {
          backgroundColor: theme.palette.grey[800],
          textColor: theme.palette.common.white,
          icon: null,
          iconColor: theme.palette.common.white,
          hideCloseIcon: false,
        };
      case ToastVariants.SUCCESS:
        return {
          backgroundColor: theme.palette.green[100],
          textColor: theme.palette.grey[900],
          icon: <CheckCircleOutline fontSize="small" />,
          iconColor: theme.palette.grey[900],
          hideCloseIcon: false,
          borderColor: theme.palette.green[500],
          minWidth: "400px",
        };
      default:
        return {
          borderColor: theme.palette.grey[200],
          backgroundColor: theme.palette.grey[50],
          borderRadius: "8px",
          textColor: theme.palette.common.black,
          icon: null,
          hideCloseIcon: null,
        };
    }
  };

  return (
    <div>
      {toasts.map((toast) => {
        const isCloseIconHidden =
          toast.hideCloseIcon || getAlertStyles(toast.type).hideCloseIcon;
        return (
          <Snackbar
            key={toast.id}
            anchorOrigin={toast.position}
            open={true}
            autoHideDuration={
              autoHideDuration + (toast.toastHideOffset || SNACKBAR_OFFSET)
            }
            onClose={() => {
              toast.closeCallback?.();
              handleClose(toast.id);
            }}
            ClickAwayListenerProps={{ onClickAway: () => null }}
            sx={{
              zIndex: theme.customZIndex.toast,
              padding: 0,
              maxWidth: "620px",
            }}
          >
            <div>
              <Alert
                variant="filled"
                style={getAlertStyles(toast.type)}
                onClose={() => {
                  toast.closeCallback?.();
                  handleClose(toast.id);
                }}
                severity={toast.type}
                icon={getAlertStyles(toast.type).icon || false}
                sx={{
                  width: "100%",
                  zIndex: theme.customZIndex.toast,
                  padding: "14px 16px",
                  borderRadius: "4px",
                  borderBottomLeftRadius: toast.callbackAction ? 0 : "4px",
                  borderBottomRightRadius: toast.callbackAction ? 0 : "4px",

                  "& .MuiAlert-icon": {
                    p: 0,
                  },
                  "& .MuiSvgIcon-root": {
                    color: getAlertStyles(toast.type).iconColor,
                    width: "20px",
                    height: "20px",
                  },
                  ".MuiIconButton-root": {
                    padding: "0 5px 0",
                    position: "relative",
                    bottom: "1px",
                  },

                  "& .MuiAlert-action": {
                    padding: 0,
                    display: isCloseIconHidden ? "none" : "block",
                  },
                  "& .MuiAlert-message": {
                    padding: 0,
                    display: "flex",
                    overflow: "hidden",

                    flex: 2,
                  },
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="bodyRegular14"
                    style={{
                      color: getAlertStyles(toast.type).textColor,
                      cursor: "default",
                    }}
                  >
                    {toast.message}
                  </Typography>

                  {toast.callbackAction && (
                    <Typography
                      variant="bodyMediumUnderline14"
                      sx={{
                        color: theme.palette.common.white,
                        cursor: "pointer",
                        paddingInline: "40px 8px",
                        textUnderlineOffset: "2px",
                      }}
                      onClick={() => {
                        handleClose(toast.id);
                        toast.callbackAction?.();
                      }}
                    >
                      {toast.actionLabel}
                    </Typography>
                  )}
                </Box>
              </Alert>
              {toast.callbackAction && (
                <ProgressBar duration={autoHideDuration} />
              )}
            </div>
          </Snackbar>
        );
      })}
    </div>
  );
};
