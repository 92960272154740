import MuiTab from "@mui/material/Tab";
import { TabProps, styled } from "@mui/material";
import { getBorderShadow } from "../../utils/getBorderShadow";

const StyledTab = styled(MuiTab)`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${(p) => p.theme.palette.grey[700]};

  &:hover {
    color: ${(p) => p.theme.palette.primary[600]};
    box-shadow: ${(p) =>
      getBorderShadow(p.theme.palette.grey[200], "bottom", 2)};
  }
`;

export const Tab: React.FC<TabProps> = (props) => {
  return <StyledTab {...props} disableFocusRipple disableRipple />;
};
