export type ChangeFilterFunc = (key: string, value: string | boolean) => void;
type SetSelectedOptions = (fn: (value: string[]) => string[]) => void;

const selectOption = (
  newOption: string,
  setSelectedOptions: SetSelectedOptions
) => {
  setSelectedOptions((prev) => {
    const newSelected = [...prev, newOption];
    return newSelected;
  });
};

const removeOption = (
  value: string,
  setSelectedOptions: SetSelectedOptions
) => {
  setSelectedOptions((prev) => {
    const newSelected = prev.filter((selectedValue) => selectedValue !== value);
    return newSelected;
  });
};

/** Handles checkbox change event. Updates selected options and calls changeFilter function wit updated value. */
export const handleCheckboxChange = (
  newValue: string,
  isChecked: boolean,
  setSelectedOptions: SetSelectedOptions
): void =>
  isChecked
    ? selectOption(newValue, setSelectedOptions)
    : removeOption(newValue, setSelectedOptions);
