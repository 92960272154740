import { useTranslation } from "next-i18next";
import Image from "next/image";
import { Typography } from "../../atoms";
import { Box, Divider, useTheme } from "../../reexports";
import ContactIcon from "../../../public/images/contact-icon.svg";

interface ProfileComponentProps {
  userName: string;
  userEmail: string;
  roles: string[];
}

export function ProfileComponent({
  userName,
  userEmail,
  roles,
}: ProfileComponentProps) {
  const theme = useTheme();
  const { t } = useTranslation("common");

  const profileFields = [
    {
      title: t("name"),
      value: userName,
    },
    {
      title: t("email"),
      value: userEmail,
    },
    {
      title: t("role"),
      value: roles.join(", "),
    },
  ];

  const flexColumn = {
    display: "flex",
    flexDirection: "column",
  };

  return (
    <Box sx={{ ...flexColumn, gap: "8px" }}>
      <Box
        sx={{
          ...flexColumn,
          padding: "24px",
          alignItems: "flex-start",
          gap: "16px",
          backgroundColor: theme.palette.common.white,
          borderRadius: "8px",
          border: `1px solid ${theme.palette.grey[200]}`,
        }}
      >
        <Typography variant="bodyMedium16">{t("details")}</Typography>
        <Divider flexItem />

        {profileFields.map((field) => {
          return (
            <Box
              sx={{ ...flexColumn, gap: "8px", width: "100%" }}
              key={field.title}
            >
              <Typography variant="bodyRegular14">{field.title}</Typography>
              <Typography
                variant="bodyMedium14"
                sx={{
                  wordWrap: "break-word",
                }}
              >
                {field.value}
              </Typography>
            </Box>
          );
        })}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
          padding: "16px 24px",
          backgroundColor: theme.palette.common.white,
          borderRadius: "8px",
          border: `1px solid ${theme.palette.grey[200]}`,
        }}
      >
        <Image
          src={ContactIcon}
          alt={"contact us icon"}
          style={{ justifySelf: "center" }}
        />
        <Box sx={flexColumn}>
          <Typography variant="bodyMedium16">{t("contact")}</Typography>
          <Typography variant="bodyRegular14">
            {t("ifSomethingIsNotRight")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
