import { styled } from "@mui/material";
import { Box } from "../../reexports";

export const TileBase = styled(Box)`
  padding: 24px;
  background-color: ${(p) => p.theme.palette.common.white};
  border: 1px solid ${(p) => p.theme.palette.grey[200]};
  border-radius: 8px;
  :has(.Mui-focused),
  :focus-within {
    border: 1px solid ${(p) => p.theme.palette.grey[900]};
    box-shadow: 0px 8px 16px 0px rgba(25, 28, 30, 0.16);
  }
  :focus {
    outline: none;
  }
`;
