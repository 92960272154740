import { ApiError } from "@applied-ai/types/apiTypes/core/ApiError";
import type { GetServerSideProps, GetServerSidePropsContext } from "next";

/**
 * This function wraps a getServerSideProps function and handles 401 error by redirecting to the auth signin page.
 * @example
 * export const getServerSideProps = withError<ServerSideProps>(
 *   async (context) => {
 *      ...
 *      return ...
 *    }
 * );
 *
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function withError<Props extends { [key: string]: any }>(
  gssp: GetServerSideProps<Props>
) {
  return async (context: GetServerSidePropsContext) => {
    const uctsUrl = `${process.env.HOST}:${process.env.UCTS_PORT}${process.env.NEXT_PUBLIC_UCTS_PATH}`;
    const locale = context.locale || "en";

    try {
      return await gssp(context);
    } catch (e) {
      console.error("[withError.ts]: ERROR", e);
      if (!(e instanceof ApiError)) {
        return {
          redirect: {
            destination: `${uctsUrl}/${locale}/500`,
            permanent: false,
          },
        };
      }

      const requestId = e.requestId;
      const requestIdParam = requestId ? `?requestId=${requestId}` : "";

      switch (e.status) {
        case 404:
          return {
            redirect: {
              destination: `${uctsUrl}/${locale}/404${requestIdParam}`,
              permanent: false,
            },
          };
        case 401: {
          const loginUrl = new URL(`${uctsUrl}/user/login`);
          const locale = context.locale || "en";
          const pathname = context.resolvedUrl;
          const redirectUrl = pathname.startsWith(
            process.env.NEXT_PUBLIC_UCTS_PATH as string
          )
            ? context.resolvedUrl
            : `${process.env["NEXT_PUBLIC_UCTS_PATH"]}${encodeURI(
                `/${locale === "default" ? "en" : locale}${pathname}`
              )}`;
          loginUrl.searchParams.append("redirectUrl", redirectUrl);

          console.info("[withError.ts]: Redirecting to", loginUrl.toString());
          return {
            redirect: {
              destination: loginUrl.toString(),
              permanent: true,
            },
          };
        }
        case 403:
          return {
            redirect: {
              destination: `${uctsUrl}/${locale}/403${requestIdParam}`,
              permanent: false,
            },
          };
        default:
          return {
            redirect: {
              destination: `${uctsUrl}/${locale}/500${requestIdParam}`,
              permanent: false,
            },
          };
      }
    }
  };
}
