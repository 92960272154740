import { styled, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "next-i18next";
import { Link } from "../../atoms";

interface Props {
  tabs: { value: string; label: string }[];
  value: string;
  onChange?: (value: string) => void;
  asLink?: boolean;
}

const StyledTabButton = styled("button")`
  padding: 8px 4px;
  background-color: transparent;
  text-align: center;

  font-size: 0.875rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 1.25rem;

  color: ${({ theme }) => theme.palette.grey[700]};
  border: none;
  cursor: pointer;
  outline-color: ${({ theme }) => theme.palette.primary[400]};

  &:disabled {
    color: ${({ theme }) => theme.palette.primary[500]};
    border-bottom: 2px solid ${({ theme }) => theme.palette.primary[500]};
    pointer-events: none;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.primary[500]};
  }
`;

export function TabsBar({ tabs, value, onChange, asLink }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        display: "flex",
        gap: "12px",
      }}
    >
      {tabs?.map((tab, index) => {
        return asLink ? (
          <Link key={`tab${index}-${value}`} href={tab.value} underline="none">
            <StyledTabButton
              theme={theme}
              disabled={tab.value === value}
              key={`bar${index}-${value}`}
            >
              {t(tab.label)}
            </StyledTabButton>
          </Link>
        ) : (
          <StyledTabButton
            theme={theme}
            onClick={(e) => {
              onChange?.(tab.value);
            }}
            disabled={tab.value === value}
            key={`bar${index}-${value}`}
          >
            {t(tab.label)}
          </StyledTabButton>
        );
      })}
    </Box>
  );
}
