import { ReactNode } from "react";
import { Box, Typography } from "../../atoms";

interface SummaryTileProps {
  titleText: string;
  titleIcon?: ReactNode;
  middleContentText?: string | ReactNode;
  middleContentIcon?: ReactNode;
  bottomContentText?: string;
  bottomContent?: ReactNode;
  onClick?: () => void;
}

export const SummaryTile: React.FC<SummaryTileProps> = ({
  titleText,
  titleIcon,
  middleContentText,
  middleContentIcon,
  bottomContent,
  bottomContentText,
  onClick,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "18px 16px",
        backgroundColor: "grey.50",
        border: `1px solid`,
        borderColor: "grey.200",
        borderRadius: "8px",
        gap: "8px",
        cursor: onClick ? "pointer" : "auto",
        wordWrap: "break-word",
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="bodyRegular14"
          sx={{
            color: "grey.600",
            wordWrap: "break-word",
          }}
          data-testid="summary-tile-date"
        >
          {titleText}
        </Typography>
        {titleIcon}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "8px",
        }}
      >
        {middleContentIcon}
        <Typography
          variant="bodyMedium16"
          sx={{
            width: "100%",
            color: "grey.900",
          }}
          data-testid="summary-tile-title"
        >
          {middleContentText}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
        data-testid="summary-tile-bottom-content"
      >
        {bottomContent}
        <Typography
          variant="bodyRegular12"
          sx={{
            paddingTop: "4px",
            color: "gray.600",
          }}
        >
          {bottomContentText}
        </Typography>
      </Box>
    </Box>
  );
};
