/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum UseCaseStatusStyle {
  LIGHT_GREEN = "light-green",
  DARK_GREY = "dark-grey",
  WHITE = "white",
}
