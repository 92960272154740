import { ROLES, UserDto, UserWithRole } from "@applied-ai/types";
import { useState } from "react";

interface InviteUsersHook<User> {
  assignedUsers: User[] | UserDto[];
  setAssignedUsers: (users: User[] | UserDto[]) => void;
  assignedUsersIds: string[];
  handleInviteUsersClick: () => void;
  handleAssignUsersClick: (
    option: UserDto,
    state: { selected?: boolean }
  ) => (e: React.SyntheticEvent) => void;
}

export function useInviteUsers<User extends UserWithRole>(
  role: ROLES,
  users: User[],
  setUsers: (users: User[]) => void,
  addUsers: (users: string[], role: ROLES, successCallback: () => void) => void
): InviteUsersHook<User> {
  const [assignedUsers, setAssignedUsers] = useState<User[] | UserDto[]>([]);
  const handleInviteUsersClick = () => {
    const existingUsersWithSelectedRole = users.filter(
      (user) => user.role === role
    );
    const allUserIdsWithSelectedRole = [
      ...existingUsersWithSelectedRole,
      ...assignedUsers,
    ].map((user) => user.id);

    const newUsersWithSelectedRole = assignedUsers.map(
      (user) =>
        ({
          ...user,
          role: role,
        } as User)
    );

    const addAssignedUsers = () =>
      setUsers([...users, ...newUsersWithSelectedRole]);

    addUsers(allUserIdsWithSelectedRole, role, addAssignedUsers);

    setAssignedUsers([]);
  };

  const handleAssignUsersClick = (
    option: UserDto,
    state: { selected?: boolean }
  ) => {
    return (e: React.SyntheticEvent) => {
      const newSelected = state.selected
        ? assignedUsers.filter((user) => user.id !== option.id)
        : [...assignedUsers, option];
      setAssignedUsers(newSelected);
    };
  };

  const assignedUsersIds = assignedUsers.map((user) => user.id);

  return {
    assignedUsers,
    assignedUsersIds,
    handleInviteUsersClick,
    setAssignedUsers,
    handleAssignUsersClick,
  };
}
