import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Check from "@mui/icons-material/Check";
import LinkOutlined from "@mui/icons-material/LinkOutlined";
import LockOpen from "@mui/icons-material/LockOpen";
import LockOutlined from "@mui/icons-material/LockOutlined";
import { useTranslation } from "next-i18next";
import React, { useState } from "react";
import { Button, TextField, Typography } from "../../atoms";
import { Box, MenuItem, Modal, useTheme } from "../../reexports";
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalWrapper,
} from "../ModalComponents";

interface ShareModalProps {
  isOpen: boolean;
  title: string;
  canChangeVisibility: boolean;
  isVisible: boolean;
  close: () => void;
  onCopyLinkClick: () => void;
  children: React.ReactNode;
  changeAccess?: (access: string, successCallback: () => void) => void;
  isChangingVisibilityEnabled?: boolean;
  isPublic?: boolean;
}

const accessIconStyle = { width: "16px", height: "16px" };

export const ShareModal = ({
  isOpen,
  title,
  canChangeVisibility,
  isChangingVisibilityEnabled = false,
  isVisible,
  onCopyLinkClick,
  children,
  close,
  isPublic,
  changeAccess,
}: ShareModalProps) => {
  const { t } = useTranslation("common");
  const [access, setAccess] = useState(isPublic ? "public" : "private");
  const theme = useTheme();

  const ACCESSES = [
    {
      value: "private",
      label: t("private"),
      description: t("privateAccessDescription"),
      icon: <LockOutlined sx={accessIconStyle} />,
    },
    {
      value: "public",
      label: t("public"),
      description: t("publicAccessDescription"),
      icon: <LockOpen sx={accessIconStyle} />,
    },
  ];

  const selectedAccess = ACCESSES.find(
    (accessObj) => accessObj.value === access
  );

  return (
    <Modal
      open={isOpen}
      onClose={close}
      aria-labelledby="modal-share-use-case"
      sx={{ visibility: isVisible ? "visible" : "hidden" }}
    >
      <ModalWrapper>
        <ModalHeader toggleModal={close}>{title}</ModalHeader>
        <ModalContent>{children}</ModalContent>
        <ModalFooter>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {!!changeAccess && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",

                  "& label": {
                    width: "fit-content",

                    "&:focus-within": {
                      boxShadow: "0px 0px 4px 0px rgba(0, 116, 123, 0.60)",
                      borderRadius: "4px",
                    },
                  },
                }}
              >
                {isChangingVisibilityEnabled && (
                  <TextField
                    disabled={!canChangeVisibility}
                    select
                    value={access}
                    onChange={(e) =>
                      changeAccess?.(e.target.value, () =>
                        setAccess(e.target.value)
                      )
                    }
                    data-testid="share-modal-public-private-button"
                    labelSx={{ padding: 0 }}
                    SelectProps={{
                      renderValue: (value) => (
                        <Typography variant="bodyMedium14">
                          {
                            ACCESSES.find((access) => access.value === value)
                              ?.label
                          }
                        </Typography>
                      ),
                      IconComponent: ArrowDropDownIcon,
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "32px",
                        background: theme.palette.common.white,
                        border: "0 !important",
                        boxShadow: "none !important",

                        "&:hover": {
                          background: theme.palette.primary[50],
                        },
                      },
                    }}
                  >
                    {ACCESSES.map((accessObj) => (
                      <MenuItem
                        key={accessObj.value}
                        value={accessObj.value}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography variant="bodyMedium14">
                            {accessObj.label}
                          </Typography>
                          <Typography
                            variant="bodyMedium12"
                            sx={{
                              fontWeight: 400,
                              color: "grey.700",
                            }}
                          >
                            {accessObj.description}
                          </Typography>
                        </Box>
                        {access === accessObj.value && (
                          <Check sx={{ marginLeft: "auto" }} />
                        )}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    ml: "4px",
                  }}
                  variant="bodyRegular12"
                  data-testid="share-modal-public-private-info"
                >
                  {selectedAccess?.icon}
                  {selectedAccess?.description}
                </Typography>
              </Box>
            )}
            <Button
              variant="text"
              onClick={onCopyLinkClick}
              startIcon={<LinkOutlined />}
              sx={{ ml: "auto" }}
              data-testid="share-modal-copy-link-button"
            >
              {t("copyLink")}
            </Button>
          </Box>
        </ModalFooter>
      </ModalWrapper>
    </Modal>
  );
};
