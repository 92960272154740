import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Divider,
  EllipsisWithTooltip,
  Link,
  Menu,
  MenuItem,
  Spinner,
  Typography,
} from "@applied-ai/components";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { signOut } from "next-auth/react";
import { useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Session } from "next-auth";

export const ProfileMenu = ({ user }: { user: Session["user"] }) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(["common"]);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // !TODO This will no longer be needed once the FE is fully merged, then we will be able to bring back Link

  // <Link
  //  href={router.asPath !== "/my-profile" ? "/my-profile" : ""}
  //  size="large"
  //  style={{ textDecoration: "none" }}
  // >

  const navigateToMyProfile = () => {
    if (router.basePath === process.env.NEXT_PUBLIC_UCTS_PATH) {
      router.push("/my-profile");
      return;
    }

    router.push(
      `${process.env.NEXT_PUBLIC_UCTS_PATH}/${router.locale}/my-profile`,
      undefined,
      {
        locale: false,
      }
    );
  };

  return (
    <>
      <Box sx={{ flexGrow: 0 }}>
        <Button
          onClick={handleOpenUserMenu}
          sx={{
            maxWidth: "280px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            padding: "4px",
          }}
        >
          <Avatar
            alt={user?.name || "user avatar"}
            src={user?.picture || ""}
            onClick={handleOpenUserMenu}
            sx={{ width: "28px", height: "28px" }}
          />
          <EllipsisWithTooltip>{user?.name || ""}</EllipsisWithTooltip>
        </Button>
        <Menu
          sx={{
            mt: "9px",
            "& .MuiMenu-list": {
              display: "flex",
              flexDirection: "column",
              width: "240px",
              gap: "4px",
            },

            "& .MuiDivider-root": {
              margin: 0,
            },
          }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={!!anchorElUser}
          onClose={handleCloseUserMenu}
        >
          <Link
            href="/my-profile"
            underline="none"
            sx={{ textDecoration: "none" }}
          >
            <MenuItem onClick={() => navigateToMyProfile()}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "grey.900",
                }}
                variant="bodyRegular14"
              >
                <AccountCircleOutlinedIcon sx={{ mr: "8px" }} />
                {t("common:myProfile")}
              </Typography>
            </MenuItem>
          </Link>

          <Divider sx={{ color: "grey.100" }} />

          <MenuItem
            onClick={async () => {
              setIsLoading(true);

              const url = new URL(
                `${process.env.NEXT_PUBLIC_ZITADEL_ISSUER}/oidc/v1/end_session`
              );

              url.searchParams.append(
                "client_id",
                process.env.NEXT_PUBLIC_ZITADEL_CLIENT_ID || ""
              );
              url.searchParams.append(
                "post_logout_redirect_uri",
                window.location.origin
              );

              await signOut({ redirect: true, callbackUrl: url.toString() });
            }}
          >
            <Typography
              sx={{ display: "flex", alignItems: "center", color: "grey.900" }}
              variant="bodyRegular14"
            >
              <LogoutOutlined sx={{ mr: "8px" }} /> {t("common:logout")}
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
      <Backdrop open={isLoading}>
        <Spinner color="secondary" size="large" />
      </Backdrop>
    </>
  );
};
