import { ReactNode } from "react";
import { Box, SxProps } from "../../reexports";
import Image from "next/image";
import { Button, HiddenIfNoPermission, Typography } from "../../atoms";
import { Theme } from "@mui/material/styles/createTheme";
import { TypographyPropsVariantOverrides } from "@mui/material";
import { usePermissions } from "@applied-ai/hooks";

type Size = "large" | "small";

type CardStyles = {
  [key in Size]: {
    image: {
      defaultHeight: number;
    };
    title: {
      variant: keyof TypographyPropsVariantOverrides;
      sx: SxProps<Theme>;
    };
    description: {
      variant: keyof TypographyPropsVariantOverrides;
      sx: SxProps<Theme>;
    };
    actionButton: { sx: SxProps<Theme> };
  };
};

interface Props {
  title: string | ReactNode;
  size: Size;
  image: {
    src: string;
    alt: string;
    width?: number;
    height?: number;
  };
  actionButton?: {
    label: string;
    action: () => void;
    disabled?: boolean;
    icon?: ReactNode;
    requiredPermission?: string;
  };
  testId?: string;
  testButtonId?: string;
  description?: string | ReactNode;
  sx?: SxProps<Theme>;
}

const cardStyles: CardStyles = {
  large: {
    image: { defaultHeight: 157 },
    title: { variant: "h2", sx: { marginTop: "32px" } },
    description: { variant: "bodyRegular16", sx: { marginTop: "20px" } },
    actionButton: { sx: { marginTop: "36px" } },
  },
  small: {
    image: { defaultHeight: 80 },
    title: { variant: "h4", sx: { marginTop: "24px" } },
    description: { variant: "bodyRegular14", sx: { marginTop: "8px" } },
    actionButton: { sx: { marginTop: "24px" } },
  },
};

export const MessageCard = ({
  title,
  description,
  size,
  image,
  actionButton,
  testId,
  testButtonId,
  sx,
}: Props) => {
  const styles = cardStyles[size];
  const { permissions } = usePermissions();

  return (
    <Box
      sx={{ display: "grid", placeItems: "center", ...sx }}
      data-testid={testId}
    >
      <Image
        width={0}
        height={0}
        style={{
          width: image.width || "auto",
          height: image.height || styles.image.defaultHeight,
        }}
        src={image.src}
        alt={image.alt}
      />
      <Typography variant={styles.title.variant} sx={styles.title.sx}>
        {title}
      </Typography>
      <Typography
        variant={styles.description.variant}
        sx={{
          maxWidth: "400px",
          textAlign: "center",
          wordWrap: "break-word",
          whiteSpace: "pre-line",
          overflowWrap: "break-word",
          ...styles.description.sx,
        }}
      >
        {description}
      </Typography>
      {actionButton && (
        <HiddenIfNoPermission
          requiredPermission={actionButton.requiredPermission}
          grantedPermissions={permissions}
        >
          <Button
            onClick={actionButton.action}
            disabled={actionButton.disabled}
            startIcon={actionButton.icon}
            sx={styles.actionButton.sx}
            data-testid={testButtonId}
          >
            {actionButton.label}
          </Button>
        </HiddenIfNoPermission>
      )}
    </Box>
  );
};
