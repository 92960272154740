const getContent = (input: unknown): string => {
  if (typeof input === "string") {
    return input;
  }

  if (typeof input === "object") {
    if (Array.isArray(input)) {
      return input.map((item) => getContent(item)).join("");
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (input && (input as any)?.props && (input as any)?.props?.children) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return getContent((input as any)["props"]["children"]);
    }
  }

  return "";
};

export function parseStringToId(input: string | unknown[]): string {
  let output = getContent(input).replace(/\s+/g, "-");
  output = output.replace(/[^a-zA-Z0-9\-_]/g, "");

  if (/^\d/.test(output)) {
    output = "_" + output;
  }

  return output.toLowerCase();
}
