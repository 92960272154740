import { SxProps, styled } from "@mui/material";
import { Box } from "../../atoms";
import { Checkbox, CheckboxProps } from "../../atoms/Checkbox/Checkbox";
import React from "react";

const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$containerPadding",
})<CheckboxProps & { $containerPadding: string | undefined }>(
  ({ checked, $containerPadding = "8px", theme }) => {
    const backgroundColor = checked
      ? { backgroundColor: theme.palette.primary[50] }
      : { backgroundColor: theme.palette.common.white };
    const borderColor = checked
      ? theme.palette.primary[200]
      : theme.palette.grey[200];

    return {
      display: "flex",
      alignItems: "flex-start",
      padding: $containerPadding,
      border: `1px solid ${borderColor}`,
      borderRadius: "4px",
      cursor: "pointer",
      ...backgroundColor,
      "& .MuiFormControlLabel-root": {
        padding: "4px",
        marginLeft: "4px",
      },
    };
  }
);

const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== "$containerPadding",
})`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;

  &.Mui-disabled + * {
    cursor: not-allowed;
    color: ${({ theme }) => theme.palette.grey[500]};
  }
`;

export const SingleCheckboxItem: React.FC<
  CheckboxProps & {
    checked: boolean;
    containerPadding?: string;
    children?: React.ReactNode;
    sx?: SxProps;
  }
> = (props) => {
  const { containerPadding: $containerPadding, ...rest } = props;
  return (
    <StyledContainer
      checked={props.checked}
      $containerPadding={props.containerPadding}
      sx={props.sx}
    >
      <StyledCheckbox
        labelStyles={{
          width: "100%",
        }}
        cursorPointerOnLabel
        {...rest}
      />
      {props.children}
    </StyledContainer>
  );
};
