import { useTranslation } from "next-i18next";
import { Button, Typography } from "../../atoms";
import { Box, Divider } from "../../reexports";
import Image from "next/image";
import Error404 from "@applied-ai/root/public/images/404.svg";
import Error403 from "@applied-ai/root/public/images/403.svg";
import Error500 from "@applied-ai/root/public/images/500.svg";
import SomethingWentWrong from "@applied-ai/root/public/images/somethingWentWrong.svg";
import { useRouter } from "next/router";
import { useRedirect } from "@applied-ai/hooks";

export const ErrorComponent: React.FC<{
  errorStatus: 403 | 404 | 500 | "somethingWentWrong";
  appPath?: string;
}> = ({ appPath, errorStatus }) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { redirectTo } = useRedirect(
    router.basePath.startsWith(process.env.NEXT_PUBLIC_UCTS_PATH || "")
      ? `/${router.locale}/`
      : `${process.env.NEXT_PUBLIC_UCTS_PATH}/${router.locale}/`
  );

  const ERROR = {
    403: {
      title: t("noAccessError"),
      description: t("noAccessErrorDescription"),
      image: Error403,
    },
    404: {
      title: t("pageNotFound"),
      description: t("pageNotFoundDescription"),
      image: Error404,
    },
    500: {
      title: t("internalServerError"),
      description: t("internalServerErrorDescription"),
      image: Error500,
    },
    somethingWentWrong: {
      title: t("somethingWentWrong"),
      description: t("somethingWentWrongDescription"),
      image: SomethingWentWrong,
    },
  };

  const requestId = router.query["requestId"];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: "8%",
        gap: "32px",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "220px",
          height: "156px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Image fill src={ERROR?.[errorStatus]?.image} alt="SystemsIcon" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Typography variant="h1">{ERROR?.[errorStatus]?.title}</Typography>
        <Typography
          variant="bodyRegular16"
          sx={{
            width: "25rem",
            textAlign: "center",
          }}
        >
          {ERROR?.[errorStatus]?.description}
        </Typography>
        {requestId && (
          <>
            <Divider sx={{ mb: "-4px", width: "100%" }} />
            <Box>
              <Typography variant="bodyRegular14">
                {t("requestId")}: {requestId}
              </Typography>
            </Box>
          </>
        )}
        <Button onClick={redirectTo} sx={{ mt: "40px" }}>
          {router.query.redirectUrl ? t("back") : t("backHome")}
        </Button>
      </Box>
    </Box>
  );
};
