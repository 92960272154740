import { useState } from "react";

export interface ModalContentData {
  title: string;
  description: {
    top: string;
    bottom?: string;
  };
  submitButton: {
    text: string;
    action: () => void;
    testId: string;
  };
}

export const useModalState = () => {
  const [modalState, setModalState] = useState<{
    isOpen: boolean;
    content: ModalContentData | null;
  }>({
    isOpen: false,
    content: null,
  });

  const openModal = (content: ModalContentData) => {
    setModalState({ isOpen: true, content });
  };

  const closeModal = () => {
    setModalState({ isOpen: false, content: null });
  };

  return { modalState, openModal, closeModal };
};
