import { SortByOption } from "@applied-ai/types";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "next-i18next";
import { Box, InputAdornment, MenuItem } from "../../reexports";
import { TextField, Typography } from "../../atoms";

interface SearchInputProps {
  label?: string | React.ReactNode;
  inputPlaceholder?: string;
  initialSortBy?: string;
  sortBy?: string;
  searchValue: string;
  onSearchChange: (value: string) => void;
  onSortByChange?: (value: string) => void;
  sortByOptions?: SortByOption[];
  isSearchDisabled?: boolean;
  showSort?: boolean;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  label,
  inputPlaceholder,
  initialSortBy,
  sortBy,
  searchValue,
  onSearchChange,
  onSortByChange,
  sortByOptions,
  isSearchDisabled,
  showSort = true,
}) => {
  const { t } = useTranslation("common");
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TextField
          placeholder={inputPlaceholder}
          value={searchValue}
          onChange={(e) => onSearchChange(e.target.value)}
          fullWidth
          labelSx={{
            pl: 0,
            pb: 0,
            width: showSort ? "50%" : "100%",
          }}
          disabled={isSearchDisabled}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ width: "20px", height: "20px" }} />
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              height: "36px",
            },
          }}
        />

        {showSort && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="bodyRegular14">
              {t("common:filtering.sortBy")}
            </Typography>
            <TextField
              select
              variant="outlined"
              defaultValue={initialSortBy}
              onChange={(e) => onSortByChange?.(e.target.value)}
              value={sortBy}
              SelectProps={{
                IconComponent: ArrowDropDownIcon,
              }}
              sx={{
                ".MuiInputBase-root": {
                  border: "none !important",
                  boxShadow: "none !important",
                  backgroundColor: "transparent",
                  color: "grey.700",
                  fontWeight: 500,
                  cursor: "pointer",
                },
                "&:hover": {
                  backgroundColor: "primary.50",
                  borderRadius: "4px",
                },
              }}
            >
              {sortByOptions?.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {t(label)}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}
      </Box>
      {label && (
        <Typography
          variant="bodyMedium14"
          sx={{ fontWeight: 400, color: "grey.600" }}
        >
          {label}
        </Typography>
      )}
    </Box>
  );
};
