import { FieldGroupDto } from "@applied-ai/types";

export const getCurrentFieldGroupName = (
  id: string,
  fieldGroups: FieldGroupDto[]
): string | undefined | null => {
  for (const group of fieldGroups) {
    if (group.id === id) {
      return group.name;
    }

    if (group.field_groups) {
      const result = getCurrentFieldGroupName(id, group.field_groups);
      if (result) {
        return result;
      }
    }
  }

  return null;
};
