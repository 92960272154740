import { styled } from "@applied-ai/components";
import {
  NO_SIDEBARS_CONTENT_FIRST_BREAKPOINT,
  NO_SIDEBARS_CONTENT_SECOND_BREAKPOINT,
} from "@applied-ai/utils";

export const Main = styled("main")`
  position: relative;
  background-color: ${(p) => p.theme.palette.grey[100]};
  max-height: 100%;
  overflow: auto;
  padding: 32px 0;
`;

export const MainContent = styled("div")`
  width: 100%;
  max-width: ${`${NO_SIDEBARS_CONTENT_FIRST_BREAKPOINT}px`};
  margin: auto;
  @media (min-width: ${({ theme }) => theme.breakpoints[1600]}) {
    max-width: ${`${NO_SIDEBARS_CONTENT_SECOND_BREAKPOINT}px`};
  }
`;

export const NoSidebarsMain = ({ children }: { children: React.ReactNode }) => {
  return (
    <Main>
      <MainContent>{children}</MainContent>
    </Main>
  );
};

export const NoSidebarsLayout = {
  Main: NoSidebarsMain,
};
