import React from "react";
import { Box, Typography, Button } from "../../atoms";
import { FormHelperText, SxProps } from "../../reexports";
import { QuestionTile } from "../QuestionTile/QuestionTile";

interface CheckboxGroupProps {
  fieldId: string;
  children: React.ReactNode;
  entitiesCount: number;
  subheader: string;
  fieldErrorMessage?: string;
  required?: boolean;
  isHintAvailable?: boolean;
  label?: string;
  onFocus: () => void;
  onIconClick?: () => void;
  header?: string;
  actionButton?: {
    label: string;
    icon?: React.ReactNode;
    onClick: () => void;
    disabled?: boolean;
  };
  tileSx?: SxProps;
}

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  entitiesCount,
  fieldId,
  children,
  fieldErrorMessage,
  required,
  onIconClick,
  onFocus,
  isHintAvailable,
  label,
  header,
  subheader,
  actionButton,
  tileSx,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mx: "4px",
        }}
      >
        <Typography
          variant="bodyMedium20"
          sx={{
            display: "flex",
            alignItems: "center",
            color: "grey.900",
          }}
        >
          {header}
        </Typography>
        {actionButton && (
          <Button
            startIcon={actionButton.icon}
            variant="text"
            onClick={actionButton.onClick}
            disabled={actionButton.disabled}
          >
            {actionButton.label}
          </Button>
        )}
      </Box>
      <QuestionTile
        key={fieldId}
        question={`${subheader} (${entitiesCount})`}
        htmlFor={fieldId}
        onIconClick={onIconClick}
        required={required}
        withDivider={false}
        label={label}
        sx={tileSx}
        onFocus={onFocus}
        isHintAvailable={isHintAvailable}
      >
        {fieldErrorMessage && (
          <FormHelperText error>{fieldErrorMessage}</FormHelperText>
        )}
        {children}
      </QuestionTile>
    </>
  );
};
