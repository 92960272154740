import { FieldGroupDto } from "@applied-ai/types";

export const updateFieldGroups = (
  fieldGroups: FieldGroupDto[],
  currentFieldGroupId: string,
  areRequiredFieldsFulfilled: boolean
): FieldGroupDto[] => {
  return fieldGroups.map((group) => {
    const updatedGroup = { ...group };

    if (group.id === currentFieldGroupId) {
      updatedGroup.required = !areRequiredFieldsFulfilled;
    }

    if (group.field_groups?.length) {
      updatedGroup.field_groups = updateFieldGroups(
        group.field_groups,
        currentFieldGroupId,
        areRequiredFieldsFulfilled
      );
    }

    return updatedGroup;
  });
};
