import {
  PieChartProps,
  PortfolioBarChartData,
  PortfolioChartWrapperDto,
} from "@applied-ai/types";

export const transformUeCasesPerEntityDataForBarChart = (useCaseData: {
  chart_type: string;
  data: PieChartProps[];
  slug: string;
  name: string;
  status?: PortfolioChartWrapperDto.status;
  total: number;
}): PortfolioBarChartData => {
  const { data } = useCaseData;
  const index_by = "column_name";

  // Sort data by value
  const sortedData = data.sort((a, b) => b.value - a.value);

  // Take top 5 items or all items if there are less than 5
  const top5Data = sortedData.slice(0, Math.min(5, sortedData.length));

  // Calculate the sum of values for items beyond the top 5
  const sumOfOthers = sortedData
    .slice(5)
    .reduce((sum, item) => sum + item.value, 0);

  const keys: string[] = top5Data.map((item) => item.label);

  const transformedData: {
    [key: string]: number | string;
  }[] = top5Data.map((item) => {
    const columnData: { [key: string]: number | string } = {};
    columnData[index_by] = item.label;
    columnData[item.label] = item.value;
    columnData["value_percentage"] = item.percent;
    return columnData;
  });

  // Add an additional column for 'Rest' if there are more than 5 items
  if (sortedData.length > 5) {
    keys.push("Rest");

    // Collect all values and value percentages for Rest group
    const restValues: number[] = [];
    const restPercentages: number[] = [];
    const restLabels: string[] = [];

    sortedData.slice(5).forEach((item) => {
      restValues.push(item.value);
      restPercentages.push(item.percent);
      restLabels.push(item.label);
    });

    const restPercentageString = restPercentages.join(", ");
    const restValuesString = restValues.join(", ");
    const restLabelsString = restLabels.join(", ");

    const othersData: { [key: string]: number | string } = {};
    othersData[index_by] = "Rest";
    othersData["Rest"] = sumOfOthers;
    othersData["values"] = restValuesString;
    othersData["value_percentage"] = restPercentageString;
    othersData["rest_values"] = restValuesString;
    othersData["rest_labels"] = restLabelsString;
    transformedData.push(othersData);
  }

  const result: PortfolioBarChartData = {
    index_by: index_by,
    keys: keys,
    data: transformedData,
    isErrorStatus: false,
  };

  return result;
};
