import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Spinner, Typography } from "../../atoms";
import { Box, useTheme } from "../../reexports";
import { createDownloadFilename } from "./createDownloadFilename";
import { EllipsisWithTooltip } from "../EllipsisWithTooltip/EllipsisWithTooltip";

export type FileStatus = "loading" | "complete" | "error";
interface FileTileProps {
  id: string;
  canEdit: boolean;
  name?: string;
  status: FileStatus;
  errorMessages?: string[];
  onDeleteFile: (id: string, name?: string) => void;
  fileUrl?: string;
}

const ActionButtons = ({
  status,
  canEdit,
  onDeleteFile,
  fileUrl,
  name,
}: {
  status: FileStatus;
  onDeleteFile: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  canEdit: boolean;
  name?: string;
  fileUrl?: string;
}) => {
  const { palette } = useTheme();
  const downloadFilename = createDownloadFilename(fileUrl, name);

  switch (status) {
    case "loading":
      return <Spinner size="small" />;
    case "complete":
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <a
            href={fileUrl}
            download={downloadFilename}
            style={{ position: "relative", top: "3px" }}
          >
            <FileDownloadOutlinedIcon
              sx={{ width: "16px", height: "16px", fill: palette.grey[700] }}
            />
          </a>
          <DeleteOutlinedIcon
            onClick={(e) => canEdit && onDeleteFile(e)}
            sx={{
              width: "16px",
              height: "16px",
              fill: palette.grey[700],
              cursor: canEdit ? "pointer" : "default",
            }}
          />
        </Box>
      );
    case "error":
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <CancelRoundedIcon
            onClick={(e) => onDeleteFile(e)}
            sx={{ width: "16px", height: "16px", fill: palette.red[500] }}
          />
          <DeleteOutlinedIcon
            onClick={(e) => onDeleteFile(e)}
            sx={{
              width: "16px",
              height: "16px",
              fill: palette.grey[700],
              cursor: "pointer",
            }}
          />
        </Box>
      );
    default:
      return null;
  }
};

export const FileTile = ({
  id,
  name,
  canEdit,
  status,
  errorMessages,
  onDeleteFile,
  fileUrl,
}: FileTileProps) => {
  const { palette } = useTheme();

  return (
    <Box sx={{ display: "grid", gap: "4px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "16px",
          padding: "16px",
          backgroundColor: palette.grey[50],
          border: `solid 1px`,
          borderColor:
            status === "error" ? palette.red[500] : palette.grey[200],
          borderRadius: "4px",
        }}
      >
        <Typography
          variant="bodyMedium14"
          sx={{
            fontWeight: 400,
            maxWidth: "50%",
          }}
        >
          <EllipsisWithTooltip>{name}</EllipsisWithTooltip>
        </Typography>
        <ActionButtons
          onDeleteFile={() => onDeleteFile(id, name)}
          status={status}
          fileUrl={fileUrl}
          name={name}
          canEdit={canEdit}
        />
      </Box>
      {errorMessages &&
        status === "error" &&
        errorMessages.map((errorMessage, index) => (
          <Typography
            key={index}
            variant="bodyRegular12"
            sx={{ color: "red.500" }}
          >
            {errorMessage}
          </Typography>
        ))}
    </Box>
  );
};
