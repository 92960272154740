import { AaiFileObjectDto } from "@applied-ai/types";
import { FileToUpload, UploadedFile } from "./UploadFileField";

export const parseAddedFiles = <T>(
  updatedFiles: PromiseSettledResult<Awaited<T>>[] | FileToUpload[]
) =>
  updatedFiles
    .map((file) => {
      if (file.status === "fulfilled") {
        const result = file.value as { id: string; file: AaiFileObjectDto };

        return {
          id: result.id,
          filename: result.file.name,
          url: result.file.url,
          status: "complete",
        } as UploadedFile;
      } else if (file.status === "rejected") {
        return {
          status: "error",
          filename: file.reason.request.formData.file.path,
          errors: [file.reason.message],
        };
      }
      return file;
    })
    .filter((file): file is UploadedFile => file !== null);
