import { EntityDto } from "@applied-ai/types";
import React from "react";
import { Box, Typography } from "../../atoms";
import { SingleCheckboxItem } from "../SingleCheckboxItem/SingleCheckboxItem";
import { SxProps } from "../../reexports";

interface CheckboxProps {
  item: EntityDto;
  children?: React.ReactNode;
  value: string[];
  onChange: (
    value: string[]
  ) =>
    | Promise<void>
    | ((data: React.SyntheticEvent | string[]) => Promise<void>)
    | void;
  disabled?: boolean;
  sx?: SxProps;
  dataTestid?: string;
}

interface InputDisplayProps extends React.HTMLAttributes<HTMLInputElement> {
  "data-testid"?: string;
}

export const Checkbox = ({
  item,
  value,
  onChange,
  disabled,
  children,
  sx,
  dataTestid,
}: CheckboxProps) => {
  const isChecked = value.includes(item.id);
  const onChangeHandler = () => {
    if (isChecked) {
      onChange(value.filter((optionId) => optionId !== item.id));
    } else {
      onChange([...value, item.id]);
    }
  };

  return (
    <Box
      sx={{
        my: "10px",
        "&:hover": {
          boxShadow: `0px 8px 16px 0px rgba(25, 28, 30, 0.16)`,
        },
        ...sx,
      }}
    >
      <SingleCheckboxItem
        onChange={onChangeHandler}
        checked={isChecked}
        containerPadding="16px"
        disabled={disabled}
        sx={{
          "& > *": {
            minWidth: "0px",
          },
        }}
        inputProps={
          {
            "data-testid": dataTestid,
          } as InputDisplayProps
        }
      >
        {children ? (
          children
        ) : (
          <Typography
            variant="bodyMediumUnderline16"
            sx={{
              color: "grey.900",
              fontSize: "1rem",
            }}
          >
            {item.value}
          </Typography>
        )}
      </SingleCheckboxItem>
    </Box>
  );
};
