import { AaiFileObjectDto } from "@applied-ai/types";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useTranslation } from "next-i18next";
import { useDropzone } from "react-dropzone";
import { Typography } from "../../atoms";
import { Box, styled, useTheme } from "../../reexports";

export interface CustomFile extends File {
  id?: string;
  value?: string;
  file?: File;
  metadata?: AaiFileObjectDto;
}

export interface onDropFile {
  file: CustomFile;
  errors?: string[];
}

interface DropAreaProps {
  disabled: boolean;
  onDrop: (files: onDropFile[]) => void;
  validation: (file: File) => string[] | undefined;
}

const DropAreaWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 24px 16px;
  border-radius: 4px;
  border: 1px dashed ${({ theme }) => theme.palette.grey[200]};
  outline: none;
`;

export const DropArea = ({ onDrop, disabled, validation }: DropAreaProps) => {
  const theme = useTheme();
  const { t } = useTranslation(["common"]);
  const { getRootProps, getInputProps, isFocused, isDragActive } = useDropzone({
    onDrop: (files) => {
      const checkedFiles = files.map((file) => {
        return { file, errors: validation(file) };
      });
      onDrop(checkedFiles);
    },
    disabled,
  });

  if (disabled) {
    return (
      <DropAreaWrapper sx={{ backgroundColor: "grey.100" }}>
        <FileUploadOutlinedIcon
          sx={{ width: "20px", height: "20px", fill: theme.palette.grey[600] }}
        />
        <Typography variant="bodyMedium12" sx={{ color: "grey.600" }}>
          {t("common:dragAndDropFile")}
        </Typography>
      </DropAreaWrapper>
    );
  }

  return (
    <DropAreaWrapper
      sx={{
        backgroundColor: "grey.50",
        boxShadow:
          isFocused || isDragActive
            ? "0px 0px 4px 0px rgba(0, 116, 123, 0.60)"
            : "",
        cursor: "pointer",

        "&:hover > .dragText": {
          textDecoration: "underline",
        },
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <FileUploadOutlinedIcon
        sx={{ width: "20px", height: "20px", fill: theme.palette.primary[500] }}
      />
      <Typography
        variant="bodyMedium12"
        className="dragText"
        sx={{
          color: "primary.500",
          display: "block",
          textDecoration: isFocused || isDragActive ? "underline" : "",
        }}
      >
        {t("common:dragAndDropFile")}
      </Typography>
    </DropAreaWrapper>
  );
};
