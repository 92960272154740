import { useEffect, useRef } from "react";
import { QuestionTile } from "../QuestionTile/QuestionTile";
import { RichEditorComponent } from "../RichEditor/RichEditor";
import { Typography } from "../../atoms";
import { useTranslation } from "next-i18next";
import SunEditor from "suneditor/src/lib/core";
import "suneditor/dist/css/suneditor.min.css";

interface QuestionRichEditorTileProps {
  id: string;
  question: string;
  required?: boolean;
  isHintAvailable?: boolean;
  value: string;
  handleValueChange: (
    data: React.SyntheticEvent | string,
    successCallback?: () => void
  ) => Promise<void>;
  resetError: () => void;
  onCardFocus?: () => void;
  iconClickHandler?: () => void;
  disabled: boolean;
  error?: string;
  label?: string;
}

// Remember to use isContentEmpty function from utils.ts file to check if the content/value is empty
export function QuestionRichEditorTile({
  id,
  question,
  disabled,
  iconClickHandler,
  required,
  isHintAvailable,
  label,
  value,
  error,
  handleValueChange,
  onCardFocus,
}: QuestionRichEditorTileProps) {
  const { t } = useTranslation(["common"]);
  const editorRef = useRef<SunEditor>(null);
  const currentValue = useRef(value);

  useEffect(() => {
    currentValue.current = value;
  }, [value]);

  const handleBlur = async (content: string) => {
    const currentFieldValue = content.trim();

    if (currentFieldValue === currentValue.current) {
      return;
    }

    await handleValueChange(currentFieldValue || "");
  };

  return (
    <QuestionTile
      key={id}
      question={question}
      htmlFor={id}
      onIconClick={iconClickHandler}
      required={required}
      isHintAvailable={isHintAvailable}
      label={label}
      onFocus={onCardFocus}
    >
      <RichEditorComponent
        key={id}
        ref={editorRef}
        editorValue={value}
        onBlur={handleBlur}
        disabled={disabled}
      />
      {error && (
        <Typography variant="bodyRegular12" sx={{ color: "red.500" }}>
          {t(error)}
        </Typography>
      )}
    </QuestionTile>
  );
}
