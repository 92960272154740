import Divider from "@mui/material/Divider";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import { Box } from "../../reexports/Box";
import { theme } from "../../theme/theme";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import { ReactElement, ReactNode, RefObject, forwardRef } from "react";
import { useTranslation } from "next-i18next";
import { Tag } from "../../atoms";
import { TileBase } from "../../atoms/TileBase/TileBase";
import Tooltip from "@mui/material/Tooltip";
import { SxProps, Theme } from "@mui/material";
import { Button } from "../../atoms";

interface QuestionTileProps {
  question: string;
  htmlFor: string;
  children: ReactNode;
  onIconClick?: () => void;
  onCommentIconClick?: () => void;
  required?: boolean;
  helperText?: string;
  withDivider?: boolean;
  ref?: RefObject<HTMLDivElement> | null;
  isHintAvailable?: boolean;
  label?: string;
  isActive?: boolean;
  sx?: SxProps;
  id?: string;
  tileActionButton?: ReactNode;
  onFocus?: () => void;
}

export const QuestionTile = forwardRef<HTMLDivElement, QuestionTileProps>(
  (
    {
      helperText,
      question,
      htmlFor,
      children,
      required,
      onIconClick,
      onCommentIconClick,
      onFocus,
      withDivider = true,
      isHintAvailable,
      label,
      isActive,
      sx,
      id,
      tileActionButton,
    },
    ref
  ) => {
    const { t } = useTranslation(["common"]);

    const isNullOnIconClickFunction =
      onIconClick?.toString() === (() => null).toString();

    return (
      <TileBase
        ref={ref}
        tabIndex={-1}
        id={id}
        sx={{
          position: "relative",
          marginTop: label && "8px",
          border: isActive ? `1px solid ${theme.palette.grey[900]}` : "",
          boxShadow: isActive ? "0px 8px 16px 0px rgba(25, 28, 30, 0.16)" : "",
          "&:hover": {
            boxShadow: "0px 8px 16px 0px rgba(25, 28, 30, 0.16)",
          },
          ...sx,
        }}
        data-testid="QuestionTile"
        onFocus={onFocus}
      >
        {label && (
          <Tag
            label={label}
            backgroundColor={theme.palette.primary[100]}
            sx={{
              position: "absolute",
              top: 0,
              transform: "translateY(-50%)",
              color: theme.palette.primary[600],
              padding: "2px 6px",
            }}
          />
        )}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "12px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {required && (
              <Typography
                color="red.500"
                variant="bodyMedium10"
                sx={{
                  textTransform: "uppercase",
                }}
              >
                {t("common:required")}
              </Typography>
            )}
            {helperText && (
              <Typography
                variant="bodyMedium10"
                sx={{
                  color: "grey.600",
                  textTransform: "uppercase",
                }}
              >
                {helperText}
              </Typography>
            )}
            <InputLabel
              htmlFor={htmlFor}
              sx={{
                whiteSpace: "normal",
              }}
            >
              <Typography variant="bodyMedium16" sx={{ color: "grey.900" }}>
                {question}
              </Typography>
            </InputLabel>
          </Box>
          <Box sx={{ display: "flex", alignSelf: "flex-start" }}>
            {tileActionButton && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  pl: "24px",
                }}
              >
                {tileActionButton}
                <Divider
                  sx={{
                    ml: "16px",
                    mr: "8px",
                    height: "100%",
                    color: "grey.200",
                  }}
                  orientation="vertical"
                />
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {onCommentIconClick && (
                <IconButton
                  sxStyles={{ transform: "rotateY(180deg)" }}
                  onClick={onCommentIconClick}
                  icon={<AddCommentOutlinedIcon fontSize="small" />}
                  isHintAvailable={true}
                  testId="question-tile-comment-button"
                />
              )}
              {onIconClick && !isNullOnIconClickFunction && (
                <IconButton
                  onClick={onIconClick}
                  icon={<InfoOutlined fontSize="small" />}
                  isHintAvailable={isHintAvailable}
                  testId="question-tile-icon-button"
                />
              )}
            </Box>
          </Box>
        </Box>
        {withDivider && <Divider sx={{ margin: "0 2px 16px 0" }} />}
        {children}
      </TileBase>
    );
  }
);

interface IconButtonProps {
  icon: ReactElement;
  onClick: () => void;
  isHintAvailable?: boolean;
  sxStyles?: SxProps<Theme>;
  testId?: string;
}

const IconButton = ({
  isHintAvailable,
  onClick,
  icon,
  sxStyles,
  testId,
}: IconButtonProps) => {
  const { t } = useTranslation(["common"]);

  return (
    <Tooltip
      title={t("common:notAvailable")}
      placement="bottom"
      disableHoverListener={isHintAvailable}
      sx={{
        "& .MuiTooltip-tooltip": {
          padding: "4px 8px",
          borderRadius: "3px",
        },
      }}
    >
      <Box
        sx={{
          cursor: isHintAvailable ? "auto" : "not-allowed",
          ...sxStyles,
        }}
      >
        <Button
          disabled={!isHintAvailable}
          variant="ghost"
          onClick={onClick}
          data-testid={testId}
          icon={icon}
        />
      </Box>
    </Tooltip>
  );
};
