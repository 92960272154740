import {
  DataSourceFieldDefinitionWithOptions,
  UseCaseFieldDefinitionWithValueDto,
} from "@applied-ai/types";

export const parseFieldDefinitionsAndValues = (
  fieldValuesAndDefinitions: (
    | DataSourceFieldDefinitionWithOptions
    | UseCaseFieldDefinitionWithValueDto
  )[]
) => {
  return fieldValuesAndDefinitions.reduce((acc, currentValue) => {
    const fieldDefinitionId = currentValue.field_definition.id as string;
    const fieldValue = currentValue.field_value?.value?.value;

    acc[fieldDefinitionId] =
      typeof fieldValue === "number" ? `${fieldValue}` : fieldValue || "";

    return acc;
  }, {} as Record<string, string | string[] | number>);
};

const getFieldValueId = (
  fieldDefinitionAndValue: DataSourceFieldDefinitionWithOptions
) => fieldDefinitionAndValue.field_value?.id || "";

export const parseFieldDefinitionsAndValuesToFieldValuesObject = (
  fieldDefinitionsAndValues: DataSourceFieldDefinitionWithOptions[]
) => {
  const fieldValuesObject: Record<string, string> = {};

  fieldDefinitionsAndValues.forEach((field) => {
    const fieldDefinitionId = field.field_definition.id;
    fieldValuesObject[fieldDefinitionId] = getFieldValueId(field);
  });

  return fieldValuesObject;
};
